
/* Action types */
const PREFIX = "user/";
export const STORE_REQUEST = PREFIX + "STORE_REQUEST";
export const STORE_SUCCESS = PREFIX + "STORE_SUCCESS";
export const STORE_FAILED = PREFIX + "STORE_FAILED";

export const STORE_PROFILE_REQUEST = PREFIX + "STORE_PROFILE_REQUEST";
export const STORE_PROFILE_SUCCESS = PREFIX + "STORE_PROFILE_SUCCESS";
export const STORE_PROFILE_FAILED = PREFIX + "STORE_PROFILE_FAILED";

export const LOAD_USER_REQUEST = PREFIX + "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = PREFIX + "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILED = PREFIX + "LOAD_USER_FAILED";

export const SET_ADD_DIALOG = PREFIX + "SET_ADD_DIALOG";
export const UNSET_ADD_DIALOG = PREFIX + "UNSET_ADD_DIALOG";

export const SET_ACTIVE_REWARD = PREFIX + "SET_ACTIVE_REWARD";
export const UNSET_ACTIVE_REWARD = PREFIX + "UNSET_ACTIVE_REWARD";

export const SET_ACTIVE_SUPPORT = PREFIX + "SET_ACTIVE_SUPPORT";
export const UNSET_ACTIVE_SUPPORT = PREFIX + "UNSET_ACTIVE_SUPPORT";

export const ADD_PRO = PREFIX + "ADD_PRO";
export const ADD_CONTRA = PREFIX + "ADD_CONTRA";

export const SET_ACTIVE_DAY = PREFIX + "SET_ACTIVE_DAY";
export const UNSET_ACTIVE_DAY = PREFIX + "UNSET_ACTIVE_DAY";

export const SET_ACTIVE_NOTIFICATION = PREFIX + "SET_ACTIVE_NOTIFICATION";
export const UNSET_ACTIVE_NOTIFICATION = PREFIX + "UNSET_ACTIVE_NOTIFICATION";

export const SET_SMOKE_NOW = PREFIX + "SET_SMOKE_NOW";
export const UNSET_SMOKE_NOW = PREFIX + "UNSET_SMOKE_NOW";

export const OPEN_TIMER_DIALOG = PREFIX + "OPEN_TIMER_DIALOG";
export const CLOSE_TIMER_DIALOG = PREFIX + "CLOSE_TIMER_DIALOG";

export const SET_TIMER = PREFIX + "SET_TIMER";
export const UNSET_TIMER = PREFIX + "UNSET_TIMER";

export const SET_STATUS_ALREADY = PREFIX + "SET_STATUS_ALREADY";
export const UNSET_STATUS_ALREADY = PREFIX + "UNSET_STATUS_ALREADY";

export const RESET_REDUX = PREFIX + "RESET_REDUX";


/* initial state */
const initialState = {
    isFetching: false,
    error: false,
    data: {},
    addDialog: false,
    editable: false,
    activeReward: {},
    activeSupport: {},
    pro: false,
    contra: false,
    activeDay: {},
    activeNotification: {},
    smokeNow: false,
    timerDialog: false,
    countdown: false,
    status: false
};

/* Reducer */
const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case STORE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case STORE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
            };
        case STORE_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case STORE_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case STORE_PROFILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
            };
        case STORE_PROFILE_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case LOAD_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                data: action.data
            };
        case LOAD_USER_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case SET_ADD_DIALOG:
            return {
                ...state,
                addDialog: true
            };
        case UNSET_ADD_DIALOG:
            return {
                ...state,
                addDialog: false
            };
        case SET_ACTIVE_REWARD:
            return {
                ...state,
                editable: true,
                activeReward: action.reward
            };
        case UNSET_ACTIVE_REWARD:
            return {
                ...state,
                editable: false,
                activeReward: {}
            };
        case SET_ACTIVE_SUPPORT:
            return {
                ...state,
                editable: true,
                activeSupport: action.support
            };
        case UNSET_ACTIVE_SUPPORT:
            return {
                ...state,
                editable: false,
                activeSupport: {}
            };
        case ADD_PRO:
            return {
                ...state,
                pro: true,
                contra: false
            };
        case ADD_CONTRA:
            return {
                ...state,
                pro: false,
                contra: true
            };
        case SET_ACTIVE_DAY:
            return {
                ...state,
                activeDay: action.day,
            };
        case UNSET_ACTIVE_DAY:
            return {
                ...state,
                activeDay: {},
            };
        case SET_ACTIVE_NOTIFICATION:
            return {
                ...state,
                activeNotification: action.notification,
                addDialog: true,
                editable: true
            };
        case UNSET_ACTIVE_NOTIFICATION:
            return {
                ...state,
                activeNotification: {},
                addDialog: false,
                editable: false
            };
        case SET_SMOKE_NOW:
            return {
                ...state,
                smokeNow: true
            };
        case UNSET_SMOKE_NOW:
            return {
                ...state,
                smokeNow: false
            };
        case OPEN_TIMER_DIALOG:
            return {
                ...state,
                timerDialog: true
            };
        case CLOSE_TIMER_DIALOG:
            return {
                ...state,
                timerDialog: false
            };
        case SET_TIMER:
            return {
                ...state,
                countdown: action.seconds
            };
        case UNSET_TIMER:
            return {
                ...state,
                countdown: false
            };
        case SET_STATUS_ALREADY:
            return {
                ...state,
                status: true
            };
        case UNSET_STATUS_ALREADY:
            return {
                ...state,
                status: false
            };

        case RESET_REDUX:
            return {
                ...state,
                data: {},
            };
        default:
            return state;
    }
};
export default userReducer;