import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./BasicDashboardComponentStyles";
import HelpIcon from '@material-ui/icons/Help';
import Timer from "../Dashboard/Timer";
import { Card, CardActionArea, CardContent, CardMedia } from "@material-ui/core";

import QuestionMark from '../../../Assets/v2/question_mark.png';


const BasicDashboardComponent = ({ classes, name, paper = false, icon, title, description, onClick, dashboardInfo, dashboardInfoData, showInfo, history, counterStart=false, stopTimer }) => {

    let tileStyle = {}

    if(paper){
        tileStyle = {
            backgroundColor: '#fff',
            borderRadius: 15,
            boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.02)'
        }
    }

    let modalData = {};
    if(dashboardInfoData && dashboardInfoData.length){
        modalData = dashboardInfoData.find(item => item.key === name);
    }

    return (
        <div className={classes.dashboardTiles} style={tileStyle} onClick={onClick}>

            {dashboardInfo &&
                <div className={classes.infoOverlay} onClick={()=> showInfo({data: {...modalData}})}>
                    <img src={QuestionMark} className={classes.infoIcon}/>
                </div>
            }

            <div className={classes.container} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
                <div className={classes.icon}>
                    {icon}
                </div>

                <div className={classes.title} style={{fontSize: paper ? 16 : 24}}>
                    {title}
                </div>
                {(name === 'timer' && counterStart) ?
                    <div className={classes.description} style={{height: 40}}>
                        <Timer/>
                    </div>
                    :
                    <>
                    { description &&
                        <div className={classes.description}>
                            {description}
                        </div>
                    }
                    </>
                }
            </div>
            {name==='fitness' &&
                <div className={classes.fitnessFooter} onClick={(e) => { e.stopPropagation(); history.push('/intern/fitness_list'); }} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
                    <div className={classes.fitnessFooterText}>
                        > Meine Tipp - Liste
                    </div>
                </div>
            }

            {name==='timer' && !counterStart &&
                <div className={classes.fitnessFooter} onClick={onClick} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
                    <div className={classes.fitnessFooterText}>
                        > Timer starten
                    </div>
                </div>
            }

            {name==='timer' && counterStart &&
                <div className={classes.fitnessFooter} onClick={stopTimer} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
                    <div className={classes.fitnessFooterText}>
                        > Timer stoppen
                    </div>
                </div>
            }

        </div>

    );

}

export default (withStyles(style)(BasicDashboardComponent));