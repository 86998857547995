import {
    HIDE_INFORMATION_FEATURE_MODAL,
    LOAD_INFORMATION_FEATURE_ERROR,
    LOAD_INFORMATION_FEATURE_REQUEST,
    LOAD_INFORMATION_FEATURE_SUCCESS, SHOW_INFORMATION_FEATURE_MODAL
} from "../Reducers/informationFeatureReducer";

export const loadInformationFeatureRequest = () => ({
    type: LOAD_INFORMATION_FEATURE_REQUEST
});

export const loadInformationFeatureSuccess = ({healthiness, motivation, fact, fitness}) => ({
    type: LOAD_INFORMATION_FEATURE_SUCCESS,
    healthiness,
    motivation,
    fact,
    fitness
})

export const loadInformationFeatureError = () => ({
    type: LOAD_INFORMATION_FEATURE_ERROR
});

export const showInformationFeatureModal = ({data}) => ({
    type: SHOW_INFORMATION_FEATURE_MODAL,
    data
});

export const hideInformationFeatureModal = () => ({
    type: HIDE_INFORMATION_FEATURE_MODAL
});