import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DataTransferStyles";
import {Button, TextField, FormControlLabel, Checkbox} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import {compose} from "redux";
import {Field, getFormValues, reduxForm} from 'redux-form';
import TextFieldGroup from "../../../Components/TextFieldGroup";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {createAccountRequest, loginRequest, logoutRequest} from "../../../Actions/authActions";
import {addFlashMessage} from "../../../Actions/flashMessageActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";

class Login extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        console.log(this.props);
    }

    required = value => value ? undefined : 'Feld erforderlich';

    submit = (values) => {
        this.props.login(
            {
                history: this.props.history,
                email: values.email,
                password: values.password
            });
    };

    render() {
        const { classes, handleSubmit } = this.props;
        return (
            <div className={classes.container}>
                <form>
                    <div className={classes.title}>
                        <h1>Anmelden</h1>
                    </div>

                        <div className={classes.content}>
                            <p>
                                <Field
                                    name={'email'}
                                    component={TextFieldGroup}
                                    fullWidth
                                    placeholder={'gib deine E-Mail Adresse ein'}
                                    validate={[this.required]}
                                />
                            </p>
                            <p>
                                <Field
                                    name={'password'}
                                    type={'password'}
                                    component={TextFieldGroup}
                                    fullWidth
                                    placeholder={'lege dein Kennwort fest'}
                                    validate={[this.required]}
                                />
                            </p>
                        </div>

                        <div className={classes.submitButton}>
                            <PrimaryButton
                                onClick={handleSubmit(this.submit)}
                                label={'Einloggen'}
                            />
                        </div>

                        <div className={classes.resetPassword}>
                            <SimpleButton
                                label={'Kennwort vergessen?'}
                                onClick={() => this.props.ownProps.history.push("/intern/forgot_password")}
                            />
                        </div>
                    </form>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
    }
}
const mapDispatchToProps = {
    login: loginRequest,
    createAccount: createAccountRequest,
    addFlashMessage: addFlashMessage,
    logout: logoutRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'login-form'
    }),
    withStyles(style)
)(Login);
