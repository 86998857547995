import React, { useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FirstDaysStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, MenuItem } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import { storeRequest, unsetActiveNotification, unsetAddDialog} from "../../../Actions/userActions";
import SelectGroup from "../../../Components/SelectGroup";
import {firstDaysOption } from "../../../utils/constants"
import { Close as CloseIcon } from '@material-ui/icons';
import SwitchGroup from "../../../Components/SwitchGroup";
import TimePickerGroup from "../../../Components/TimePickerGroup";
import moment from "moment";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";
import CheckboxGroup from "../../../Components/CheckboxGroup";


const required = (value) => value>=0 ? 0 : 'Auswahl erforderlich';

const renderOptions = (options) => {
    return options.map( option => renderOption(option))
};

const renderOption = (option) => {
    return <MenuItem value={option.value}>{option.label}</MenuItem>
};

const NotificationsDialog = (props) => {
    useEffect(() => {
        return () => {
            props.unsetActiveNotification();
        }
    }, []);

    const getNotification = (element) => element.id === props.activeNotification.id;

    const submit = (values) => {

        const _notifications = props.notifications;
        const title = firstDaysOption.filter(option => option.number === values.type);
        const time = moment(values.time).format('HH:mm');
        const whenTime = moment('1970-01-01 ' + time).format();


        if(props.editable) {
            const index = _notifications.findIndex(getNotification);

            _notifications[index] = {
                enabled: values.notification?values.notification:false,
                afterDays: props.activeDay&&props.activeDay.day&&props.activeDay.day,
                whenTime: whenTime,
                when: moment().format(),
                text: title && title[0] ? title[0].notification : '',
                label: title && title[0] ? title[0].text : '',
                type: values.type,
                custom: true,
            };
        } else {
            const result = {
                enabled: values.notification?values.notification:false,
                afterDays: props.activeDay&&props.activeDay.day&&props.activeDay.day,
                whenTime: whenTime,
                when: moment().format(),
                text: title && title[0] ? title[0].notification : '',
                label: title && title[0] ? title[0].text : '',
                type: values.type,
                custom: true,
            };
            _notifications.push(result);
        }

        props.store({key: 'notifications', values: _notifications})
        props.unsetAddDialog();
    };

    const deleteNotification = () => {
        const newNotifications = [];

        props.notifications.forEach(notification =>{
            if(notification.id !==  props.activeNotification.id){
                newNotifications.push(notification)
            }
        });
        props.store({key: 'notifications', values: newNotifications});
        props.unsetAddDialog();
    };

    const selectFirstDaysOption = firstDaysOption.map(items => { return({value:items.number, label: items.text})});

    return (
        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={true}>
            <DialogTitle>
                <div className={props.classes.dialogTitle}>
                    <span>Benachrichtigung hinzufügen</span>
                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => props.unsetAddDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.dialogContent}>
                        <Field
                            name={'notification'}
                            component={CheckboxGroup}
                            label={'Erinnerung'}
                        />

                        <div style={{height:30}}></div>

                        <Field
                            name={'type'}
                            component={SelectGroup}
                            label={'Situation'}
                            fullWidth
                            validate={required}
                        >
                            {renderOptions(selectFirstDaysOption)}
                        </Field>

                        <div style={{height:50}}></div>

                        <Field
                            name={'time'}
                            component={TimePickerGroup}
                            fullWidth
                            label={'Zeit der Benachrichtigung'}
                        />

                        <div style={{height:50}}></div>


                        <div className={props.classes.submitButton}>
                            <PrimaryButton
                                label={'Speichern'}
                                onClick={props.handleSubmit(submit)}
                            />

                            {props.editable && <div>
                                <SimpleButton
                                    label={'Löschen'}
                                    onClick={deleteNotification}
                                />
                            </div>
                            }
                        </div>

                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        notifications: state.user.data && state.user.data.notifications ? state.user.data.notifications : [],
        editable: state.user.editable,
        activeNotification: state.user.activeNotification,
        initialValues:{
            time: state.user.activeNotification && state.user.activeNotification.whenTime ? moment(state.user.activeNotification.whenTime).format() : moment().utc().format(),
            type: state.user.activeNotification && state.user.activeNotification.type>=0 ? state.user.activeNotification.type : -1,
            notification: state.user.activeNotification && state.user.activeNotification.enabled ? state.user.activeNotification.enabled : false,
        }
    }
}

const mapDispatchToProps = {
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
    unsetActiveNotification: unsetActiveNotification,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'notifications-form',
        enableReinitialize: false,
    }),
    withStyles(style)
)(NotificationsDialog);