import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { Field, reduxForm, getFormValues } from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./UserStyles";
import SelectGroup from "../../../Components/SelectGroup";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import { MenuItem, Grid, Button } from "@material-ui/core";
import DatePickerGroup from "../../../Components/DatePickerGroup";
import SwitchGroup from "../../../Components/SwitchGroup";
import moment from 'moment';
import {storeProfileRequest, storeRequest} from "../../../Actions/userActions";
import {age, cigarettes, firstCigarette, sex} from "../../../utils/constants";
import PrimaryButton from "../../../Components/PrimaryButton";
import CheckboxGroup from "../../../Components/CheckboxGroup";

const now = moment().toDate();

class User extends Component {
    constructor(props) {
        super(props);
    }

    required = value => value ? 0 : 'Auswahl erforderlich';

    getInternalValues = (values) => {
        let toTransfer = { ...values };
        if(toTransfer.priceType == true){
            //toTransfer.medianPrice = toTransfer.medianPrice * 20;
        } else {
            toTransfer.medianPrice = toTransfer.medianPrice  / 20;
        }
        return toTransfer;
    }

    submit = (values) => {
        this.props.store({values: this.getInternalValues(values) });
    };

    submitAndBack = (values) => {
        this.props.store({values: this.getInternalValues(values) });
        this.props.history.push('/intern/dashboard');
    };

    renderOptions = (options) => {
        return options.map( option => this.renderOption(option))
    };

    renderOption = (option) => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>
    };

    getStopDateLabel = () => (this.props.status === 'already') ? 'Rauchstopptag' : 'Voraussichtlicher Rauchstopptag';

    render() {
        const { classes, handleSubmit, formValues  } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Mein Profil</h1>
                </div>

                <div className={classes.content}>
                    <form>
                        <Grid container spacing={3} style={{padding: 20}}>
                            <Grid item xs={12}>
                                <Field
                                    label={'Ich rauche ... Zigaretten am Tag'}
                                    name='noOfCigarettes'
                                    component={TextFieldGroup}
                                    fullWidth
                                    type={'number'}
                                    validate={[this.required]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label={'Ich rauche die erste Zigarette'}
                                    name='firstCigarette'
                                    component={SelectGroup}
                                    fullWidth
                                    validate={[this.required]}
                                >
                                    {this.renderOptions(firstCigarette)}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label={this.getStopDateLabel()}
                                    name='stopDate'
                                    component={DatePickerGroup}
                                    fullWidth
                                    style={{width: '100%'}}
                               />
                            </Grid>
                            <Grid item xs={12}>
                                <span>Zigarettenpreis in EUR</span>
                                <Field
                                    name='priceType'
                                    component={SwitchGroup}
                                    fullWidth
                                    leftLabel={'pro Schachtel'}
                                    rightLabel={'pro Zigarette'}
                                    labelStyle={{fontSize: 12}}
                                />
                                <Field
                                    label={'Zigarettenpreis in Euro'}
                                    name='medianPrice'
                                    type={'number'}
                                    component={TextFieldGroup}
                                    fullWidth
                                    validate={[this.required]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='firstTime'
                                    component={CheckboxGroup}
                                    fullWidth
                                    label={'Das ist mein erster Rauchstopp'}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    label={'Alter'}
                                    name='age'
                                    component={SelectGroup}
                                    fullWidth
                                    validate={[this.required]}
                                >
                                    {this.renderOptions(age)}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label={'Geschlecht'}
                                    name='sex'
                                    component={SelectGroup}
                                    fullWidth
                                    validate={[this.required]}
                                >
                                    {this.renderOptions(sex)}
                                </Field>
                            </Grid>


                            {(formValues && formValues.sex === 'female') &&
                                <Grid item xs={12}>
                                    <Field
                                        name='pregnant'
                                        component={CheckboxGroup}
                                        fullWidth
                                        label={'Schwanger'}
                                    />
                                </Grid>
                            }
                        </Grid>



                    </form>
                </div>
                <div className={classes.submitButton}>
                    <PrimaryButton
                        label={'Speichern'}
                        onClick={handleSubmit(this.submit)}
                    />
                    <br/>
                    <PrimaryButton
                        style={{marginTop: 10, marginBottom: 20}}
                        label={'Speichern und zurück'}
                        onClick={handleSubmit(this.submitAndBack)}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const formValues = getFormValues('user-form')(state);
    const appSettings = state.user && state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings']['profile']: false;

    const priceType = appSettings && appSettings.priceType ? appSettings.priceType : false;
    let medianPrice = appSettings.medianPrice ? appSettings.medianPrice : 0;

    if(priceType){
        //medianPrice = medianPrice / 20;
    } else {
        medianPrice = medianPrice * 20;
    }

    return{
        formValues: formValues,
        status: state.user.data['app-settings']?state.user.data['app-settings'].status:'maybe',
        initialValues:{
            noOfCigarettes: appSettings && appSettings.noOfCigarettes ? appSettings.noOfCigarettes : 0,
            firstCigarette: appSettings && appSettings.firstCigarette ? appSettings.firstCigarette : 0,
            stopDate: appSettings && appSettings.stopDate ? moment(appSettings.stopDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate() : now,
            medianPrice: medianPrice,
            priceType: priceType,
            firstTime: appSettings && appSettings.firstTime !== undefined ? appSettings.firstTime : true,
            age: appSettings && appSettings.age ? appSettings.age : 0,
            sex: appSettings && appSettings.sex ? appSettings.sex : 0,
            pregnant: appSettings && appSettings.pregnant ? appSettings.pregnant : false,
        }

    }
}
const mapDispatchToProps = {
    store: storeProfileRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'user-form',
        enableReinitialize: true,
    }),
    withStyles(style)
)(User);
