import { combineReducers } from 'redux';
import wizzarReducer from './Reducers/wizzardReducer';
import informationFeatureReducer from './Reducers/informationFeatureReducer';
import dashboardReducer from './Reducers/dashboardReducer';
import authReducer from './Reducers/authReducer';
import { reducer as formReducer } from 'redux-form'
import flashMessageReducer from './Reducers/flashMessageReducer';
import userReducer from './Reducers/userReducer';
import fileReducer from './Reducers/fileReducer';

const rootReducer = combineReducers({
    form: formReducer,
    wizzard: wizzarReducer,
    information: informationFeatureReducer,
    dashboard: dashboardReducer,
    auth: authReducer,
    flashMessage: flashMessageReducer,
    user: userReducer,
    file: fileReducer
});

export default rootReducer;