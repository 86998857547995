import React, { useEffect }from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./RisksStyles";
import {compose } from "redux";
import { Button } from "@material-ui/core";
import {Field, reduxForm } from "redux-form";
import {connect} from "react-redux";
import { risks } from "../../../utils/constants";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {storeRequest} from "../../../Actions/userActions";
import globalStyle from "../../../themes/GlobalStyles";
import PrimaryButton from "../../../Components/PrimaryButton";

const renderRisk = (props, risk) =>{

    return(
        <div className={props.classes.listItem}>
            <Field
                name={risk.value}
                component={CheckboxGroup}
                label={risk.label}
                defaultChecked={props.initialValues[risk.value]}
            />
        </div>
    )
};

const Risks = (props) => {
    const submit = (values) => {
        const result = {
            hurdles: props.hurdles,
            risks: {
                asthma: values.asthma,
                copd: values.copd,
                diabetes: values.diabetes,
                pille: values.pille,
                surgery: values.surgery,
                heartdesease: values.heartdesease,
                breathless: values.breathless,
                psycho: values.psycho,
                tumor: values.tumor,
                bloodpressure: values.bloodpressure,
                furtile: values.furtile,
                teeth: values.teeth,
                other: values.other
            },
            motives: props.motives
        }
        props.store({key: 'risks', values: result});
    };

    const submitAndBack = (values) => {
        submit(values);
        props.history.push('/intern/dashboard');
    };

    return (
        <div className={props.classes.container}>
            <div className={props.classes.title}>
                <h1>Meine Risiken</h1>
            </div>
            <form className={props.classes.content} >
                <span>
                    Bei bestimmten Erkrankungen oder körperlichen Situationen ist der Rauchstopp ganz besonders gut. Klicke jene Situationen an, die für dich zutreffen, um zu sehen, warum dein Körper besonders vom Rauchstopp profitieren wird.
                </span>

                {risks &&
                    <div className={props.classes.listSection}>
                        {risks.map(risk=>{
                            return(
                                renderRisk(props, risk)
                            )
                        })}
                    </div>
                }
            </form>
            <div className={props.classes.submitButton}>
                <PrimaryButton
                    label={'Speichern'}
                    onClick={props.handleSubmit(submit)}
                />
                <br/>
                <PrimaryButton
                    style={{marginTop: 10, marginBottom: 20}}
                    label={'Speichern und zurück'}
                    onClick={props.handleSubmit(submitAndBack)}
                />
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return{
        motives: state.user.data && state.user.data.risks && state.user.data.risks.motives ? state.user.data.risks.motives : {},
        hurdles: state.user.data && state.user.data.risks && state.user.data.risks.hurdles ? state.user.data.risks.hurdles : {},
        initialValues: {
            asthma: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.asthma?state.user.data.risks.risks.asthma:false,

            copd: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.copd?state.user.data.risks.risks.copd:false,

            diabetes: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.diabetes?state.user.data.risks.risks.diabetes:false,

            pille: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.pille?state.user.data.risks.risks.pille:false,

            surgery: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.surgery?state.user.data.risks.risks.surgery:false,

            heartdesease: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.heartdesease?state.user.data.risks.risks.heartdesease:false,

            breathless: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.breathless?state.user.data.risks.risks.breathless:false,

            psycho: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.psycho?state.user.data.risks.risks.psycho:false,

            tumor: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.tumor?state.user.data.risks.risks.tumor:false,

            bloodpressure: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.bloodpressure?state.user.data.risks.risks.bloodpressure:false,

            furtile: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.furtile?state.user.data.risks.risks.furtile:false,

            teeth: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.teeth?state.user.data.risks.risks.teeth:false,

            other: state.user.data&&state.user.data.risks&&state.user.data.risks.risks&&state.user.data.risks.risks.other?state.user.data.risks.risks.other :false,
        }
    }
}

const mapDispatchToProps = {
    store: storeRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'risks-form',
        enableReinitialize: true,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(Risks);