import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        container: {
            textAlign: 'center',
        },
        content: {
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 10,
            paddingLeft: 20,
            textAlign: 'left',
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20
        },
        paper: {
            padding: 30
        },
        link: {
            color: '#67bad3',
        },
        linkIcon: {
            color: '#F2B63D',
            marginRight: 10
        },
        listSection: {
            textAlign: 'left'
        },
        listIcon: {
            color: '#F2B63D',
            padding: 10
        },
        listText: {
            color: '#000'
        }

    }

};

export default style;