import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";

const FAQ = (props) => {

    const { classes } = props;
    return (
        <div className={classes.container}>
            <h1 className={classes.title}>Häufig gestellte Fragen</h1>
            <div className={classes.content}>
                <h2>Ist Rauchen wirklich so schädlich?</h2>
                <div>
                    Jede/r Zweite stirbt an den Folgen des Tabakkonsums.
                </div>

                <h2>Ab wann bin ich abhängig?</h2>
                <div>
                    <p>Beantworte diese Fragen:</p>

                    <p>Hast du jemals versucht aufzuhören, und es ist dir nicht gelungen?</p>
                    <p>Rauchst du jetzt, weil es hart ist, aufzuhören?</p>
                    <p>Hast du jemals das Gefühl gehabt, abhängig zu sein?</p>
                    <p>Hattest du schon einmal das starke Verlangen, zu rauchen?</p>
                    <p>Hast du schon einmal das Gefühl gehabt, du brauchst dringend eine Zigarette?</p>
                    <p>Ist es für dich schwer an Orten, an denen man rauchen darf, nicht zu rauchen?</p>

                    <p>Wenn du schon eine Zeit rauchfrei warst, oder wenn du versucht hast, nicht zu rauchen:</p>

                    <p>War es schwer für dich, dich zu konzentrieren?</p>
                    <p>Warst du nervöser, weil du nicht geraucht hast?</p>
                    <p>Hattest du das starke Bedürfnis oder den Drang, zu rauchen?</p>
                    <p>Hast du dich nervös, unruhig oder ängstlich gefühlt, weil du nicht rauchen konntest?</p>

                    Wenn du eine dieser Fragen mit JA beantwortest, kannst du davon ausgehen, dass du schon vom Nikotin abhängig bist.
                </div>

                <h2>Ist Passivrauch auch so schlimm?</h2>
                <div>
                    Die Konzentration der Schadstoffe im Passivrauch ist höher als im inhalierten Rauch. Daher schadet Passivrauch auch der Gesundheit.
                </div>

                <h2>Kann ich den Rauchstopp schaffen?</h2>
                <div>
                    Mit ausreichernder Vorbereitung, guter Unterstützung, klarem Ziel und Unterstützung über die ersten Tage hinaus kannst du rauchfrei werden. Mehrere Anläufe gehören für viele dazu, da die Tabakabhängigkeit eine chronische Suchterkrankung ist. Mit Hilfe gelingt der Rauchstopp leichter als alleine.
                </div>

                <h2>Zahlt sich ein Rauchstopp für mich noch aus?</h2>
                <div>
                    Egal wie alt du bist und wie lange du schon geraucht hast, ein Rauchstopp zahlt sich immer aus. Ab dem Zeitpunkt der letzten Zigarette wird der Körper wieder ausreichend mit Sauerstoff versorgt und muss keine Giftstoffe mehr aufnehmen.
                    Das Wohlbefinden steigt, die Durchblutung verbessert sich und dadurch wird deine Lebensqualität besser.
                </div>

                <h2>Ist Gelegenheitsrauchen möglich?</h2>
                <div>
                    Viele Raucher und Raucherinnen möchten „nur noch“ bei bestimmten Gelegenheiten rauchen. Wenn du einmal abhängig warst, ist das für dich wahrscheinlich schwer möglich, da die Rezeptoren im Gehirn schnell  wieder nach mehr Nikotin verlangen werden.
                </div>

                <h2>Werde ich Entzugssymptome haben?</h2>
                <div>
                    Jeder Entzug ist anders. Ungefähr die Hälfte jener, die mit dem Rauchen aufhören, verspüren keine Entzugserscheinungen. Es kann jedoch vorkommen, dass sich die Umstellung körperlich bemerkbar macht. Gereiztheit, Unruhe und Müdigkeit zählen zu den häufigen Symptomen.
                </div>

                <h2>Wie lange dauern Entzugserscheinungen?</h2>
                <div>
                    In der Regel können die körperlichen Entzugserscheinungen zwischen einigen Tagen bis zu 3 Wochen dauern, jedoch lässt die Intensität mit der Dauer der rauchfreien Zeit zunehmend nach. Nach der ersten Woche berichten die meisten Exraucherinnen und Exraucher, dass es ihnen schon viel besser geht.
                </div>

                <h2>Macht Rauchen impotent?</h2>
                <div>
                    Schon bei jungen Männern kann Rauchen zu Erektionsstörungen führen.
                </div>

                <h2>Verbessert Rauchen die Konzentration?</h2>
                <div>
                    Durch das inhalierte Kohlenmonoxid wird man müde, weil der Körper nicht genügend Sauerstoff bekommt. Rauchfrei fühlt man sich weniger gestresst.
                </div>

                <h2>Macht Rauchen schlank?</h2>
                <div>
                    Raucherinnen und Raucher, die schon in der Jugend zu rauchen begonnen haben, sind als Erwachsene dicker.
                </div>

                <h2>Nehme ich beim Rauchstopp zu?</h2>
                <div>
                    Durch den Rauchstopp normalisiert sich der Stoffwechsel. So werden weniger Kalorien verbraucht. Die Geschmacksnerven erholen sich, dadurch schmeckt das Essen besser. Der Blutzuckerspiegel normalisiert sich, es kann anfänglich zu Heißhungerattacken kommen. Viele Exraucherinnen und Exraucher greifen statt zur Zigarette nun zu Süßigkeiten. Achte auf ausreichend Bewegung und ausgewogene Ernährung, dann kannst du dein Gewicht halten.
                </div>

            </div>
        </div>
    );
}

export default (withStyles(style)(FAQ));
