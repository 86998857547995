import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";

const Strategy = (props) => {

    const { classes } = props;

    return (
        <Fragment>
            <div className={classes.content}>
                <div className={classes.listSection}>
                    <p>
                        <div><strong>In Etappen denken</strong></div>
                        <div>Mache es wie bein Sport und setze dir Zwischenziele! Die nächste Etappe: Heute bleibe ich rauchfrei!</div>
                    </p>

                    <p>
                        <div><strong>Trinken</strong></div>
                        <div>Trinke ausreichend! Vor allem kohlensäurehaltiges Mineralwasser lindert das Verlangen und ein Glas oder eine Flasche gibt deinen Händen Halt!</div>
                    </p>

                    <p>
                        <div><strong>An die Motive denken</strong></div>
                        <div>Denke an deine eigenen Motive, rauchfrei und unabhängig zu sein! Lade dir ein Foto und einen Satz dazu auf den Homescreen, dann hast du deinen „besten Grund“ immer parat.</div>
                    </p>

                    <p>
                        <div><strong>Nikotinersatz verwenden</strong></div>
                        <div>Bei körperlicher Abhängigkeit ist Nikotinersatz eine gute Stütze für die ersten rauchfreien Monate. Kurzfristig wirksame Präparate wie z.B. Kaugummi, Lutschtablette oder Spray kannst du bei Verlangensattacken nach Bedarf dosieren und jederzeit griffbereit halten!</div>
                    </p>

                    <p>
                        <div><strong>An bisherige Erfolge denken</strong></div>
                        <div>Schätze deine Erfolge! Auch erst ein rauchfreier Tag gilt! Rufe dir in Erinnerung, welche Situationen du schon rauchfrei geschafft hast und sei stolz auf dich!</div>
                    </p>

                    <p>
                        <div><strong>Die Hände beschäftigen</strong></div>
                        <div>Gib deinen Händen und Fingern etwas zu tun! Spiele mit Münzen oder Steinen in der Hosentasche, beginne zu stricken oder halte deine Wasserflasche fest!</div>
                    </p>

                    <p>
                        <div><strong>Etwas Knabbern</strong></div>
                        <div>Bereite dir schon morgens geschnittenes Obst oder Gemüse vor, so dass du bei einer Verlangensattacke nur mehr zugreifen brauchst. Auch Salzstangerl können helfen. Im Notfall: Der Biss in eine Zitrone bringt dich sicher auf andere Gedanken!</div>
                    </p>

                    <p>
                        <div><strong>Atmen</strong></div>
                        <div>Tief durchatmen hilft! Stelle dir beim Einatmen durch die Nase vor, du bläst deinen Bauch auf wie einen Luftballon und beim Ausatmen durch den Mund denke dir eine Pusteblume, deren Samen davonfliegen!</div>
                    </p>

                    <p>
                        <div><strong>Das Zigarettenmuseum</strong></div>
                        <div>Fülle den Inhalt eines vollen Aschenbechers in ein Einmachglas. Bei starkem Verlangen schraube es auf und riech einmal daran. Wie das stinkt!</div>
                    </p>

                    <p>
                        <div><strong>Bewegung und Sport</strong></div>
                        <div>Körperliche Aktivität hilft dir 3-fach: Sie mindert Entzugssymptome, hilft dein Gewicht zu halten und hebt deine Stimmung! Nutz jede Gelegenheit: steige Treppen, gehe kurze Wege zu Fuß und plane sportliche Aktivitäten in deinen Alltag ein!</div>
                    </p>

                    <p>
                        <div><strong>Einen Baum umarmen</strong></div>
                        <div>Es gibt unzählige gute Möglichkeiten, die dir helfen können: einen Baum umarmen, ein Fußbad in der Früh nehmen, an duftender Seife riechen, einen Kopfstand machen, mit den Kindern Puzzle spielen, einen Brief schreiben, ein Bild betrachten – nutz die  gewonnene Zeit und alle deine Sinne für etwas Schönes, Lustiges oder Neues!</div>
                    </p>
                </div>
            </div>


        </Fragment>
    );

}

export default (withStyles(style)(Strategy));
