import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./RewardStyles";
import { Grid, Button, IconButton } from "@material-ui/core";
import { setActiveReward, setAddDialog } from "../../../Actions/userActions";
import RewardDialog from "./RewardDialog";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PrimaryButton from "../../../Components/PrimaryButton";

class Reward extends Component {
    constructor(props) {
        super(props);
    }

    handleEdit = (reward) => {
        this.props.setActiveReward({reward: reward});
        this.props.setAddDialog();
    };

    render() {
        const { classes, dialog, rewards } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Belohnung</h1>
                </div>

                <div className={classes.content}>
                    <span>Belohnungen sind materielle Dinge, die du dir durch das ersparte Geld leisten und gönnen kannst. Trage ein paar Belohnungen ein. Der Rauchfrei Rechner zeigt an, wann du sie einlösen kannst.</span>
                    {rewards &&
                        <div style={{marginTop: 30}}>
                            {rewards.map(reward => {
                                return(
                                    <div>
                                        <p className={classes.listItem}>
                                            <span>{reward.text}</span>
                                            <span style={{marginLeft: 20}}>{reward.value} € </span>
                                            <IconButton style={{padding: 0, color:'#67bad3'}} onClick={()=>this.handleEdit(reward)}>
                                                <ArrowForwardIosIcon/>
                                            </IconButton>

                                        </p>
                                        <hr/>
                                    </div>
                                )})
                            }
                        </div>
                    }
                    <div className={classes.submitButton}>
                        <PrimaryButton
                            label={'Belohnung hinzufügen'}
                            onClick={()=>this.props.setAddDialog()}
                        />
                    </div>

                </div>

                {dialog && <RewardDialog/>}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        dialog: state.user.addDialog,
        rewards: state.user.data && state.user.data.reward ? state.user.data.reward:[]}
}
const mapDispatchToProps = {
    setAddDialog: setAddDialog,
    setActiveReward: setActiveReward,
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Reward));
