import React, { useEffect }from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./ChecklistStyles";
import {compose } from "redux";
import { Button } from "@material-ui/core";
import {Field, reduxForm } from "redux-form";
import {connect} from "react-redux";
import { checklist } from "../../../utils/constants";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {storeRequest} from "../../../Actions/userActions";
import globalStyle from "../../../themes/GlobalStyles";
import PrimaryButton from "../../../Components/PrimaryButton";

const renderCheckList = (classes, checklist) =>{
    return(
        <div className={classes.listItem}>
            <Field
                name={checklist.text}
                component={CheckboxGroup}
                label={checklist.text}
            />
        </div>
    )
};

const Checklist = (props) => {
    const submit = (values) => {
        const _values = Object.entries(values);
        const newChecklist = [];

        _values.forEach(value => {
            if(value[1] === true) {
                newChecklist.push(value[0])
            }
        });

        const result = [];
        checklist.forEach(entry => {
            if(newChecklist.includes(entry.text)){
                result.push({text: entry.text, value: true, progress: entry.progress});
            }
        });

        props.store({key: 'checklist', values: result});
    };

    const submitAndBack = (values) => {
        submit(values);
        props.history.push('/intern/dashboard');
    };

    return (
        <div className={props.classes.container}>
            <div className={props.classes.title}>
                <h1>Meine Checkliste</h1>
            </div>
            <form className={props.classes.content} >
                {checklist &&
                    <div className={props.classes.listSection}>
                        {checklist.map(item => renderCheckList(props.classes, item))}
                    </div>
                }
            </form>

            <div className={props.classes.submitButton}>
                <PrimaryButton
                    label={'Speichern'}
                    onClick={props.handleSubmit(submit)}
                />
                <br/>
                <PrimaryButton
                    style={{marginTop: 10, marginBottom: 20}}
                    label={'Speichern und zurück'}
                    onClick={props.handleSubmit(submitAndBack)}
                />
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    const userChecklist = state.user.data && state.user.data.checklist ? state.user.data.checklist : [];
    let values = {};
    checklist.forEach(item => {
        let checked = false;
        userChecklist.forEach(initialItem => {
            if(initialItem.text === item.text && initialItem.value){
                checked = true
            }
        });
        values[item.text] = checked;
    });

    return {
        initialValues: {
            ...values
        }
    }
}

const mapDispatchToProps = {
    store: storeRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'checklist-form',
        enableReinitialize: true,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(Checklist);