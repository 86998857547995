import React, {Component, Fragment} from "react";
import {withStyles, Container} from "@material-ui/core";
import style from "../AppContainerStyles";
import DashboardContainer from "../../../Views/app/Dashboard/DashboardContainer";
import {Switch, Route, Redirect} from "react-router-dom";
import Wizzard from "../../../Views/app/Wizzard/WizzardContainer";
import DataTransfer from "../../../Views/app/DataTransfer/DataTransfer";
import User from "../../../Views/app/User/User";
import FlashMessageRoot from "../../../Components/FlashMessage/FlashMessageRoot";
import ResetPassword from "../../../Views/app/DataTransfer/ResetPassword";
import Settings from "../../../Views/app/Settings/Settings";
import OnBoardingContainer from "../../../Views/app/OnBoarding/OnBoardingContainer"
import OnBoardingLogin from "../../../Views/app/OnBoarding/OnBoardingLogin";
import BestReason from "../../../Views/app/BestReason/BestReason";
import Reward from  "../../../Views/app/Reward/Reward";
import Motive from "../../../Views/app/Motive/Motiv";
import Support from "../../../Views/app/Support/Support";
import Scale from "../../../Views/app/Scale/Scale";
import Risks from "../../../Views/app/Risks/Risks";
import Hurdles from "../../../Views/app/Hurdles/Hurdles";
import Checklist from "../../../Views/app/Checklist/Checklist";
import FirstDaysContainer from "../../../Views/app/FirstDays/FirstDaysContainer";
import FirstDaysDetail from "../../../Views/app/FirstDays/FirstDaysDetail";
import Notification from "../../../Views/app/Notifications/Notifications";
import About from "../../../Views/app/About/About";
import Tips from "../../../Views/app/Tips/Tips";
import Wait from "../../../Views/app/Tips/Wait";
import Distract from "../../../Views/app/Tips/Distract";
import Skip from "../../../Views/app/Tips/Skip";
import Strategy from "../../../Views/app/Tips/Strategy";
import Trap from "../../../Views/app/Tips/Trap";
import Facts from "../../../Views/app/Facts/Facts";
import Addiction from "../../../Views/app/Facts/Addiction";
import Healthiness from "../../../Views/app/Facts/Healthiness";
import Tobacco from "../../../Views/app/Facts/Tobacco";
import SecondhandSmoke from "../../../Views/app/Facts/SecondhandSmoke";
import Shisha from "../../../Views/app/Facts/Shisha";
import MedicalSupport from "../../../Views/app/Facts/MedicalSupport";
import Pregnant from "../../../Views/app/Facts/Pregnant";
import FAQ from "../../../Views/app/Facts/FAQ";
import {connect} from "react-redux";
import {loadUserRequest} from "../../../Actions/userActions";
import FitnessList from "../../../Views/app/Dashboard/FitnessList";
import Statistics from "../../../Views/app/Dashboard/Statistics";
import RisksList from "../../../Views/app/Facts/RisksList";
import HurdlesList from "../../../Views/app/Facts/HurdlesList";
import Achievements from "../../../Views/app/Dashboard/Achievements";
import Attacke from "../../../Views/app/Attacke/Attacke";
import Ausrutscher from "../../../Views/app/Attacke/Ausrutscher";
import HilfeRauchverlangen from "../../../Views/app/Attacke/HilfeRauchverlangen";

class AppContainer extends Component {
    render() {
        const { classes } = this.props;

        if(localStorage.rfJwtToken){
            this.props.loadUser();
        }

        return (
            <Container className={classes.container} maxWidth={true}>
                <Switch>

                    <Route exact path="/get_started" name="OnBoarding" component={OnBoardingContainer}/>
                    <Route exact path="/get_started/login" name="OnBoardingLogin" component={OnBoardingLogin}/>

                    {!(!!localStorage.rfJwtToken) &&  <Redirect to="/get_started"/> }

                    <Route exact path="/intern/dashboard" name="DashboardContainer" component={DashboardContainer}/>
                    <Route exact path="/intern/transfer" name="DataTransfer" component={DataTransfer}/>
                    <Route exact path="/intern/forgot_password" name="ForgotPassword" component={ResetPassword}/>
                    <Route exact path="/intern/profil" name="User" component={User}/>
                    <Route exact path="/intern/settings" name="Settings" component={Settings}/>
                    <Route exact path="/intern/wizzard" name="Wizzard" component={Wizzard}/>
                    <Route exact path="/intern/best_reason" name="BestReason" component={BestReason}/>
                    <Route exact path="/intern/reward" name="Reward" component={Reward}/>
                    <Route exact path="/intern/motives" name="Motive" component={Motive}/>
                    <Route exact path="/intern/support" name="Support" component={Support}/>
                    <Route exact path="/intern/scale" name="Scale" component={Scale}/>
                    <Route exact path="/intern/risk" name="Risks" component={Risks}/>
                    <Route exact path="/intern/hurdles" name="Hurdles" component={Hurdles}/>
                    <Route exact path="/intern/checklist" name="Checklist" component={Checklist}/>
                    <Route exact path="/intern/first_days" name="FirstDays" component={FirstDaysContainer}/>
                    <Route exact path="/intern/first_days/detail" name="FirstDaysDetail" component={FirstDaysDetail}/>
                    <Route exact path="/intern/notifications" name="Notifications" component={Notification}/>
                    <Route exact path="/intern/about" name="About" component={About}/>
                    <Route exact path="/intern/tips" name="Tips" component={Tips}/>
                    <Route exact path="/intern/achievements" name="Achievements" component={Achievements}/>


                    <Route exact path="/intern/tips/wait" name="Wait" component={Wait}/>
                    <Route exact path="/intern/tips/distract" name="Distract" component={Distract}/>
                    <Route exact path="/intern/tips/skip" name="Skip" component={Skip}/>
                    <Route exact path="/intern/tips/strategy" name="Strategy" component={Strategy}/>
                    <Route exact path="/intern/tips/trap" name="Trap" component={Trap}/>
                    <Route exact path="/intern/facts" name="Facts" component={Facts}/>

                    <Route exact path="/intern/facts/addiction" name="Addiction" component={Addiction}/>
                    <Route exact path="/intern/facts/healthiness" name="Healthiness" component={Healthiness}/>
                    <Route exact path="/intern/facts/tobacco" name="Tobacco" component={Tobacco}/>
                    <Route exact path="/intern/facts/secondhand_smoke" name="SecondhandSmoke" component={SecondhandSmoke}/>
                    <Route exact path="/intern/facts/shisha" name="Shisha" component={Shisha}/>
                    <Route exact path="/intern/facts/medical_support" name="MedicalSupport" component={MedicalSupport}/>
                    <Route exact path="/intern/facts/pregnant" name="Pregnant" component={Pregnant}/>
                    <Route exact path="/intern/facts/faq" name="FAQ" component={FAQ}/>
                    <Route exact path="/intern/facts/risks" name="RisksList" component={RisksList}/>
                    <Route exact path="/intern/facts/hurdles" name="HurdlesList" component={HurdlesList}/>

                    <Route exact path="/intern/fitness_list" name="Fitness" component={FitnessList}/>
                    <Route exact path="/intern/statistics" name="Statisitcs" component={Statistics}/>

                    <Route exact path="/intern/attacke" name="Attacke" component={Attacke}/>
                    <Route exact path="/intern/attacke/ausrutscher" name="Ausrutscher" component={Ausrutscher}/>
                    <Route exact path="/intern/attacke/hilfe" name="Hilfe" component={HilfeRauchverlangen}/>

                    <Redirect to='/intern/dashboard' />
                    }
                </Switch>
                <FlashMessageRoot/>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = {
    loadUser: loadUserRequest
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(AppContainer));