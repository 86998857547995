import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        text: {
            textAlign: 'center',
        },
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        content: {
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
        },
        buttonGroup: {},
        button: {
            width: '100%',
        },
        selectedButton: {
            width: '100%',
            fontWeight: 'bold',
            background: 'rgba(0,0,0,0.1)'
        },
        inputForm: {
            padding: 20,
            width: '100%',
            maxWidth: 500,
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        checkBox: {
            textAlign: 'left'
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20
        },
        resetPassword: {
            textAlign: 'center',
            marginTop: 20,
        }
    }

};

export default style;