import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";

const Wait = (props) => {

    const { classes } = props;

    return (
        <Fragment>
            <div className={classes.content}>
                <h3>Verlangensattacken dauern meist nicht lange. So wie sie gekommen sind, vergehen sie auch wieder! Warte ab!</h3>
                <div className={classes.listSection}>
                    <ul>
                        <li className={classes.listIcon}><span className={classes.listText}>Sei dir sicher und habe Geduld: Abwarten funktioniert fast immer</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Schau bewusst auf die Uhr und gib dir 5 bis 10 Minuten Zeit</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Tipp von Buddha: Sei achtsam und nimm die Attacke wahr, ohne sie zu bewerten</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Stell dir vor, die Attacke ist ein Bär, der an dir schnuppert: Wenn du dich tot stellst, frisst er dich nicht und geht weiter</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Überbrücke die Minuten mit einem der anderen A´s</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Falls die Attacke doch nicht vorbei gehen sollte: greif auf deine Alternativen zurück</span></li>
                    </ul>
                </div>
            </div>


        </Fragment>
    );

}

export default (withStyles(style)(Wait));
