import {
    LOAD_DASHBOARD_INFO_ERROR, LOAD_DASHBOARD_INFO_REQUEST, LOAD_DASHBOARD_INFO_SUCCESS, SET_DASHBOARD_INFO,
    SET_DASHBOARD_SETTINGS,
    UNSET_DASHBOARD_INFO, UNSET_DASHBOARD_SETTINGS
} from "../Reducers/dashboardReducer";

export const loadDashboardInfoRequest = () => ({
    type: LOAD_DASHBOARD_INFO_REQUEST
});

export const loadDashboardInfoSuccess = (data) => ({
    type: LOAD_DASHBOARD_INFO_SUCCESS,
    ...data
});

export const loadDashboardInfoError = () => ({
    type: LOAD_DASHBOARD_INFO_ERROR
});

export const setDashboardInfo = () => ({
    type: SET_DASHBOARD_INFO
});

export const unsetDashboardInfo = () => ({
    type: UNSET_DASHBOARD_INFO,

});

export const setDashboardSettings = () => ({
    type: SET_DASHBOARD_SETTINGS
});

export const unsetDashboardSettings = () => ({
    type: UNSET_DASHBOARD_SETTINGS,

});
