import React, { useEffect }from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./NotificationsStyles";
import {connect} from "react-redux";
import moment from 'moment';

const renderNotification = (props, notification) =>{

    return(
        <div className={props.classes.listItem}>
            <div className={props.classes.listItemTitle}>{moment(notification.date).format('DD.MM.YYYY HH:mm')}</div>
            <span>
                {notification.text}
            </span>
            <hr/>
        </div>
    )
};

const Notifications = (props) => {

    let availableNotifications = [];

    props.notifications.forEach(n =>{
        let date = moment(n.when).add(n.afterDays, 'days').format('YYYY-MM-DD');
        const time = moment(n.whenTime).format('HH:mm');

        const day = moment(date + ' ' + time).format();

        if(moment(day).isBefore(moment())){
            n.date = day;
            availableNotifications.push(n);
        }

    });

    return (
        <div>
            <div className={props.classes.title}>
                <h1>Alle Benachrichtigungen</h1>
            </div>
            <div className={props.classes.content} >

                {availableNotifications &&
                    <div className={props.classes.listSection}>
                        {availableNotifications.map(item=>{
                            return(
                                renderNotification(props, item)
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        notifications: state.user.data && state.user.data.notifications ? state.user.data.notifications : []
    }
}
export default connect(mapStateToProps, null)(withStyles(style)(Notifications));