import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";
import {connect} from "react-redux";


class Facts extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, history } = this.props;

        return (
            <Fragment>
                <Grid container spacing={5}>
                    <Grid item md={6} xs={12}>
                        <h1>Persönliche Fakten</h1>
                        <div className={classes.content}>
                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/addiction')}><span className={classes.linkIcon}>>></span>Abhängigkeiten</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/risks')}><span className={classes.linkIcon}>>></span>Meine Risiken</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/hurdles')}><span className={classes.linkIcon}>>></span>Meine Stolpersteine</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h1>Gesundheitsfakten</h1>
                        <div className={classes.content}>
                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/healthiness')}><span className={classes.linkIcon}>>></span>Gesundheit</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/tobacco')}><span className={classes.linkIcon}>>></span>Tabak und Nikotin</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/secondhand_smoke')}><span className={classes.linkIcon}>>></span>Passivrauch</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/shisha')}><span className={classes.linkIcon}>>></span>E-Zigarette, Shisha und Co</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h1>Informationen zum Aufhören</h1>
                        <div className={classes.content}>
                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/medical_support')}><span className={classes.linkIcon}>>></span>Medikamentöse Unterstützung</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/pregnant')}><span className={classes.linkIcon}>>></span>Schwangerschaft und Stillzeit</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h1>Häufig gestellte Frage</h1>
                        <div className={classes.content}>
                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/facts/faq')}><span className={classes.linkIcon}>>></span>FAQ</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps){
    return{
        history: ownProps.history
    }


}


export default connect(mapStateToProps, null)(withStyles(style)(Facts));
