import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./AttackeStyles";
import { Grid, Paper } from "@material-ui/core";
import {connect} from "react-redux";
import globalStyle from "../../../themes/GlobalStyles";

class Ausrutscher extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <h1>Ausrutscher</h1>

                <div className={classes.content}>
                    <p>Oje! Eine geraucht, oder sogar mehrere? Ausrutscher und Rückfälle passieren leider wirklich häufig beim Start in die Rauchfreiheit. Ärgere dich nicht – sondern nutze deine Energien, um rauchfrei weiter zu machen, egal was und warum es passiert ist!</p>
                    <ul class="list">
                        <li>Mach unbedingt ab sofort weiter mit der Rauchfreiheit – du hast schon einige Zeit rauchfrei geschafft, du wirst es auch weiterhin können!</li>
                        <li>Denk an deine persönlichen Gründe – warum hast du dich vor einiger Zeit zum Rauchstopp entschlossen?</li>
                        <li>Was hat zu diesem Ausrutscher geführt – überlege wie es dazu gekommen ist und bereite deine Alternativen für solche Situationen besser vor.</li>
                        <li>Wirf jetzt nur nicht den Hut darauf, es ist nicht egal! </li>
                        <li>Hinfallen kann jede/r, es kommt nur darauf an, wieder aufzustehen!</li>
                        <li>Wenn du wieder in dein altes Rauchverhalten gerutscht bist – setz sobald wie möglich einen neuen Rauchstopptag fest. Nutze beim nächsten Anlauf die Erfahrungen die du bisher gesammelt hast.</li>
                    </ul>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps){
    return{
        history: ownProps.history
    }


}


export default connect(mapStateToProps, null)(withStyles({...globalStyle(null), ...style(null)})(Ausrutscher));
