import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";

const SecondhandSmoke = (props) => {

    const { classes } = props;
    return (
        <div className={classes.container}>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <h2>Passivrauch schadet auch</h2>
                    <div className={classes.content}>
                        <div>
                            <p>Wenn jemand raucht, entstehen Schadstoffe. Diese Schadstoffe verschmutzen die Luft. Das nennt sich Passivrauch</p>

                            <p>Überall wo geraucht wird, atmet man unfreiwillig diese verschmutzte Luft ein.</p>

                            <p>Passivrauch entsteht beim Rauchen von Zigaretten, Zigarillos, Zigarren, Pfeifen, Wasserpfeifen (Shishas) und Kräuterzigaretten.</p>

                            <p>Passivrauchen schadet genauso wie selber rauchen. Es macht krank.</p>

                            <p>Halten Sie Innenräume immer rauchfrei und rauchen Sie insbesondere nicht neben Kindern, Schwangeren und kranken Menschen.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <h2>Rückstände bleiben überall</h2>
                    <div className={classes.content}>
                        <div>
                            <p>Passivrauch verschmutzt nicht nur die Luft.</p>

                            <p>Die Schadstoffe des Rauches bleiben überall haften, z.B. an Wänden, auf Möbeln, in Vorhängen, auf Autositzen. Lüften reicht nicht!</p>

                            <p>Passivrauch lagert sich auch auf der Haut, in den Haaren und in der Kleidung ab.</p>

                            <p>Die Schadstoffe bleiben dort noch lange nach dem Rauchen haften. Sie sind auch noch da, wenn Gestank und Rauch weg sind. Die Schadstoffe gelangen wieder in die Luft. Durch das Einatmen kommen sie in den Körper.</p>

                            <p>Räume in denen geraucht wird, sind somit immer eine Gefahr für die Gesundheit.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <h2>Körperliche Auswirkungen</h2>
                    <div className={classes.content}>

                        <p>Passivrauch ist giftig. In Österreich sterben 3 Menschen pro Tag an den Folgen von Passivrauch.</p>

                        <p>Kurzfristig wird die Belastung durch Passivrauch spürbar durch:</p>
                        <ul>
                            <li className={classes.listIcon}><span className={classes.listText}>Augenbrennen</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Kopfschmerzen</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Hustenreiz</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Schwindel</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Müdigkeit</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Atemlosigkeit</span></li>
                        </ul>
                        <p>
                            Langfristig führt Passivrauch zu denselben Erkrankungen wie aktives Rauchen, z.B. Lungenerkrankungen, Herzinfarkte, Krebs und Diabetes.
                        </p>
                        <p>
                            Kindern, kranken Menschen und Schwangeren schadet Passivrauch besonders.
                        </p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default (withStyles(style)(SecondhandSmoke));
