import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DataTransferStyles";
import {Button, TextField, FormControlLabel, Checkbox} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import {compose} from "redux";
import {Field, getFormValues, reduxForm} from 'redux-form';
import TextFieldGroup from "../../../Components/TextFieldGroup";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {createAccountRequest, loginRequest, logoutRequest, setPasswordRequest} from "../../../Actions/authActions";
import {addFlashMessage} from "../../../Actions/flashMessageActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";

class DataTransfer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedButton: 0,
        }
    }

    required = value => value ? undefined : 'Feld erforderlich';
    requiredCheckbox = value => value ? undefined : 'Ihre Zustimmung ist erforderlich';

    handleButtonChange = (index) =>{
        this.setState({selectedButton: index})
    };

    logout = () => {
        this.props.logout({ownProps: this.props.ownProps});
    }

    submit = (values) => {
        const { onBoarding = false, boardingValues, history, setPassword } = this.props;
        switch (this.state.selectedButton) {
            case 0:
                if(values.password !== values.password2){
                    this.props.addFlashMessage({message: 'Bitte überprüfen Sie Ihre Kennwörter', style:'error'})
                } else{

                    if(onBoarding){
                        this.props.createAccount({
                            email: values.email,
                            password: values.password,
                            history: history,
                            boarding: onBoarding,
                            boardingValues: boardingValues
                        });
                    } else {
                        setPassword({
                            email: values.email,
                            password: values.password,
                            history: history,
                        });
                    }
                }
                break;
            case 1:
                this.props.login(
                    {
                        email: values.email,
                        password: values.password,
                        history: history,
                        boarding: onBoarding,
                        boardingValues: boardingValues,
                    });
                break;
            case 2:
                this.props.createAccount(
                    {
                        history: history,
                        boarding: onBoarding,
                        boardingValues: boardingValues
                    });
        }
    };

    render() {
        const { classes, handleSubmit } = this.props;
        return (
            <div className={classes.container}>
                {!(!!localStorage.getItem('rfJwtToken')) ?
                    <form>
                        <div className={classes.title}>
                            <h1>Nur noch ein Schritt!</h1>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.text}>
                                <p>
                                    Sichere deine Daten ab, indem du eine E-Mail-Adresse und ein Kennwort festlegst.
                                </p>
                            </div>

                            <div className={classes.buttonGroup}>
                                <p>
                                    <Button endIcon={this.state.selectedButton === 0 ? <CheckIcon/> : null}
                                            className={this.state.selectedButton === 0 ? classes.selectedButton : classes.button}
                                            onClick={() => this.handleButtonChange(0)}>
                                        neues Konto erstellen
                                    </Button>
                                </p>
                                <p>
                                    <Button endIcon={this.state.selectedButton === 1 ? <CheckIcon/> : null}
                                            className={this.state.selectedButton === 1 ? classes.selectedButton : classes.button}
                                            onClick={() => this.handleButtonChange(1)}>
                                        ich habe schon ein Konto
                                    </Button>
                                </p>
                            </div>

                            {this.state.selectedButton === 0 &&
                            <div className={classes.inputForm}>
                                <p>
                                    <Field
                                        name={'email'}
                                        type={'email'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'gib deine E-Mail Adresse ein'}
                                        validate={[this.required]}
                                    />
                                </p>
                                <p>
                                    <Field
                                        name={'password'}
                                        type={'password'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'lege dein Kennwort fest'}
                                        validate={[this.required]}
                                    />
                                </p>
                                <p>
                                    <Field
                                        name={'password2'}
                                        type={'password'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'wiederhole dein Kennwort'}
                                        validate={[this.required]}
                                    />
                                </p>
                            </div>
                            }

                            {this.state.selectedButton === 1 &&
                            <div className={classes.inputForm}>
                                <p>
                                    <Field
                                        name={'email'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'gib deine E-Mail Adresse ein'}
                                        validate={[this.required]}
                                    />
                                </p>
                                <p>
                                    <Field
                                        name={'password'}
                                        type={'password'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'lege dein Kennwort fest'}
                                        validate={[this.required]}
                                    />
                                </p>
                            </div>
                            }

                            <div className={classes.checkBox}>
                                <Field
                                    name={'datasecurity'}
                                    component={CheckboxGroup}
                                    label={'Ich akzeptiere die Datenschutzbestimmungen'}
                                    validate={[this.requiredCheckbox]}
                                />
                            </div>
                        </div>

                        <div className={classes.submitButton}>
                            <PrimaryButton
                                label={'Weiter'}
                                onClick={handleSubmit(this.submit)}
                            />
                        </div>


                        <div className={classes.resetPassword}>
                            <SimpleButton
                                label={'Kennwort vergessen?'}
                                onClick={() => this.props.ownProps.history.push("/intern/forgot_password")}
                            />
                        </div>
                        <p style={{fontSize: 12, marginBottom: 10}}>
                            Keine Sorge: deine E-Mail-Adresse wird anonymisiert gespeichert und dient ausschließlich
                            zur Verifizeriung bei der Wiederherstellung deiner Daten.<br/>
                            Weder schicken wir Dir E-Mails, noch geben wir deine Adresse an Dritte weiter.
                        </p>
                    </form>
                    :
                    <div className={classes.text}>
                        <p>Sie sind bereits angemeldet um den Benutzer zu wechseln oder sich abzumelden, drücken Sie den "Logout"-Button.</p>
                        <PrimaryButton
                            label={'Logout'}
                            onClick={this.logout}
                        />
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
    }
}
const mapDispatchToProps = {
    login: loginRequest,
    createAccount: createAccountRequest,
    setPassword: setPasswordRequest,
    addFlashMessage: addFlashMessage,
    logout: logoutRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'auth-form'
    }),
    withStyles(style)
)(DataTransfer);
