import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";
import { Grid, Paper } from "@material-ui/core";
import {connect} from "react-redux";




class Tips extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, history } = this.props;

        return (
            <div className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <h1>Die 3 A Technik</h1>
                        <div className={classes.content}>
                            <div>Probier die 3 A´s aus, ein A funktioniert bestimmt auch für dich. Die 3 A Technik:</div>
                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/tips/wait')}><span className={classes.linkIcon}>>></span>Abwarten</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/tips/distract')}><span className={classes.linkIcon}>>></span>Ablenken</p>
                                <p className={classes.link} onClick={()=>history.push('/intern/tips/skip')}><span className={classes.linkIcon}>>></span>Abhauen</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1>Strategien zum Rauchstopp</h1>
                        <div className={classes.content}>
                            <div>Hier findest du Strategien, die schon viele hilfreich gefunden haben.</div>

                            <div>
                                <p className={classes.link} onClick={()=>history.push('/intern/tips/strategy')}><span className={classes.linkIcon}>>></span>Beliebte Strategien</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1>Falle</h1>
                        <div className={classes.content}>
                            <p className={classes.link} onClick={()=>history.push('/intern/tips/trap')}><span className={classes.linkIcon}>>></span>Achtung Falle!</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps){
    return{
        history: ownProps.history
    }


}


export default connect(mapStateToProps, null)(withStyles(style)(Tips));
