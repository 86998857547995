
/* Action types */
const PREFIX = "auth/";
export const LOGIN_REQUEST = PREFIX + "LOGIN_REQUEST";
export const LOGIN_SUCCESS = PREFIX + "LOGIN_SUCCESS";
export const LOGIN_FAILED = PREFIX + "LOGIN_FAILED";

export const CREATE_ACCOUNT_REQUEST = PREFIX + "CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_SUCCESS = PREFIX + "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILED = PREFIX + "CREATE_ACCOUNT_FAILED";

export const SET_PASSWORD_REQUEST = PREFIX + "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = PREFIX + "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = PREFIX + "SET_PASSWORD_FAILED";

export const FORGOT_PASSWORD_REQUEST = PREFIX + "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = PREFIX + "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = PREFIX + "FORGOT_PASSWORD_FAILED";

export const SET_RESET_PASSWORD = PREFIX + "SET_RESET_PASSWORD";
export const UNSET_RESET_PASSWORD = PREFIX + "UNSET_RESET_PASSWORD";

export const LOGOUT_REQUEST = PREFIX + "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = PREFIX + "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = PREFIX + "LOGOUT_FAILED";

/* initial state */
const initialState = {
    isFetching: false,
    error: false,
    resetEmail: false,
    token: false,
};

/* Reducer */
const authReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                token: action.token,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                token: action.token
            };
        case CREATE_ACCOUNT_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case SET_PASSWORD_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
            };
        case SET_PASSWORD_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
            };
        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        case SET_RESET_PASSWORD:
            return {
                ...state,
                resetEmail: true
            };
        case UNSET_RESET_PASSWORD:
            return {
                ...state,
                resetEmail: false
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: false,
                token: false
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            };
        default:
            return state;
    }
};
export default authReducer;