import React  from 'react';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'

const TimePickerGroup = ({ input, label, required, disablePast, variant, inputVariant, style, value, disableFuture, dateFormat = "dd.MM.yyyy HH:mm" }) => {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                value={input.value}
                onChange={input.onChange}
                autoOk
                label={label}
                variant={variant}
                inputVariant={inputVariant}
                required={required}
                disablePast = {disablePast}
                format={'HH:mm'}
                style={style}
                ampm={false}
                disableFuture={disableFuture}
            />
        </MuiPickersUtilsProvider>
    )
}

export default TimePickerGroup;