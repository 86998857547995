import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import {storeRequest, unsetStatusAlready} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";


class SetStatusAlreadyDialog extends Component {
    constructor(props) {
        super(props);

    }

    submitStatus = () => {
        let profile = this.props.profile;
        let appSettings = this.props.appSettings;

        appSettings.status = 'already';
        appSettings.statusNumeric = 5;
        appSettings.stopDate = new Date();
        appSettings.notificationDate = appSettings.stopDate;

        profile.status = 'already';
        profile.statusNumeric = 5;
        appSettings.stopDate = new Date();

        this.props.store({key: 'app-settings', values: appSettings});
        this.props.store({key: 'profile', values: profile});

        this.props.unsetStatus();
    }

    render() {
        const { classes, status } = this.props;
        return (
            <Dialog maxWidth={'xs'} open={status}>
                <DialogTitle style={{textAlign: 'center'}}>
                    Jetzt rauchfrei
                </DialogTitle>
                <DialogContent style={{textAlign: 'center'}}>
                    <span className={classes.modalText}>Bist du jetzt bereit für den Rauchstopp? Sobald du diesen Knopf drückst, beginnt deine rauchfreie Zeit und der Rauchfrei Zähler startet.</span>
                </DialogContent>
                <DialogActions>
                    <div style={{flexDirection: 'column', flex: 1, textAlign: 'center', marginTop: 20}}>
                        <div style={{flex: 1, marginBottom: 10}}>
                            <PrimaryButton label={'Weiter'} onClick={() => this.submitStatus()} />
                            <div>
                                <SimpleButton label={'Abbrechen'} onClick={() => this.props.unsetStatus()} />
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return{
        status: state.user.status,
        profile: state.user.data && state.user.data.profile ? state.user.data.profile : {},
        appSettings: state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings'] : {},
    }
}
const mapDispatchToProps = {
    unsetStatus: unsetStatusAlready,
    store: storeRequest
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(withStyles(style)(SetStatusAlreadyDialog));