import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./HealthinessDashboardComponentStyles";
import { Grid } from '@material-ui/core';

const HealthinessDashboardComponent = ({ classes, paper, icon, title, description, healthinessProgress }) => {
    let tileStyle= {
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    };

    if(!paper){
        tileStyle = {
            borderRadius: 0
        }
    }

    const getProgress = () => {
        if(healthinessProgress){
            return healthinessProgress;
        }
        return 0;
    };

    return (
        <div className={classes.dashboardTiles} style={tileStyle}>
            <div className={classes.container}>
                <div className={classes.content} >
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            {title &&
                                <div className={classes.title}>
                                    {title}
                                </div>
                            }
                            {description &&
                                <div className={classes.description}>
                                    {description}
                                </div>
                            }
                        </Grid>

                        <Grid item xs={5}>
                            {icon &&
                                <div className={classes.icon}>
                                    {icon}
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.footerContainer}>
                    <div className={classes.footer}>
                        <div className={classes.footerBackground} style={{width: getProgress() + '%'}}></div>
                        <div className={classes.footerText}>
                            {getProgress()}% vom nächsten Fortschritt erreicht
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}



export default (withStyles(style)(HealthinessDashboardComponent));