import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./MotivDashboardComponentStyles";
import HelpIcon from '@material-ui/icons/Help';
import QuestionMark from "../../../Assets/v2/question_mark.png";

const MotivDashboardComponent = ({ classes, name, title, pros, cons, dashboardInfo, dashboardInfoData, showInfo, status, onClick }) => {

    const renderListItems = (items) => {
        let result = '';
        items.forEach((i, index) => {
            if(index == 0){
                result = i.text;
            } else {
                result = result + ', ' + i.text;
            }
        });
        return result;
    };

    let tileStyle = {
        backgroundColor: '#fff',
        borderRadius: 15,
        boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.02)'
    };

    let modalData = {};
    if(dashboardInfoData && dashboardInfoData.length){
        modalData = dashboardInfoData.find(item => item.key === name);
    }

    return (
        <div className={classes.dashboardTilesBadges} style={tileStyle} onClick={onClick}>
            {dashboardInfo &&
                <div className={classes.infoOverlay} onClick={()=> showInfo({data: {...modalData}})}>
                    <img src={QuestionMark} className={classes.infoIcon}/>
                </div>
            }
            <div className={classes.container} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
                <span className={classes.badge} style={{backgroundColor: status == 'already' ? '#67bad3' : '#fbd707'}}>
                   {title}
                </span>
                <div style={{width: '100%', height: '100%', paddingTop: 20, padding: 10}}>
                    <div style={{width: '50%', height: '75%', float: 'left', textAlign: 'center', fontSize: 18, borderRight: '2px solid rgba(0,0,0,0.2)'}}>
                        <div className={classes.subtitle}>Pro</div>
                        {renderListItems(pros)}
                    </div>
                    <div style={{height: '75%', padding: 10, textAlign: 'center', fontSize: 18}}>
                        <div className={classes.subtitle}>Contra</div>
                        {renderListItems(cons)}
                    </div>
                </div>

            </div>
        </div>
    );

}

export default (withStyles(style)(MotivDashboardComponent));