import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { Button, MenuItem } from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../../../Components/SelectGroup";
import PrimaryButton from "../../../Components/PrimaryButton";
import CheckboxGroup from "../../../Components/CheckboxGroup";

const OnBoardingAge = ({classes, age, sex, finishOnBoarding}) => {

    const _sex = [
        {value:0, label: 'bitte auswählen'},
        {value:'male', label:'Männlich'},
        {value:'female', label:'Weiblich'}
    ];
    const _age = [
        {value: 0, label: 'bitte auswählen'},
        {value:15, label:'Jünger als 16 Jahre'},
        {value:16, label:'16 - 18 Jahre'},
        {value:18, label:'18 - 20 Jahre'},
        {value:20, label:'20 - 30 Jahre'},
        {value:30, label:'30 - 40 Jahre'},
        {value:40, label:'40 - 50 Jahre'},
        {value:50, label:'50 - 60 Jahre'},
        {value:60, label:'älter als 60 Jahre'}
    ];

    const renderOptions = (options) => {
        return options.map( option => renderOption(option))
    };

    const renderOption = (option) => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>
    };

    return (
        <div className={classes.container}>
            <h1 className={classes.dialogTitle}>Deine Person</h1>
            <div className={classes.content}>
                <div className={classes.inputContainer}>
                    <div className={classes.inputLabel} style={{marginTop: 0}}>Alter
                        <Field
                            name='age'
                            component={SelectGroup}
                            fullWidth
                        >
                            {renderOptions(_age)}
                        </Field>
                    </div>
                    <div className={classes.inputLabel}>Geschlecht
                        <Field
                            name='sex'
                            component={SelectGroup}
                            fullWidth
                        >
                            {renderOptions(_sex)}
                        </Field>
                    </div>

                    {sex === 'female' &&
                        <div className={classes.inputLabel}>
                            <Field
                                name='pregnant'
                                component={CheckboxGroup}
                                fullWidth
                                label={'Schwanger'}
                            />
                        </div>
                    }
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    disabled={!((age && age!=0 && sex))}
                    onClick={finishOnBoarding}
                    label={'Weiter'}
                />
            </div>
        </div>
    )
};

export default withStyles(style)(OnBoardingAge);
