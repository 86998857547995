import React, {Fragment} from 'react';
import { connect } from 'react-redux';

import FlashMessage from './FlashMessage';


const FlashMessageRoot = ( { flashMessages } ) => {
    if (!flashMessages || flashMessages.length <= 0) {
        return null;
    } else {

        let render = [];

        if(flashMessages && flashMessages.length > 0){
            flashMessages.map((item, index) => {
                render.push(<FlashMessage {...item} key={ item.id } />);
            });
        }
        
        return (
            <Fragment>
                { render }
            </Fragment>
        );
    }
}

export default connect(
    state => { return { flashMessages: state.flashMessage }; }
)(FlashMessageRoot)