import {put, call, select} from "@redux-saga/core/effects";
import {
    uploadFileSuccess, getFileSuccess, deleteFileSuccess,
} from "../Actions/fileActions";
import {addFlashMessage} from "../Actions/flashMessageActions";


function onLoad(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(){
            resolve(reader.result);
        }

        reader.onerror = function(){

        }
    });
}

export function* uploadFile (api, fileData) {
    try {
        const user = state => state.user;
        const { data } = yield select(user);

        const app_settings = {
            ...data['app-settings']
        };
        const fileResult = yield call(onLoad, fileData.file);
        if(fileResult){
            app_settings['bestReasonImage'] = fileResult;
            localStorage.setItem('app-settings', JSON.stringify(app_settings));
            yield call(api.store, 'app-settings', app_settings);

            yield put(uploadFileSuccess());
            yield put(addFlashMessage({message: 'Bild wurde erfolgreich gespeichert', style:'success'}));
        }
    } catch (e) {
        console.log(e)
    }
}

export function* deleteFileRequest (api, id) {
    try {
        let result = false;
        result = yield call(api.deleteFile, id.id);

        if (result) {
            yield put(deleteFileSuccess());

        } else {
            console.log("FAIL")
        }

    } catch (e) {
        console.log(e)
    }
}