import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./BestReasonStyles";
import {Button, TextField, FormControlLabel, Checkbox, MenuItem} from "@material-ui/core";
import {compose} from "redux";
import {Field, getFormValues, reduxForm, change} from 'redux-form';
import SelectGroup from "../../../Components/SelectGroup";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import {bestReasonType} from "../../../utils/constants";
import FileUpload from "../../../Components/FileUpload";
import { storeRequest} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";

class BestReason extends Component {
    constructor(props) {
        super(props);
    }

    required = value => value ? undefined : 'Feld erforderlich';

    renderOptions = (options) => {
        return options.map( option => this.renderOption(option))
    };

    renderOption = (option) => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>
    };

    handleReasonTypeChange = (reasonType) => {
        switch(reasonType){
            case 'Gesundheit':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will gesund werden. Ich will das Beste für meinen Körper. Ich will frei durchatmen.');
                break;
            case 'Wohlbefinden und Aussehen':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will meine Kondition verbessern. Ich will mich wohl fühlen. Ich will gut riechen. Ich will schönere Haut und weiße Zähne.');
                break;
            case 'Suchtfreiheit':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will frei und unabhängig sein. Ich will über meine Zeit selbst bestimmen. Ich will Chef/Chefin in meinem Körper sein.');
                break;
            case 'Geld':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will mir Geld sparen. Ich will mein Geld nicht in die Luft blasen.');
                break;
            case 'Familie und Freunde':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will rauchfrei für meine Liebsten sein. Ich will ein gutes Vorbild sein. Ich will niemanden durch meinen Rauch belasten.');
                break;
            case 'Kinderwunsch und Schwangerschaft':
                this.props.changeReason('reason-form', 'bestReason', 'Ich will die Chance verbessern, schwanger zu werden. Ich will das Beste für mein Ungeborenes.');
                break;
            case 'Ziele und Werte':
                this.props.changeReason('reason-form', 'bestReason', 'Rauchfrei sein passt besser zu mir. Ich möchte ein Vorbild sein. Ich achte auch sonst auf meine Gesundheit. Ich will mehr Zeit für das, was mir wichtig ist. Rauchfrei sein passt zu meinem Job.');
                break;
            default:
                this.props.changeReason('reason-form', 'bestReason', '');
                break;
        }
    };

    submit = (values) =>{
        let data = {
            ...this.props.appSettings,
            ...values,
            bestReasonImage: this.props.bestReasonImage
        };

        this.props.store({key: 'app-settings', values: data})
    };

    deleteBestReasonImage = () => {
        let items = JSON.parse(localStorage.getItem("app-settings"));
        items.bestReasonImage = '';

        this.props.store({key: 'app-settings', values: items})

    };

    render() {
        const { classes, handleSubmit, bestReasonImage  } = this.props;

        return (
            <div className={classes.container}>
                <form>
                    <div className={classes.title}>
                        <h1>Mein bester Grund</h1>
                    </div>

                    <div className={classes.content}>

                        <div className={classes.text}>
                            <p>
                                Wähl einen Satz aus oder gib einen eigenen Satz ein, der dich motiviert. Lade ein Foto hoch, das dich daran erinnert,
                                warum du dich für die Rauchfreiheit entschieden hast.
                            </p>

                        </div>

                        <div>
                            <Field
                                name='bestReasonTyp'
                                component={SelectGroup}
                                fullWidth
                                onChange={(e) => this.handleReasonTypeChange(e.target.value)}
                            >
                                {this.renderOptions(bestReasonType)}
                            </Field>

                            <div style={{height:20}}></div>

                            <Field
                                name='bestReason'
                                component={TextFieldGroup}
                                fullWidth
                                multiline={true}
                                row={3}
                                placeholder={'Dies ist mein bester Grund ...'}
                            />

                        </div>

                        <div className={classes.text}>
                            Das ausgewählte Bild wird im Querformat, Verhältnis 16:9 angezeigt. Bilder die im Hochformat hochgeladen werden,
                            werden abgeschnitten.
                        </div>

                        {bestReasonImage &&
                            <div className={classes.submitButton}>
                                <img src={bestReasonImage} alt="BestReasonImage" height="600" className={classes.bestReasonImage}/>
                                <Button
                                    style={{width: '50%'}}
                                    variant="text"
                                    color="primary"
                                    onClick={this.deleteBestReasonImage}
                                >
                                    Bild entfernen
                                </Button>
                            </div>
                        }

                        <div className={classes.uploadButton}>
                            <FileUpload/>
                        </div>
                    </div>


                    <div className={classes.submitButton}>
                        <PrimaryButton
                            onClick={handleSubmit(this.submit)}
                            label={'Speichern'}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const bestReasonImage = state.user.data && state.user.data['app-settings'] && state.user.data['app-settings'].bestReasonImage?state.user.data['app-settings'].bestReasonImage:false;
    const bestReasonTyp = state.user.data && state.user.data['app-settings'] && state.user.data['app-settings'].bestReasonTyp?state.user.data['app-settings'].bestReasonTyp:0;
    const bestReason = state.user.data && state.user.data['app-settings'] && state.user.data['app-settings'].bestReason?state.user.data['app-settings'].bestReason:'';

    return{
        formValues: getFormValues('reason-form')(state),
        bestReasonImage: bestReasonImage,
        appSettings: state.user.data && state.user.data['app-settings'],
        initialValues: {
            bestReasonTyp: bestReasonTyp,
            bestReason: bestReason
        }

    }
}
const mapDispatchToProps = {
    changeReason: change,
    store: storeRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'reason-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(BestReason);
