import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import { Grid } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock';
import achievement_1 from '../../../Assets/achievements/achievement_1.png';
import achievement_2 from '../../../Assets/achievements/achievement_2.png';
import achievement_3 from '../../../Assets/achievements/achievement_3.png';
import achievement_4 from '../../../Assets/achievements/achievement_4.png';
import achievement_6 from '../../../Assets/achievements/achievement_6.png';
import achievement_7 from '../../../Assets/achievements/achievement_7.png';
import achievement_8 from '../../../Assets/achievements/achievement_8.png';
import achievement_9 from '../../../Assets/achievements/achievement_9.png';
import achievement_10 from '../../../Assets/achievements/achievement_10.png';
import achievement_11 from '../../../Assets/achievements/achievement_11.png';
import achievement_12 from '../../../Assets/achievements/achievement_12.png';
import achievement_13 from '../../../Assets/achievements/achievement_13.png';

class Achievements extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes } = this.props;
        const dayCountSmokeFree = this.props.location && this.props.location.state && this.props.location.state.dayCountSmokeFree ? this.props.location.state.dayCountSmokeFree : false
        return (

            <Fragment>
                <div className={classes.title}>
                    <h1>Achievements</h1>
                </div>

                <Grid container spacing={10}>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 0 &&
                            <div className={classes.achievements}>
                                <img src={achievement_1} alt=""/>
                                <p><strong>Gratulation!</strong><br/>Den wichtigsten Schritt hast du gemacht!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 3 ?
                            <div className={classes.achievements}>
                                <img src={achievement_2} alt=""/>
                                <p><strong>Super!</strong><br/>Die erste Etappe hast du geschafft. Bleib dran!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Das ist bald deins! Schon am 3. rauchfreien Tag!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 5 ?
                            <div className={classes.achievements}>
                                <img src={achievement_3} alt=""/>
                                <p><strong>Juhu!</strong><br/>Du bist schon einige Tage rauchfrei. Mach weiter so!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Halte noch durch, am 5. rauchfreien Tag kommt die nächste Auszeichnung!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 7 ?
                            <div className={classes.achievements}>
                                <img src={achievement_4} alt=""/>
                                <p><strong>Gut gemacht!</strong><br/>Deine erste Woche ist geschafft! Weiter so!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Lass dich überraschen, was am 7. rauchfreien Tag folgt!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 14 ?
                            <div className={classes.achievements}>
                                <img src={achievement_6} alt=""/>
                                <p><strong>Tolle Leistung!</strong><br/>Gratulation zu 14 Tagen. Sei stolz darauf!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Du bist auf einem guten Weg! An deinem 14. rauchfreien Tag ist es soweit.</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 21 ?
                            <div className={classes.achievements}>
                                <img src={achievement_7} alt=""/>
                                <p><strong>Trara!</strong><br/>Die goldene Krone ist für dich! Schon 21 Tage!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Mach weiter, um an deinem 21. rauchfreien Tag diese Medaille zu bekommen!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 30 ?
                            <div className={classes.achievements}>
                                <img src={achievement_8} alt=""/>
                                <p><strong>Toll!</strong><br/>Nach einem Monat weißt Du wie es geht! Bleib dabei!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Weiter so! Zu deinem 1. rauchfreien Monat gehört diese Auszeichnung dir!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 60 ?
                            <div className={classes.achievements}>
                                <img src={achievement_9} alt=""/>
                                <p><strong>Hut ab!</strong><br/>2 Monate hast du geschafft! Der Goldpokal ist für dich!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Bald kannst du ihn sehen: deinen Pokal für 2 rauchfreie Monate!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 90 ?
                            <div className={classes.achievements}>
                                <img src={achievement_10} alt=""/>
                                <p><strong>Gratulation!</strong><br/>Nach 3 Monaten bist Du schon ein Rauchfrei-Profi!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Bleib dran! Nach 3 rauchfreien Monaten wirst du damit ausgezeichnet!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 180 ?
                            <div className={classes.achievements}>
                                <img src={achievement_11} alt=""/>
                                <p><strong>Applaus!</strong><br/>Ein Goldpokal zum 6-Monats-Jubiläum! Sei stolz auf dich!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Neugierig, was nach 6 rauchfreien Monaten folgt? Mach rauchfrei weiter!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 270 ?
                            <div className={classes.achievements}>
                                <img src={achievement_12} alt=""/>
                                <p><strong>Yeah!</strong><br/>9 Monate rauchfrei! Die Silberkrone gehört dir!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Das gehört bald dir! 9 rauchfreie Monate und du bist Rauchfrei-König/in!</p>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.achievementsGrid}>
                        {dayCountSmokeFree >= 360 ?
                            <div className={classes.achievements}>
                                <img src={achievement_13} alt=""/>
                                <p><strong>Tatatataa!</strong><br/>1 Jahr Rauchfrei! Gratulation!</p>
                            </div>
                            :
                            <div className={classes.achievements}>
                                <p><LockIcon fontSize={'large'}/></p>
                                <p>Etwas Geduld, und du erhältst die höchste Auszeichnung zu deinem 1-jährigenRauchfrei-Jubiläum!</p>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default connect(null, null) (withStyles(style)(Achievements));