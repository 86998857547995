import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        dialogTitle: {
            fontSize: 36,
            textAlign: 'center',
        },
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        content: {
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
            width: 500,
        },
        addButton: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 50,
        },
        dialogContent: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',

        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 10
        },
        listItem: {
            fontWeight: 'bold',
        },
    }

};

export default style;