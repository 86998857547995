import React from "react";

const style = theme => {
    return {
        container: {
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
            position: 'relative'
        },
        content: {
            display: 'flex',
            flex: 4,
            padding: 10,
        },
        icon: {
            width: '100%',
            height: '30%',
            textAlign: 'center'
        },
        title: {
            width: '100%',
            height: '20%',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: 24,
            padding: 5,
        },
        description: {
            width: '100%',
            height: '50%',
            textAlign: 'left',
            paddingTop: 20,
            marginBottom: 50,
            padding: 5,
        },
        footerContainer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
        },
        footer: {
            position: 'relative',
            width: '100%',
            height: '100%',
            padding: 10,
            backgroundColor: '#DCDCDC',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: 0,
        },
        footerText: {
            width: '100%',
            color: '#000',
            textAlign: 'center',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            position: 'relative',
            zIndex: 100,
        },
        footerBackground: {
            zIndex: 1,
            height: '100%',
            backgroundColor: '#67bad3',
            position: 'absolute',
            left: 0,
            bottom: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 8,
        }
    }

};

export default style;