import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import {setNextStep, setPreviousStep, unsetSteps} from "../../../Actions/wizzardActions";
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import {reduxForm} from "redux-form";
import moment from "moment";
import SimpleButton from "../../../Components/SimpleButton";


class OnBoardingDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialog: true
        }
    }

    componentWillUnmount(){
        this.setState({dialog: true})
        this.props.unsetSteps();
    }

    render() {
        const { classes, component, stepIndex } = this.props;

        return (
            <div className={classes.dialog}>
                <form>
                    {component}
                </form>
                {stepIndex > 0 &&
                    <div style={{marginTop: 10}}>
                        <SimpleButton label={'zurück'} onClick={() => this.props.previousStep()} />
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        stepIndex: state.wizzard.stepIndex
    }
}
const mapDispatchToProps = {
    nextStep: setNextStep,
    previousStep: setPreviousStep,
    unsetSteps: unsetSteps,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'onBoarding-form',
        initialValues: {
            priceType: false,
            noOfCigarettes: 0,
            firstCigarette: 0,
            statusNumeric: 1,
            stopDate: moment(),
            medianPrice: 5.5,
            age: 0,
            sex: 0
        }
    }),
    withStyles(style)
)(OnBoardingDialog);