import jwtDecode from 'jwt-decode';
import moment from "moment";
import setAuthorizationToken from "./utils/setAuthorizationToken";
var axios = require('axios');

var axiosInstance = axios.create({
    baseURL: 'https://app.rauchfrei.at/api'
    //baseURL: 'http://192.168.0.103:3000/api'
});

axiosInstance.interceptors.request.use(async (config) => {
    const userLang = navigator.language || navigator.userLanguage;

    if(config.url.indexOf('/auth/extend_token') > -1){

    } else {
        await (async () => {
            let bearer = config.headers.common['Authorization'];
            if(bearer && bearer.indexOf('Bearer ') > -1){
                let token = bearer.split('Bearer ')[1];
                if(token){
                    const { exp } = jwtDecode(token);
                    const limit = moment().add(60, 'seconds');
                    if (limit.isBefore(moment.unix(exp))) {
                        //return bearer;
                    } else {
                        let refreshToken = localStorage.getItem('rfRefreshToken');
                        if(refreshToken){
                            setAuthorizationToken(null);
                            let res = await axiosInstance.post('/auth/extend_token', {refreshToken: refreshToken});
                            if(res && res.data && res.data.accessToken){
                                localStorage.setItem('rfJwtToken', res.data.accessToken);
                                setAuthorizationToken(res.data.accessToken);
                                config.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
                            }
                        }
                    }
                }
            }
        })();
    }

    if(userLang){
        const lang = userLang.substr(0,2)
        config.headers.common['Language'] = lang;
    }

    if (config.responseType != "arraybuffer") {
        config.headers.common['Pragma'] = 'no-cache';
    }

    return config;
}, (err) => {
    console.log('err')
    return Promise.reject(err);
});

export default axiosInstance;