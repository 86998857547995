import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DataTransferStyles";
import {Button} from "@material-ui/core";
import {compose} from "redux";
import { Field, reduxForm } from 'redux-form';
import TextFieldGroup from "../../../Components/TextFieldGroup";
import {forgotPasswordRequest, setResetPassword, unsetResetPassword} from "../../../Actions/authActions";
import {addFlashMessage} from "../../../Actions/flashMessageActions";
import globalStyle from "../../../themes/GlobalStyles";
import PrimaryButton from "../../../Components/PrimaryButton";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
    }

    required = value =>  value ? undefined : 'Feld erforderlich'

    handleBack = () => {
        this.props.ownProps.history.push("/intern/transfer");
        this.props.unsetResetPassword();
    }

    render() {
        const { classes, handleSubmit, resetEmail  } = this.props;

        const submit = (values) => {
          this.props.forgotPassword(values.email)
        };

        return (
            <div className={classes.container}>
                <form>
                    <div className={classes.title}>
                        <h1>Hast du dein Kennwort vergessen?</h1>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.text}>
                            <p>
                                Kein Problem! Gib' einfach die E-Mail-Adresse ein, die du beim Erstellen deines Kontos verwendet hast und klicke auf "Kennwort zurücksetzen".
                            </p>
                            <p>
                                Wir schicken dir dann eine E-Mail mit Anweisungen zu, wie du ganz einfach ein neues Kennwort festlegen kannst.
                            </p>
                        </div>

                        {!resetEmail ?
                            <div className={classes.inputForm}>
                                <p>
                                    <Field
                                        name={'email'}
                                        type={'email'}
                                        component={TextFieldGroup}
                                        fullWidth
                                        placeholder={'gib deine E-Mail Adresse ein'}
                                        validate={[this.required]}
                                    />
                                </p>
                            </div>
                            :
                            <div className={classes.text}>
                                <p>
                                    Alles klar! Wenn es ein Konto für diese E-Mail-Adresse gibt, dann haben wir dir gerade ein E-Mail mit weiteren Anweisungen gesendet. Bitte prüfe deinen Posteingang.
                                </p>

                                <p>
                                    <Button
                                        style={{width: '50%'}}
                                        variant="text"
                                        color="primary"
                                        onClick={this.handleBack}
                                    >
                                        <u>zurück zur Anmeldung?</u>
                                    </Button>
                                </p>
                            </div>
                        }

                    </div>

                    <div className={classes.submitButton}>
                        <PrimaryButton
                            label={'Kennwort zurücksetzen'}
                            onClick={handleSubmit(submit)}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        resetEmail: state.auth.resetEmail,
    }
}
const mapDispatchToProps = {
    forgotPassword: forgotPasswordRequest,
    addFlashMessage: addFlashMessage,
    unsetResetPassword: unsetResetPassword
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'reset-password-form'
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(ResetPassword);
