import {
    LOGIN_REQUEST,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILED,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAILED,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    SET_RESET_PASSWORD,
    UNSET_RESET_PASSWORD, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILED, EXTEND_TOKEN_REQUEST, EXTEND_TOKEN_SUCCESS,
    EXTEND_TOKEN_FAILED
} from "../Reducers/authReducer";

export const loginRequest = (user) => ({
    type: LOGIN_REQUEST,
    ...user
});

export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    ...token
});

export const loginFailed = () => ({
    type: LOGIN_FAILED,
});

export const createAccountRequest = (user) => ({
    type: CREATE_ACCOUNT_REQUEST,
    ...user
});

export const createAccountSuccess = (token) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    ...token

});

export const createAccountFailed = () => ({
    type: CREATE_ACCOUNT_FAILED,
});

export const setPasswordRequest = (user) => ({
    type: SET_PASSWORD_REQUEST,
    ...user
});

export const setPasswordSuccess = (values) => ({
    type: SET_PASSWORD_SUCCESS,
    ...values

});

export const setPasswordFailed = () => ({
    type: SET_PASSWORD_FAILED,
});

export const forgotPasswordRequest = (email) => ({
    type: FORGOT_PASSWORD_REQUEST,
    email
});

export const forgotPasswordSuccess = () => ({
    type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailed = () => ({
    type: FORGOT_PASSWORD_FAILED,
});

export const setResetPassword = () => ({
    type: SET_RESET_PASSWORD,
});

export const unsetResetPassword = () => ({
    type: UNSET_RESET_PASSWORD,
});

export const logoutRequest = (route) => ({
    type: LOGOUT_REQUEST,
    ...route
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutFailed = () => ({
    type: LOGOUT_FAILED,
});