import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./BadgeDashboardComponentStyles";
import QuestionMark from '../../../Assets/v2/question_mark.png';

const BadgeDashboardComponent = ({ classes, name, paper=false, title, description, bold = false, listItems = [], dashboardInfo, dashboardInfoData, showInfo, status, onClick }) => {

    const renderListItems = (items) =>{
        return items.map((item, index) => {return renderItem(item, index+1)});
    };

    const renderItem = (item, index) =>{
        return (
            <div className={classes.listContainer}>
                <div style={{overflow: 'hidden'}}>
                    <div className={classes.listIcon}> {index}</div>
                    <div style={{overflow: 'hidden', height: '30px', lineHeight: '30px', paddingLeft: '10px'}}>{item.text}</div>
                </div>
            </div>
        )
    };

    let tileStyle= {};
    if(paper){
        tileStyle = {
            backgroundColor: '#fff',
            borderRadius: 15,
            boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.02)'
        }
    }

    let modalData = {};
    if(dashboardInfoData && dashboardInfoData.length){
        modalData = dashboardInfoData.find(item => item.key === name);
    }

    return (
        <div className={classes.dashboardTilesBadges} style={tileStyle} onClick={onClick}>
            {dashboardInfo &&
                <div className={classes.infoOverlay} onClick={()=> showInfo({data: {...modalData}})}>
                    <img src={QuestionMark} className={classes.infoIcon}/>
                </div>
            }
            <div className={classes.container} style={{filter: dashboardInfo ? 'blur(3px)' : 'none' }}>
               <span className={classes.badge} style={{backgroundColor: status == 'already' ? '#67bad3' : '#fbd707'}}>
                   {title}
               </span>
                {bold === true ?
                    <div className={classes.descriptionBold}>
                        {description}
                    </div>
                    :
                    <div style={{marginTop: 15}}>
                        {listItems && listItems.length ?
                            <>
                                {renderListItems(listItems)}
                            </>
                        :
                            <div className={classes.description}>
                                {description}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );

}

export default (withStyles(style)(BadgeDashboardComponent));