
export const getRisks = () => {
    const risks = localStorage.getItem('risks');

    if (risks) {
        return risks;
    }

    const defaultRisks = {
        risks: {
            asthma: false,
            copd: false,
            diabetes: false,
            pill: false,
            surgery: false,
            heartdesease: false,
            breathless: false,
            psycho: false,
            tumor: false,
            bloodpressure: false,
            furtile: false,
            teeth: false,
            other: false,
        },
        hurdles: {
            like: false,
            stress: false,
            used: false,
            noreason: false,
            nowant: false,
            nobreak: false,
            nogainweight: false,
            smokeflat: false,
            helps: false,
            noother: false,
            fear: false,
            nosucceed: false,
            other2: false,
        },
        motives: {
            savemoney: false,
            free: false,
            health: false,
            body: false,
            look: false,
            fitness: false,
            pregant: false,
            social: false,
            values: false,
        },
    };

    localStorage.setItem('risks', defaultRisks);
    return defaultRisks;
};

export const getRisksOrHurdles = (data) => {
    return Object.keys(data).filter(key => data[key] === true);
};

export const getRisksText = (risksText, riskType) => {
    if (!risksText || risksText.length === 0) {
        return null;
    }
    for (let i = 0; i < risksText.length; i++) {
        if (risksText[i].type === riskType) {
            return risksText[i].text;
        }
    }
    return null;
};

export const getRisksTitle = (riskType) => {
    switch (riskType) {
        case 'asthma':
            return 'Asthma bronchiale';
            break;
        case 'copd':
            return 'COPD';
            break;
        case 'diabetes':
            return 'Diabetes';
            break;
        case 'pille':
            return 'Einnahme Pille';
            break;
        case 'surgery':
            return 'Geplante Operation';
            break;
        case 'heartdesease':
            return 'Herz-Kreislauferkrankung';
            break;
        case 'breathless':
            return 'Husten und Atemnot';
            break;
        case 'psycho':
            return 'Psychische Erkrankung';
            break;
        case 'tumor':
            return 'Tumorerkrankung';
            break;
        case 'bloodpressure':
            return 'Bluthochdruck';
            break;
        case 'furtile':
            return 'Fruchtbarkeit';
            break;
        case 'teeth':
            return 'Mund und Zähne';
            break;
        case 'other':
            return 'Sonstige Erkrankung';
            break;

        default:
            return 'Unbekannt';
    }
};