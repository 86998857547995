import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    deleteFileRequest, getFileRequest,
    uploadFileRequest
} from "../Actions/fileActions";
import { IconButton, Grid, Button, Dialog, DialogContent, DialogTitle, DialogActions, MenuItem} from "@material-ui/core"
import PrimaryButton from "./PrimaryButton";

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.disableImage = this.disableImage.bind(this);
    }

    showOpenFileDlg = () => {
        this.inputRef.current.click();
    };

    disableImage(id) {
        //this.changeImageStatus(id, 'disable');
    }

    handleFileUpload = (fileList) => {
       if(fileList && fileList.length){
           console.log(fileList)
           this.props.uploadFile({file: fileList[0]});
       }
    };

    getFile(item) {
        this.props.downloadFile({ id:item._id, download: true});
    }

    render() {
        const { } = this.props;
        return (
            <div>
                <input type="file" style={{ display: "none" }} ref={this.inputRef} onChange={(e)=>this.handleFileUpload(e.target.files)}/>
                <PrimaryButton onClick={()=>this.showOpenFileDlg()} label={'Bild auswählen'} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = {
    uploadFile: uploadFileRequest,
    downloadFile: getFileRequest,
    deleteFile: deleteFileRequest,

};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),

)(FileUpload)