import React from "react";



const style = theme => {
    return {
        dialogPaper:{
            height:'60%',
            minHeight:'60%'
        },
        stepImage: {
            maxWidth: '25%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 20,
            display: 'block',
        },
        stepTitle: {
            fontWeight: 'bold',
            textAlign: 'center'
        },
        stepText: {
            textAlign: 'left'
        },
        bottom: {
            width: '100%',
            textAlign: 'center',
            height: 120
        },
        header: {
            textAlign: 'center'
        },
        dialogHeaderTitle: {
            flex: 1,
            textAlign: 'center'
        },
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        content: {
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
            width: 500,
            height: 450,
        },
        dotContainer: {
            marginBottom: 10
        },
        dot: {
            height: 10,
            width: 10,
            backgroundColor: '#bbb',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: 5,
            marginRight: 5
        },
        dotFilled: {
            backgroundColor: '#ffd606',
        }
    }

};

export default style;