import React from 'react';
import {Switch, FormControlLabel, withStyles, Grid} from "@material-ui/core";
import { green, grey } from '@material-ui/core/colors';

const SwitchGroup = ({ input, disabled, leftLabel, rightLabel, classes, OnChange, labelStyle }) => {
    let _labelStyle = { marginRight: 20 };
    if(labelStyle){
        _labelStyle = {
            ..._labelStyle,
            ...labelStyle
        }
    }
    return (
        <div>
            <span style={_labelStyle}>{leftLabel}</span>
            <FormControlLabel
                control={
                    <Switch
                        {...input}
                        checked={input.value}
                        onClick={() => input.onChange(!input.value)}
                        disabled={disabled}
                        classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        onChange={OnChange}
                    />
                }
            />
            <span style={_labelStyle}>{rightLabel}</span>
        </div>

    )
}

export default withStyles({
    switchBase: {
        color: grey[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(SwitchGroup);