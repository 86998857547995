import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        listItem: {
            fontWeight: 'bold',
            textAlign: 'left'
        },
        listSection: {
            textAlign: 'center',
            marginBottom: 20
        }
    }

};

export default style;