import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./ScaleStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, MenuItem } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {storeRequest, unsetAddDialog} from "../../../Actions/userActions";
import SelectGroup from "../../../Components/SelectGroup";
import { Close as CloseIcon } from '@material-ui/icons';
import PrimaryButton from "../../../Components/PrimaryButton";
import globalStyle from "../../../themes/GlobalStyles";

const required = (value) => value ? 0 : 'Auswahl erforderlich';

const renderOptions = (options) => {
    return options.map( option => renderOption(option))
};

const renderOption = (option) => {
    return <MenuItem value={option.value}>{option.label}</MenuItem>
};

const ScaleDialog = (props) => {

    const submit = (values) => {

        if(props.isPro && !props.isContra){
            const pros = props.balancePros;
            pros.push({text: values.balancePros});
            props.store({key: 'balancePros', values: pros});
            props.unsetAddDialog();
        } else if(!props.isPro && props.isContra){
            const contras = props.balanceContras;
            contras.push({text: values.balanceContras});
            props.store({key: 'balanceContras', values: contras});
            props.unsetAddDialog();
        }
    };

    return (

        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={true}>
            <DialogTitle>
                <div className={props.classes.dialogTitle}>
                    <h1>{props.isPro && 'Pro'} {props.isContra && 'Contra'} hinzufügen</h1>
                </div>
                <div className={props.classes.closeContainer}>
                    <IconButton onClick={() => props.unsetAddDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.dialogContent}>
                        {props.isPro &&
                            <Field
                                name={'balancePros'}
                                component={SelectGroup}
                                fullWidth
                                validate={required}
                            >
                                {renderOptions(props.pro)}
                            </Field>
                        }

                        {props.isContra &&
                            <Field
                                name={'balanceContras'}
                                component={SelectGroup}
                                fullWidth
                                validate={required}
                            >
                                {renderOptions(props.contra)}
                            </Field>
                            }

                        <div style={{height:50}}></div>

                        <div className={props.classes.submitButton}>
                            <PrimaryButton
                                label={'Speichern'}
                                onClick={props.handleSubmit(submit)}
                            />
                        </div>
                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        isPro: state.user.pro,
        isContra: state.user.contra,
        balancePros: state.user.data && state.user.data.balancePros ? state.user.data.balancePros:[],
        balanceContras: state.user.data && state.user.data.balanceContras ? state.user.data.balanceContras:[],
        initialValues:{
            balancePros: ' ',
            balanceContras: ' '
        }
    }
}

const mapDispatchToProps = {
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'scale-form',
        enableReinitialize: true,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(ScaleDialog);