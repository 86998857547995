import React, { useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Radio, RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import { Close as CloseIcon } from '@material-ui/icons';
import {unsetDashboardSettings} from "../../../Actions/dashboardActions";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {storeRequest} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import { dashboardViewAlready, dashboardViewMaybe} from "../../../Services/dashboard";
import globalStyle from "../../../themes/GlobalStyles";

const renderSettings = (props, item, title) =>{
    return(
        <div className={props.classes.settingsItem}>
            <Field
                name={item.key}
                component={CheckboxGroup}
                label={title}
            />
        </div>
    )
};

const DashboardSettings = (props) => {

    const { handleSubmit } = props;

    const submit = (values) => {
        let result = props.dashboard.map(entry => {
            entry['visible'] = values[entry.key];
            return entry;
        });

        console.log(result);

        props.store({key: props.status === 'already' ? 'dashboard-already' : 'dashboard-yes', values: result})
        props.unsetDashboardSettings();
    };

    return (
        <Dialog maxWidth={"lg"} classes={{ paper: props.classes.dialogPaper }} open={props.settings}>
            <DialogTitle>
                <div className={props.classes.settingsTitle}>
                    <h1 style={{fontFamily: 'Fedra', paddingTop: 20, marginBottom: 0}}>Dashboard Einstellungen</h1>
                    <div className={props.classes.closeContainer}>
                        <IconButton onClick={() => props.unsetDashboardSettings()}>
                            <CloseIcon style={{fontSize: 32}}/>
                        </IconButton>
                    </div>


                </div>
            </DialogTitle>

            <DialogContent>
                    <form>
                        <div className={props.classes.settingsSection}>
                            {props.dashboard.map(item => {
                                if(item.key !== 'smokeNow' && item.key !== 'statistic' && item.key !== 'checklist' && item.key !== 'stopDate' && item.key !== 'cigarettesNotSmoked' && item.key !== 'eurosSaved' && item.key !== 'achievements'){
                                    return renderSettings(props, item, item.settingsTitle);
                                }
                            })}
                        </div>

                        <div style={{textAlign: 'center', marginBottom: 20, marginTop: 20}}>
                            <PrimaryButton
                                onClick={handleSubmit(submit)}
                                label={'Speichern'}
                            />
                        </div>

                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {

    const dashboardAlready = state.user.data && state.user.data['dashboard-already'] ? state.user.data['dashboard-already'] : [];
    const dashboardMaybe = state.user.data && state.user.data['dashboard-yes'] ? state.user.data['dashboard-yes'] : [];
    const status = state.user && state.user.data && state.user.data['app-settings'] && state.user.data['app-settings'].status ? state.user.data['app-settings'].status : null;
    const dashboard = (status === 'already' ? dashboardAlready : dashboardMaybe);

    if(dashboard && dashboard.length > 0){
        dashboard.forEach(d => {
            const found = (status === 'already' ? dashboardViewAlready : dashboardViewMaybe).find(entry => entry.key === d.key);
            if(found){
                d.settingsTitle = found.settingsTitle;
            }
        });
    }

    const initialValues = {};

    dashboard.forEach(entry => {
        initialValues[entry.key] = !!entry.visible
    });

    return{
        settings: state.dashboard.settings,
        dashboard: dashboard,
        status: status,
        initialValues: initialValues
    }
}

const mapDispatchToProps = {
    unsetDashboardSettings: unsetDashboardSettings,
    store: storeRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'dashboard-settings-form',
        enableReinitialize: false,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(DashboardSettings);