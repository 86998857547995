import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { Button, MenuItem } from "@material-ui/core";
import {Field, getFormValues} from "redux-form";
import SelectGroup from "../../../Components/SelectGroup";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import SwitchGroup from "../../../Components/SwitchGroup";
import PrimaryButton from "../../../Components/PrimaryButton";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {compose} from "redux";
import {connect} from "react-redux";

const OnBoardingCigarettesPrice = ({classes, nextStep, medianPrice, priceType}) => {

    return (
        <div className={classes.container}>
            <h1 className={classes.dialogTitle}>Kosten</h1>
            <div className={classes.content}>
                <div className={classes.inputContainer}>
                    <div className={classes.inputLabel} style={{marginTop: 0, marginBottom: 20}}>Zigarettenpreis in EUR</div>
                    <div>
                        <Field
                            name='priceType'
                            component={SwitchGroup}
                            fullWidth
                            leftLabel={'pro Schachtel'}
                            rightLabel={'pro Zigarette'}
                        />
                    </div>
                    <div>
                        <Field
                            name='medianPrice'
                            type={'number'}
                            component={TextFieldGroup}
                            containerStyle={{textAlign:'center', maxWidth: 200}}
                            inputStyle={{textAlign:'center', fontSize: 20}}
                            style={{alignItems: 'center'}}
                        />
                    </div>
                    <div className={classes.inputLabel}>
                        <Field
                            name='firstTime'
                            component={CheckboxGroup}
                            fullWidth
                            label={'Erster Rauchstopp'}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    disabled={!(medianPrice)}
                    onClick={nextStep}
                    label={'Weiter'}
                />
            </div>

        </div>
    )
};

export default compose(
    connect(null, null),
    withStyles(style)
)(OnBoardingCigarettesPrice);