import React, { useEffect } from 'react';
import {connect} from "react-redux";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import defaultTheme from '../../../themes/defaultTheme'
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HelpOutline from '@material-ui/icons/HelpOutline';
import MoreVert from '@material-ui/icons/MoreVert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import DrawerItems from "./DrawerItems";
import AppContainer from "../Container/AppContainer";
import {setDashboardInfo, setDashboardSettings, unsetDashboardInfo} from "../../../Actions/dashboardActions";
import logo from '../../../Assets/logo-rauchfrei-web-1.png';
import logo1 from '../../../Assets/rauchfrei-telefon-logo-sprechblase@2x.png';
import nav from './_nav'

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        color: '#000',
        marginRight: theme.spacing(2),
    },
    helpButton: {
        color: '#000',
        position: 'absolute',
        right: '50px'
    },
    moreButton: {
        color: '#000',
        position: 'absolute',
        right: '10px',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '2px 0 10px -1px rgba(0,0,0,0.1)'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: 80,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(6),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        background: '#F5F2E4'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const PersistentDrawerLeft = ({dashboardInfo, setDashboardInfo, unsetDashboardInfo, setDashboardSettings, ownProps, appSettings}) => {
    const classes = useStyles();
    const theme = defaultTheme;
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleHelp = () => {
        if(dashboardInfo){
            unsetDashboardInfo();
        }else {
            setDashboardInfo();
        }
    };

    const showDashboardIcons = () => {
        return ownProps.location.pathname === '/intern/dashboard'
    };

    const getBreadcrumb = () => {
        const path = ownProps.location.pathname;

        let main = {...nav.items.find(i => path.startsWith(i.url))};

        if(main && main.name === 'Dashboard'){
            if(appSettings && appSettings.statusNumeric === 5){
                main.name = 'Rauchfrei';
            } else {
                main.name = 'Vorbereitung';
            }
        }

        if(path.endsWith('fitness_list') || path.endsWith('achievements') || path.endsWith('statistics')){
            main = {
                name: 'Dashboard',
                url: '/intern/dashboard'
            };
        }

        if(main){
            let sub = null;

            if(main.url !== path){
                sub = (() => {
                    if(path.endsWith('tips/wait')){
                        return 'Abwarten';
                    } else if(path.endsWith('tips/distract')){
                        return 'Ablenken';
                    } else if(path.endsWith('tips/skip')){
                        return 'Abhauen';
                    } else if(path.endsWith('tips/strategy')){
                        return 'Beliebte Strategien';
                    } else if(path.endsWith('tips/trap')){
                        return 'Achtung Falle';
                    } else if(path.endsWith('facts/addiction')){
                        return 'Abhängigkeit';
                    } else if(path.endsWith('facts/healthiness')){
                        return 'Gesundheit';
                    } else if(path.endsWith('facts/tobacco')){
                        return 'Tabak und Nikotin';
                    } else if(path.endsWith('facts/secondhand_smoke')){
                        return 'Passivrauch';
                    } else if(path.endsWith('facts/shisha')){
                        return 'E-Zigarette, Shisha und Co';
                    } else if(path.endsWith('facts/medical_support')){
                        return 'Medikamentöse Unterstützung';
                    } else if(path.endsWith('facts/pregnant')){
                        return 'Schwangerschaft und Stillzeit';
                    } else if(path.endsWith('facts/faq')){
                        return 'FAQ';
                    } else if(path.endsWith('facts/risks')){
                        return 'Meine Risiken';
                    } else if(path.endsWith('facts/hurdles')){
                        return 'Meine Stolpersteine';
                    } else if(path.endsWith('first_days/detail')){
                        return 'Details';
                    } else if(path.endsWith('attacke/hilfe')){
                        return 'Hilfe Rauchverlangen';
                    } else if(path.endsWith('attacke/ausrutscher')){
                        return 'Ausrutscher';
                    } else if(path.endsWith('fitness_list')){
                        return 'Fitness-Tip Liste';
                    } else if(path.endsWith('achievements')){
                        return 'Achievements';
                    } else if(path.endsWith('statistics')){
                        return 'Statistik';
                    }
                    else {
                        return ''
                    }
                })();
            }

            if(ownProps.location.state && ownProps.location.state.fromDashboard){
                main = nav.items.find(i => (i.name === 'Dashboard' || i.name === 'Rauchfrei' || i.name === 'Vorbereitung'));
                let x = nav.items.find(i => path.startsWith(i.url));
                if(x){
                    sub = x.name;
                }
            }

            let styleMain = (() => {
                if(!!sub){
                    return {
                        cursor: 'pointer',
                        fontSize: 16,
                        color: 'black',
                        fontWeight: 'normal',
                        textDecoration: 'none'
                    }
                } else {
                    return {
                        cursor: 'pointer',
                        fontSize: 18,
                        color: 'black',
                        fontWeight: 'bold',
                        textDecoration: 'none'
                    }
                }
            })();

            let styleSub = (() => {
                return {
                    fontSize: 18,
                    color: 'black',
                    fontWeight: 'bold',
                    textDecoration: 'none'
                }
            })();

            return <div style={{textAlign: 'center', width: '100%', color: '#000', fontWeight: 'bold'}}>
                <span><span style={styleMain} onClick={() => ownProps.history.push(main.url)}>{main.name}</span> {sub && (<span><span> > </span><span style={styleSub}>{sub}</span></span>)}</span>
            </div>
        }
    };

    return (
        <div className={classes.root}>
            <div style={{position: 'absolute', top: -30, left: 120, zIndex: 20}}>
                <img style={{width: '250px', height: 'auto'}} src={logo1} />
            </div>
            {!ownProps.location.pathname.startsWith('/get_started')  ?
                <>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                        style={{zIndex: 1, backgroundColor: (appSettings && appSettings.statusNumeric === 5) ? '#67bad3' : '#fbd707'}}
                    >
                        <Toolbar style={{
                            position: 'relative'
                        }}>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>

                            {!!appSettings && getBreadcrumb()}

                            { showDashboardIcons() && (

                                <>
                                {dashboardInfo ?
                                    <IconButton
                                        color="inherit"
                                        aria-label="help"
                                        onClick={handleHelp}
                                        className={classes.helpButton}
                                    >
                                        <CloseIcon style={{ fontSize: 35 }}/>
                                    </IconButton>
                                :
                                    <IconButton
                                        color="inherit"
                                        aria-label="help"
                                        onClick={handleHelp}
                                        className={classes.helpButton}
                                    >
                                        <HelpOutline style={{ fontSize: 35 }}/>
                                    </IconButton>
                                }

                                <IconButton
                                    color="inherit"
                                    aria-label="more"
                                    onClick={() => setDashboardSettings()}
                                    className={classes.moreButton}
                                >
                                    <MoreVert fontSize={'large'}/>
                                </IconButton>
                                </>)
                            }
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <img src={logo} alt="" style={{height: 150, position: 'absolute', left: 0, top: -10}}/>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <DrawerItems/>
                    </Drawer>
                    <main className={clsx(classes.content, {[classes.contentShift]: open,})}>
                        <div className={classes.drawerHeader} />
                        <AppContainer/>
                    </main>
                </>
            :
                <main style={{width: '100%'}}>
                    <div className={classes.drawerHeader} />
                    <AppContainer/>
                </main>
            }
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return{
        dashboardInfo: state.dashboard.dashboardInfo,
        ownProps: ownProps,
        appSettings: state.user.data && state.user.data['app-settings'],
    }
}
const mapDispatchToProps = {
    setDashboardInfo: setDashboardInfo,
    unsetDashboardInfo: unsetDashboardInfo,
    setDashboardSettings: setDashboardSettings
};

export default  connect(mapStateToProps, mapDispatchToProps)(PersistentDrawerLeft);
