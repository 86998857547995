import {put, call, select} from "@redux-saga/core/effects";
import {loadInformationFeatureError, loadInformationFeatureSuccess} from "../Actions/informationFeatureActions";

export function* loadInformationFeatureRequest (api) {
    try {

        const result = yield call(api.loadInformationFeatures);
        console.log('step1')
        if (result && result.healthiness && result.motivation && result.fact && result.fitness ) {
            console.log('step2')
            console.log(result.healthiness)
            yield put(loadInformationFeatureSuccess({healthiness: result.healthiness.data.data, motivation: result.motivation.data, fact: result.fact.data, fitness: result.fitness.data}));
        } else {
            yield put(loadInformationFeatureError());
        }
    } catch (e) {
        console.log(e)
    }
}







