import AppStyles from "../../AppStyles.js";
import React from "react";

const drawerWidth = 240;
const headerHeight = '64px'

const style = theme => {
    return {
        ...AppStyles,
        root: {
            display: 'flex',
            backgroundColor: '#ffffff',
            paddingLeft: '10px',
            paddingRight: '10px',
            minHeight: '100vh',
            paddingTop: '30px'

        },

        paper: {
            color: theme.palette.text.secondary,
        },

        navIcon: {
            color: "#fbd707"
        },

        container: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    }

};

export default style;