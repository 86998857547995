import React, { useEffect }from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./HurdlesStyles";
import globalStyle from "../../../themes/GlobalStyles";
import {compose } from "redux";
import { Button } from "@material-ui/core";
import {Field, reduxForm } from "redux-form";
import {connect} from "react-redux";
import { hurdles } from "../../../utils/constants";
import CheckboxGroup from "../../../Components/CheckboxGroup";
import {storeRequest} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";

const renderHurdles = (props, hurdle) =>{
    return(
        <div className={props.classes.listItem}>
            <Field
                name={hurdle.value}
                component={CheckboxGroup}
                label={hurdle.label}
                defaultChecked={props.initialValues[hurdle.value]}
            />
        </div>
    )
};

const Hurdles = (props) => {
    const submit = (values) => {

        const result = {
            hurdles: {
                like: values.like,
                stress: values.stress,
                used: values.used,
                noreason: values.noreason,
                nowant: values.nowant,
                nobreak: values.nobreak,
                nogainweight: values.nogainweight,
                smokeflat: values.smokeflat,
                helps: values.helps,
                fear: values.fear,
                nosucceed: values.nosucceed,
                noother: values.noother,

    },
            risks: props.risks,
            motives: props.motives
        }

        props.store({key: 'risks', values: result});
    };

    const submitAndBack = (values) => {
        submit(values);
        props.history.push('/intern/dashboard');
    };

    return (
        <div className={props.classes.container}>
            <div className={props.classes.title}>
                <h1>Meine Stolpersteine</h1>
            </div>
            <div className={props.classes.content}>
                <form>

                    {hurdles &&
                        <div className={props.classes.listSection}>
                            {hurdles.map(hurdle=>{
                                return(
                                    renderHurdles(props, hurdle)
                                )
                            })}
                        </div>
                    }
                </form>
            </div>
            <div className={props.classes.submitButton}>
                <PrimaryButton
                    label={'Speichern'}
                    onClick={props.handleSubmit(submit)}
                />
                <br/>
                <PrimaryButton
                    style={{marginTop: 10, marginBottom: 20}}
                    label={'Speichern und zurück'}
                    onClick={props.handleSubmit(submitAndBack)}
                />
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return{
        risks: state.user.data && state.user.data.risks && state.user.data.risks.risks ? state.user.data.risks.risks : {},
        motives: state.user.data && state.user.data.risks && state.user.data.risks.motives ? state.user.data.risks.motives : {},

        initialValues: {
            like: state.user.data && state.user.data.risks && state.user.data.risks.risks.hurdles && state.user.data.risks.hurdles.like ? state.user.data.risks.hurdles.like : false,
            stress: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.stress ? state.user.data.risks.hurdles.stress : false,
            used: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.used ? state.user.data.risks.hurdles.used : false,
            noreason: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.noreason ? state.user.data.risks.hurdles.noreason : false,
            nowant: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.nowant ? state.user.data.risks.hurdles.nowant : false,
            nobreak: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.nobreak ? state.user.data.risks.hurdles.nobreak : false,
            nogainweight: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.nogainweight ? state.user.data.risks.hurdles.nogainweight : false,
            smokeflat: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.smokeflat ? state.user.data.risks.hurdles.smokeflat : false,
            helps: state.user.data && state.user.data.risks && state.user.data.hurdles && state.user.data.risks.hurdles.helps ? state.user.data.risks.hurdles.helps : false,
            fear: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.fear ? state.user.data.risks.hurdles.fear : false,
            nosucceed: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.nosucceed ? state.user.data.risks.hurdles.nosucceed : false,
            noother: state.user.data && state.user.data.risks && state.user.data.risks.hurdles && state.user.data.risks.hurdles.noother ? state.user.data.risks.hurdles.noother : false,
        }
    }
}

const mapDispatchToProps = {
    store: storeRequest
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'hurdles-form',
        enableReinitialize: true,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(Hurdles);