import React, { useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./RewardStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import {connect} from "react-redux";
import { Close as CloseIcon } from '@material-ui/icons';
import {storeRequest, unsetActiveReward, unsetAddDialog} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";

const required = (value) => value ? 0 : 'Auswahl erforderlich';

const RewardDialog = (props) => {
    useEffect(() => {
        return () => {
           props.unsetActiveReward();
        }
    }, []);

    const getReward = (element) => element.id === props.activeReward.id;

    const submit = (values) => {
        const reward = props.rewards;

        if(props.editable){
            const index = reward.findIndex(getReward);

            reward[index] = {
                id: reward[index].id,
                text: values.text,
                value: values.value
            };
        } else {
            reward.push(values)
        }

        props.store({key: 'reward', values: reward});
        props.unsetAddDialog();
    };

    const deleteReward = () => {
        const newRewards = [];

        props.rewards.forEach(reward =>{
            if(reward.id !== props.activeReward.id){
                newRewards.push(reward)
            }
        });
        props.store({key: 'reward', values: newRewards});
        props.unsetAddDialog();
    };

    return (
        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={true}>
            <DialogTitle>
                <div className={props.classes.dialogTitle}>
                    <span>Belohnung {props.editable?'bearbeiten':'hinzufügen'}</span>
                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => props.unsetAddDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.dialogContent}>
                        <Field
                            name={'text'}
                            component={TextFieldGroup}
                            fullWidth
                            label={'Meine Belohnung'}
                            placeholder={'Meine Belohnung...'}
                            validate={required}
                        />

                        <Field
                            name={'value'}
                            type={'number'}
                            component={TextFieldGroup}
                            fullWidth
                            label={'Preis'}
                            placeholder={'Preis'}
                            validate={required}
                        />

                        <div className={props.classes.submitButton}>
                            <PrimaryButton
                                style={{marginBottom: 20}}
                                color="primary"
                                label={'Speichern'}
                                onClick={props.handleSubmit(submit)}
                            />

                            {props.editable &&
                                <div>
                                    <SimpleButton
                                        style={{width: '100%', marginBottom: 20}}
                                        variant="text"
                                        color="primary"
                                        onClick={deleteReward}
                                        label={'Löschen'}
                                    />
                                </div>
                            }
                        </div>
                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        rewards: state.user.data && state.user.data.reward ? state.user.data.reward : [],
        editable: state.user.editable,
        activeReward: state.user.activeReward,
        initialValues: {
            text: state.user.activeReward&&state.user.activeReward.text?state.user.activeReward.text:'',
            value: state.user.activeReward&&state.user.activeReward.value?state.user.activeReward.value:'',
        }
    }
}

const mapDispatchToProps = {
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
    unsetActiveReward: unsetActiveReward
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'reward-form',
        enableReinitialize: false,
    }),
    withStyles(style)
)(RewardDialog);