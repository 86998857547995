import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";

const Distract = (props) => {

    const { classes } = props;

    return (
        <Fragment>
            <div className={classes.content}>
                <h3>Lenke dich ab und konzentriere dich bewusst auf etwas Anderes!</h3>

                <div className={classes.listSection}>
                    <ul>
                        <li className={classes.listIcon}><span className={classes.listText}>Ruf eine Freundin oder einen Freund an, um zu plaudern</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Wasch ab, kehr auf oder mach etwas im Haushalt, es gibt ja immer was zu tun</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Löse ein Sudoku oder spiel dein Lieblingsspiel am Handy</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Kannst du häkeln, nähen oder stricken, malen oder sticken?</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Lies ein spannendes Buch oder eine neue Zeitschrift</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Schau, was diese App sonst noch kann</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Mach Sport oder geh spazieren</span></li>

                    </ul>
                </div>
            </div>


        </Fragment>
    );

}

export default (withStyles(style)(Distract));
