
/* Action types */
const PREFIX = "dashboard/";

export const LOAD_DASHBOARD_INFO_REQUEST = PREFIX + "LOAD_DASHBOARD_INFO_REQUEST";
export const LOAD_DASHBOARD_INFO_SUCCESS = PREFIX + "LOAD_DASHBOARD_INFO_SUCCESS";
export const LOAD_DASHBOARD_INFO_ERROR = PREFIX + "LOAD_DASHBOARD_INFO_ERROR";

export const SET_DASHBOARD_INFO = PREFIX + "SET_DASHBOARD_INFO";
export const UNSET_DASHBOARD_INFO = PREFIX + "UNSET_DASHBOARD_INFO";

export const SET_DASHBOARD_SETTINGS = PREFIX + "SET_DASHBOARD_SETTINGS";
export const UNSET_DASHBOARD_SETTINGS = PREFIX + "UNSET_DASHBOARD_SETTINGS";




/* initial state */
const initialState = {
    dashboardInfo: false,
    dashboardInfoData: [],
    isFetching: false,
    error: false,
    settings: false
};

/* Reducer */
const dashboardReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_DASHBOARD_INFO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_DASHBOARD_INFO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dashboardInfoData: action.data
            };
        case LOAD_DASHBOARD_INFO_ERROR:
            return {
                ...state,
                error: true
            };
        case SET_DASHBOARD_INFO:
            return {
                ...state,
                dashboardInfo: true
            };
        case UNSET_DASHBOARD_INFO:
            return {
                ...state,
                dashboardInfo: false
            };
        case SET_DASHBOARD_SETTINGS:
            return {
                ...state,
                settings: true
            };
        case UNSET_DASHBOARD_SETTINGS:
            return {
                ...state,
                settings: false
            };

        default:
            return state;
    }
};
export default dashboardReducer;