import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import BasicDashboardComponent from "../BasicDashboardComponent/BasicDashboardComponent";
import BadgeDashboardComponent from "../BadgeDashboardComponent/BadgeDashboardComponent";
import { Grid } from '@material-ui/core';
import DashboardSpeedDials from './DashboardSpeedDials';
import  { checklist, risks as risksConstants, hurdles as hurdlesConstants } from '../../../utils/constants';
import checklistIcon from '../../../Assets/checkliste@2x.png';
import calendar from '../../../Assets/rauchstoppdatum@2x.png'
import btnPlus from '../../../Assets/v2/smoke_now_icon.png';
import {showInformationFeatureModal} from "../../../Actions/informationFeatureActions";
import InformationFeaturesModal from "./InformationFeaturesModal";
import moment from "moment";
import SmokeNow from "./SmokeNow";
import {setSmokeNow} from "../../../Actions/userActions";
import { change } from 'redux-form';
import MotivDashboardComponent from "../MotivDashboardComponent/MotivDashboardComponent";
import logo from '../../../Assets/rauchfrei-telefon-logo-sprechblase@2x.png';

export function getDayCount (rauchprotokoll) {
    let dayCount = [];
    let dateMatch = [];
    let dateString = '';
    for (let i = 0; i < rauchprotokoll.length; i++) {
        dateString = new Date(rauchprotokoll[i].date).toDateString();
        if (dateMatch.indexOf(dateString) == -1) {
            dateMatch.push(dateString);
            dayCount[dateMatch.indexOf(dateString)] = 1;
        } else {
            dayCount[dateMatch.indexOf(dateString)]++;
        }
    }
    return dayCount;
};

class DashboardYes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstDaysProgressText: false,
            progress: false
        }
    }

    componentDidMount(){}

    getProgressFromChecklist = () => {
        let checklist  = this.props.checklist;
        let progress = 0;

        progress += checklist.reduce((result, { value, progress }) => {
            if (value === true) {
                return result + progress;
            }

            return result;
        }, 0);

        return progress;
    };

    getTotalProgress = () => {
        let dayCount = getDayCount(this.props.rauchprotokoll).length;
        // number of stored days x 10%, max 30%
        let progress = Math.min(30, dayCount * 10);

        let customNotifications = [];
        let notifications = this.props.notifications;
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i].custom) {
                notifications[i].index = i;
                customNotifications.push(notifications[i]);
            }
        }
        progress += customNotifications.slice(0, 3).length * 10;
        // check list
        progress += this.getProgressFromChecklist();

        return progress;
    };


    getFirstDaysProgressText = () => {
        const progress = this.getTotalProgress();
        let firstDaysText = false;
        if(progress){
            if(progress > 0 && progress < 20){
                firstDaysText = "Super, du hast mit der Vorbereitung begonnen! Mach weiter.";
            } else if(progress > 19 && progress < 40){
                firstDaysText = "Du bist gut unterwegs. Bleib dabei!";
            } else if(progress > 39 && progress < 60){
                firstDaysText = "Fühlst du dich schon gut vorbereitet? Schau noch mal alles durch!";
            } else if(progress > 59 && progress < 80){
                firstDaysText = "Startklar für den Rauchstopp?!!";
            } else if(progress > 79){
                firstDaysText = "Optimal! Es kann losgehen!";
            } else {
                firstDaysText = "Noch nicht begonnen!";
            }
        }
        return firstDaysText;
    };

    getBestType = () => {
        let rauchprotokoll = this.props.rauchprotokoll;
        let typeCounts = [];
        let typeMatch = [];
        let bestType = '';
        let bestTypeNumber = 0;
        for (let i = 0; i < rauchprotokoll.length; i++) {
            if (typeMatch.indexOf(rauchprotokoll[i].type) == -1) {
                typeMatch.push(rauchprotokoll[i].type);
                typeCounts[typeMatch.indexOf(rauchprotokoll[i].type)] = 1;
                if (bestTypeNumber < 1) {
                    bestTypeNumber = 1;
                    bestType = rauchprotokoll[i].type;
                }
            } else {
                typeCounts[typeMatch.indexOf(rauchprotokoll[i].type)]++;
                if (
                    bestTypeNumber <
                    typeCounts[typeMatch.indexOf(rauchprotokoll[i].type)]
                ) {
                    bestTypeNumber =
                        typeCounts[
                            typeMatch.indexOf(rauchprotokoll[i].type)
                            ];
                    bestType = rauchprotokoll[i].type;
                }
            }
        }
        return bestType;
    };

    handleClick = (url, params) => {
        if(!this.props.dashboardInfo){
            if(params){
                this.props.ownProps.history.push(url, params)
            } else {
                this.props.ownProps.history.push(url)
            }
        }
    };

    render() {
        const { classes, dashboardInfo, dashboardInfoData, dashboardSettings, risks, hurdles } = this.props;

        let showingSettings = [];
        dashboardSettings.forEach(setting => {
            if(setting.visible){
                showingSettings.push(setting.key)
            }
        });

        let risksItems = [];
        (risks&&risks.length>0?risks:[]).forEach(risk => {
            if(risk[1]){
                risksConstants.forEach(constant => {
                    if(risk[0] === constant.value){
                        risksItems.push({text: constant.label})
                    }
                })

            }
        });

        let hurdlesItems = [];
        (hurdles&&hurdles.length>0?hurdles:[]).forEach(hurdle => {
            if(hurdle[1]){
                hurdlesConstants.forEach(constant => {
                    if(hurdle[0] === constant.value){
                        hurdlesItems.push({text: constant.label})
                    }
                })

            }
        });

        const type = this.getBestType();
        const statistics = (
                <div className={classes.statisticsCard}>
                    <p>
                        <strong>{this.props.rauchprotokoll.length}{this.props.rauchprotokoll.length === 1  ? ' Zigarette' : ' Zigaretten'}</strong> habe ich in <strong>{getDayCount(this.props.rauchprotokoll).length}{getDayCount(this.props.rauchprotokoll).length === 1 ? ' Tag' : ' Tagen'}</strong> geraucht <br/>
                    </p>
                    <p>
                        Dein häufigster Grund: <br/>
                        <strong>{type ? type : 'Zu wenig Daten'}</strong>
                    </p>

                </div>
        );

        return(
            <div className={classes.dashboardContainer}>
                <div className={classes.shareSection}>
                   Mein Rauchprotokoll
                </div>
                <Grid container spacing={5} className={classes.componentContainer}>
                    <Grid item md={4} xs={12}>
                        <BadgeDashboardComponent
                            onClick={() => this.handleClick('/intern/statistics', {fromDashboard: true })}
                            name='statistic'
                            title={'Auswertung:'}
                            icon={<img src={checklistIcon} className={classes.dashboardImages}/>}
                            description={statistics}
                            paper={true}
                            dashboardInfo={dashboardInfo}
                            dashboardInfoData={dashboardInfoData}
                            showInfo={(data) => this.props.showModal({data: {...data.data}})}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <BasicDashboardComponent
                            name='smokeNow'
                            title={'Ich werde jetzt rauchen'}
                            icon={<img src={btnPlus} className={classes.dashboardImages} onClick={()=> this.props.setSmokeNow()}/>}
                            paper={false}
                            dashboardInfo={dashboardInfo}
                            dashboardInfoData={dashboardInfoData}
                            showInfo={(data) => this.props.showModal({data: {...data.data}})}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <BasicDashboardComponent
                            onClick={() => this.handleClick('/intern/checklist', {fromDashboard: true })}
                            name='checklist'
                            icon={<img src={checklistIcon} className={classes.dashboardImages}/>}
                            title={this.getProgressFromChecklist() + '%'}
                            description={'der Checkliste habe ich erledigt'}
                            paper={true}
                            dashboardInfo={dashboardInfo}
                            dashboardInfoData={dashboardInfoData}
                            showInfo={(data) => this.props.showModal({data: {...data.data}})}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <BasicDashboardComponent
                            onClick={() => this.handleClick('/intern/profil', {fromDashboard: true })}
                            name='stopDate'
                            title={this.props.appSettings && this.props.appSettings.stopDate!==null?'Ab ' + moment(this.props.appSettings.stopDate).format('DD.MM.'):'Du hast noch kein Rauchstopp Datum gewählt'}
                            icon={<img src={calendar} className={classes.dashboardImages}/>}
                            description={(this.props.appSettings && this.props.appSettings.stopDate!==null) &&'bin ich rauchfrei'}
                            paper={true}
                            dashboardInfo={dashboardInfo}
                            dashboardInfoData={dashboardInfoData}
                            showInfo={(data) => this.props.showModal({data: {...data.data}})}
                        />
                    </Grid>
                    {showingSettings.includes('firstDaysProgress') &&
                        <Grid item md={8} xs={12}>
                            <BasicDashboardComponent
                                name='firstDaysProgress'
                                paper={false}
                                icon={<div style={{
                                    width: '100%',
                                    height: '50px',
                                    backgroundColor: '#DCDCDC',
                                    position: 'relative',
                                    borderRadius: '5px'
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        width: this.getTotalProgress() + '%',
                                        backgroundColor: '#ffd705',
                                        height: '100%',
                                        borderRadius: '5px',
                                        zIndex: 1,
                                    }}></div>
                                    <div style={{
                                        width: '100%',
                                        lineHeight: '50px',
                                        fontSize: 24,
                                        textAlign: 'center',
                                        zIndex: 100,
                                        position: 'relative',
                                        padding: 5}}>{this.getTotalProgress()}%</div>
                                </div>}
                                description={<div style={{fontSize: 22, textAlign: 'center', marginTop: '15px'}}>deiner ersten rauchfreien Tage
                                    hast du schon geplant. <strong>{this.getFirstDaysProgressText()}</strong></div>}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('firstDays') &&
                        <Grid item md={4} xs={12}>
                            <BasicDashboardComponent
                                onClick={() => this.handleClick('/intern/first_days', {fromDashboard: true })}
                                name='firstDays'
                                paper={true} icon={<img src={calendar} className={classes.dashboardImages}/>}
                                description={'Erste rauchfrei Tage'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('motivwaage') &&
                        <Grid item md={8} xs={12}>
                            <MotivDashboardComponent
                                onClick={() => this.handleClick('/intern/scale', {fromDashboard: true })}
                                name='motivwaage'
                                paper={true}
                                title={'Meine Motivwaage:'}
                                pros={this.props.balancePros.length ? this.props.balancePros : []}
                                cons={this.props.balanceContras.length ? this.props.balanceContras : []}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('risiken') &&
                        <Grid item md={4} xs={12}>
                            <BadgeDashboardComponent
                                onClick={() => this.handleClick('/intern/risk', {fromDashboard: true })}
                                name='risiken'
                                paper={true}
                                title={'Meine Risiken:'}
                                listItems={risksItems.length ? risksItems : []}
                                description={'Du hast noch keine Risiken angelegt'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('stolpersteine') &&
                        <Grid item md={4} xs={12}>
                            <BadgeDashboardComponent
                                onClick={() => this.handleClick('/intern/hurdles', {fromDashboard: true })}
                                name='stolpersteine'
                                paper={true}
                                title={'Meine Stolpersteine:'}
                                listItems={hurdlesItems.length ? hurdlesItems : []}
                                description={'Du hast noch keine Stolpersteine angelegt'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('motive') &&
                        <Grid item md={4} xs={12}>
                            <BadgeDashboardComponent
                                onClick={() => this.handleClick('/intern/motives', {fromDashboard: true })}
                                name='motive'
                                paper={true}
                                title={'Meine Motive:'}
                                listItems={this.props.motive.length ? this.props.motive : []}
                                description={'Du hast noch keine Motive angelegt'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}
                            />
                        </Grid>
                    }
                </Grid>

                <DashboardSpeedDials ownProps={this.props.ownProps}/>

                {this.props.modal && this.props.modalData &&
                    <InformationFeaturesModal/>
                }

                {this.props.smokeNow &&
                    <SmokeNow changeImportance={this.props.changeImportance}/>
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        appSettings: state.user.data && state.user.data['app-settings'],
        profile: state.user.data && state.user.data['profile'],
        rauchprotokoll: state.user.data && state.user.data.rauchprotokoll ? state.user.data.rauchprotokoll :[],
        notifications: state.user.data && state.user.data.notifications ? state.user.data.notifications : [],
        balanceContras: state.user.data && state.user.data.balanceContras ? state.user.data.balanceContras : [],
        balancePros: state.user.data && state.user.data.balancePros ? state.user.data.balancePros : [],
        risks: state.user.data && state.user.data.risks && state.user.data.risks.risks ? Object.entries(state.user.data.risks.risks ):{},
        hurdles: state.user.data && state.user.data.risks && state.user.data.risks.hurdles ? Object.entries(state.user.data.risks.hurdles):{},
        motive: state.user.data && state.user.data.motive ? state.user.data.motive : [],
        checklist: state.user.data && state.user.data.checklist ? state.user.data.checklist : [],
        dashboardSettings: state.user.data && state.user.data['dashboard-yes'] ? state.user.data['dashboard-yes'] : [],

        dashboardInfo: state.dashboard.dashboardInfo,
        dashboardInfoData: state.dashboard.dashboardInfoData,

        modal: state.information.showModal,
        modalData: state.information.modalData,
        smokeNow: state.user.smokeNow,

    }
}
const mapDispatchToProps = {
    showModal: showInformationFeatureModal,
    setSmokeNow: setSmokeNow,
    changeImportance: change
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(DashboardYes));