import React from "react";

const style = theme => {
    return {
        container: {
            width: '100%',
            padding: 10,
        },
        title: {
            width: '100%',
            height: '20%',
            textAlign: 'center',
            fontWeight: 'bold',
        },
        description: {
            width: '100%',
            height: '50%',
            textAlign: 'center',
            color: '#FFF',
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: 5,
            padding: 5,
        },

        dashboardTiles: {
            position: 'relative',
            minHeight: 200
        },
        infoOverlay: {
            position: 'absolute',
            backgroundColor: '#000',
            opacity: 0.5,
            width: '100%',
            height: '100%',
            borderRadius: 8,
            flexDirection: 'column',
            display: 'flex'
        }
    }
};

export default style;