import {put, call, select} from "@redux-saga/core/effects";
import {loadDashboardInfoError, loadDashboardInfoSuccess} from "../Actions/dashboardActions";

export function* loadDashboardInfoRequest (api) {
    try {

        const result = yield call(api.loadDashboardInfo);

        if (result && result.data && result.data.data ) {
            yield put(loadDashboardInfoSuccess({data: result.data.data}));
        } else {
            yield put(loadDashboardInfoError());
        }
    } catch (e) {
        console.log(e)
    }
}







