import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./MotivStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, MenuItem } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import {connect} from "react-redux";
import {storeRequest, unsetAddDialog} from "../../../Actions/userActions";
import SelectGroup from "../../../Components/SelectGroup";
import { motive } from "../../../utils/constants"
import { Close as CloseIcon } from '@material-ui/icons';
import PrimaryButton from "../../../Components/PrimaryButton";



const required = (value) => value ? 0 : 'Auswahl erforderlich';

const renderOptions = (options) => {
    return options.map( option => renderOption(option))
};

const renderOption = (option) => {
    return <MenuItem value={option.value}>{option.label}</MenuItem>
};

const RewardDialog = (props) => {

    const submit = (values) => {
        const motive = props.oldMotive;
        motive.push({text: values.motiv});
        props.store({key: 'motive', values: motive});
        props.unsetAddDialog();
    };


    return (

        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={true}>
            <DialogTitle>
                <div className={props.classes.dialogTitle}>
                    <span>Motiv hinzufügen</span>
                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => props.unsetAddDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.dialogContent}>
                        <Field
                            name={'motiv'}
                            component={SelectGroup}
                            fullWidth
                            validate={required}
                        >
                            {renderOptions(motive)}
                        </Field>


                        <div className={props.classes.submitButton}>
                            <PrimaryButton
                                label={'Speichern'}
                                onClick={props.handleSubmit(submit)}
                            />
                        </div>

                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        oldMotive: state.user.data && state.user.data.motive ? state.user.data.motive:[],
        initialValues:{
          motiv: 0
        }
    }
}

const mapDispatchToProps = {
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'motiv-form',
        enableReinitialize: true,
    }),
    withStyles(style)
)(RewardDialog);