import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { Grid } from "@material-ui/core";
import PrimaryButton from "../../../Components/PrimaryButton";

const OnBoardingWelcome = ({classes, nextStep, login}) => {
    return (
        <div className={classes.container}>
            <h1 className={classes.welcomeTitle}> Herzlich Willkommen bei Rauchfrei! </h1>
            <div className={classes.content}>
                Schön, dass du dich für den Rauchstopp interessierst!
                Mit Unterstützung wird er leichter gelingen! Diese App und das Team des Rauchfrei Telefons möchten dich
                auf dem Weg in die Rauchfreiheit unterstützen.
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <PrimaryButton
                        onClick={nextStep}
                        label={'Neu hier'}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'left'}}>
                    <PrimaryButton
                        onClick={login}
                        label={'Einloggen'}
                    />
                </Grid>
            </Grid>

        </div>
    )
};

export default withStyles(style)(OnBoardingWelcome);
