import React from "react";

const style = theme => {
    return {
        input: {
            root: {
                background: "#000"
            },
            disabled: {

            }
        }
    };
};

export default style;