import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import DataTransfer from "../DataTransfer/DataTransfer";
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

class OnBoardingLogin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const boardingValues = this.props.location&&this.props.location.state&&this.props.location.state.boardingValues?this.props.location.state.boardingValues:false
        return (
            <div className={classes.dialog}>
                <DataTransfer onBoarding={true} boardingValues={boardingValues} history={this.props.ownProps.history} />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        stepIndex: state.wizzard.stepIndex,
    }
}
const mapDispatchToProps = {
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(withStyles(style)(OnBoardingLogin));