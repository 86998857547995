import {put, call, select} from "@redux-saga/core/effects";
import {
    createAccountFailed, createAccountSuccess, extendTokenFailed, extendTokenSuccess, forgotPasswordFailed,
    forgotPasswordSuccess,
    loginFailed, loginSuccess,
    logoutFailed,
    logoutSuccess,
    setPasswordFailed,
    setPasswordRequest, setPasswordSuccess, setResetPassword
} from "../Actions/authActions";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import {addFlashMessage} from "../Actions/flashMessageActions";
import {loadUserRequest, resetRedux, storeRequest} from "../Actions/userActions";
import {getRisks, getRisksOrHurdles} from "../Services/risks";
import {defaultAppSettings, defaultProfile} from "../Services/appSettings";

export function* login (api, user) {
    const result = yield call(api.login, user.email, user.password);
    if(result && result.data && result.data._id){

        localStorage.setItem('rfJwtToken', result.data.accessToken.token);
        localStorage.setItem('rfRefreshToken', result.data.refreshToken);
        localStorage.setItem('rfLoggedIn', result.data.email);

        setAuthorizationToken(result.data.accessToken.token);

        yield put(loginSuccess({ token: result.data.accessToken.token, refreshToken: result.data.refreshToken}));
        //yield put(loadUserRequest({loadFromApi: true}));

        if(user.boarding && user.boardingValues){
            yield put(storeRequest({key: 'profile', values: user.boardingValues}))
        }
        user.history.push("/intern/dashboard");
    } else{
        yield put(loginFailed());
        yield put(addFlashMessage({message: 'Überprüfen Sie Ihre Anmeldedaten', style:'error'}))
    }
}

export function* createAccount (api, user) {
    const result = yield call(api.createAccount);

    if(result && result.data && result.data.accessToken){
        localStorage.setItem('rfJwtToken', result.data.accessToken.token);
        localStorage.setItem('rfRefreshToken', result.data.refreshToken);

        setAuthorizationToken(result.data.accessToken.token)

        yield put(createAccountSuccess({token: result.data.accessToken.token, refreshToken: result.data.refreshToken}))

        if(user.boarding && user.boardingValues){

            const appSettings = createAppSettings(user.boardingValues);

            if(appSettings.statusNumeric == 5){
                appSettings.status = 'already';
            }

            const profile = createProfile(defaultProfile, appSettings, user.boardingValues);

            appSettings['profile'] = profile;

            yield put(storeRequest({key: 'app-settings', values: appSettings}));
            yield put(storeRequest({key: 'profile', values: profile}));
            yield put(loadUserRequest({loadFromApi: true}));
        }

        if(user && user.email && user.password){
            yield put(setPasswordRequest({email: user.email, password: user.password, history: user.history, boarding: user.boarding}))
        } else {
            if(user.boarding){
                user.history.push("/intern/wizzard");
            } else{
                user.history.push("/intern");
            }

        }
    } else{
        yield put(createAccountFailed());
    }
}

export function* setPassword (api, user) {
    const result = yield call(api.setPassword, user.email, user.password);
    if(result && result.data && result.data.success){
        yield put(addFlashMessage({message: 'Sie haben erfolgreich Ihr Konto erstellt', style:'success'}));

        localStorage.setItem('rfLoggedIn', result.data.email);

        yield put(setPasswordSuccess());
        if(user.boarding){
            user.history.push("/intern/wizzard");
        } else{
            user.history.push("/intern/dashboard");

        }
    } else{
        yield put(setPasswordFailed());
        yield put(addFlashMessage({message: 'Benutzer existiert bereits', style:'error'}))
    }
}

export function* forgotPassword (api, email) {
    const result = yield call(api.forgotPassword, email.email);
    if(result && result.data && result.data.success){
        yield put(forgotPasswordSuccess());
        yield put(setResetPassword());
    } else{
        yield put(forgotPasswordFailed());
        yield put(addFlashMessage({message: 'Bitte überprüfen Sie Ihre Email-Adresse', style:'error'}))
    }
}

export function* logout (route) {
    const result = localStorage.clear();
    route.ownProps.history.push("/get_started");

    if(!result){
        yield put(logoutSuccess())
        yield put(resetRedux())
    } else{
        yield put(logoutFailed())
    }
}

function createProfile(profile, appSettings, boardingValues){

    const { risks, hurdles } = getRisks();

    const finalProfile = Object.assign({}, profile, {
        progress: 0,//$injector.get('ProgressService').getProgress(),
        status: appSettings.status,
        stopDate: appSettings.stopDate,
        firstTime: appSettings.firstTime,
        statusNumeric: appSettings.statusNumeric,
        risks: getRisksOrHurdles(risks),
        hurdles: getRisksOrHurdles(hurdles)
    });

    if(profile.statusNumeric && appSettings.statusNumeric && parseInt(profile.statusNumeric) != parseInt(appSettings.statusNumeric)){
        finalProfile.sentNotificationsNew = null;
    }

    return {
        ...finalProfile,
        ...boardingValues
    };
}

function createAppSettings(boardingValues){

    const appSettings = defaultAppSettings;
    return {
        ...appSettings,
        ...boardingValues
    }
}
