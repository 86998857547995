import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import DashboardAlready from "./DashboardAlready";
import {loadUserRequest} from "../../../Actions/userActions";
import {loadInformationFeatureRequest} from "../../../Actions/informationFeatureActions";
import {loadDashboardInfoRequest} from "../../../Actions/dashboardActions";
import DashboardYes from "./DashboardYes";
import DashboardSettings from "./DashboardSettings";
import SetStatusAlreadyDialog from "./SetStatusAlreadyDialog";

class DahboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: null
        };
    }

    componentDidMount(){
        this.props.loadInformationFeatures();
        this.props.loadDashboardInfo();
    }

    render() {
        const { classes, appSettings, ownProps } = this.props;

        return (
            <Fragment>
                { appSettings && appSettings.status && appSettings.status === 'already' ?
                    <DashboardAlready
                        ownProps={this.props.ownProps}
                        healthiness={this.props.healthiness}
                    />
                :
                    <DashboardYes
                        ownProps={this.props.ownProps}
                    />
                }

                {this.props.settings &&
                    <DashboardSettings/>
                }

                {this.props.status && this.props.status &&
                    <SetStatusAlreadyDialog ownProps={this.props.ownProps}/>
                }

            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        appSettings: state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings'] : null,
        ownProps: ownProps,
        healthiness: state.information.healthiness,
        settings: state.dashboard.settings,
        status: state.user.status

    }
}

const mapDispatchToProps = {
    loadInformationFeatures: loadInformationFeatureRequest,
    loadDashboardInfo: loadDashboardInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(DahboardContainer));