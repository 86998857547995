import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./AboutStyles";

class About extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes  } = this.props;

        return (
            <div style={{marginBottom: 30}}>

                <h1>Inhalt</h1>
                <div> kostenfreie App ist für Personen, die ihr Rauchverhalten verändern möchten, rauchfrei werden oder rauchfrei bleiben möchten. Die Rauchfrei App hilft beim Vorbereiten auf den Rauchstopp, unterstützt in den ersten rauchfreien Tagen und längerfristig beim Rauchfrei bleiben.
                    <br/><br/>
                    Die inhaltliche und strukturelle Verknüpfung mit dem Beratungsangebot des Rauchfrei Telefons (<a href="#" onclick="window.open('https://www.rauchfrei.at', '_system', 'location=yes'); return false;">www.rauchfrei.at</a>) bietet zusätzlich kostenfreie individuelle Unterstützung.
                    <br/><br/>
                    Inhaltlich basiert die App auf Verhaltensmodellen der Verhaltensänderung (Transtheoretisches Modell Prochaska und Di Clemente, 1983, Kognitiv - Behaviorales Rückfallmodell von Marlatt und Gordon, 1985). Die jahrelange praktische Beratungserfahrung des gesundheitspsychologischen Teams des Rauchfrei Telefons war maßgeblich für die inhaltliche Gestaltung. Umfassende Empfehlungen von Expertinnen und Experten sowie Praxisleitlinien wurden ebenfalls berücksichtigt (Fiore, 2008, Abroms 2013).
                    <br/><br/>
                    Diese App unterstützt und begleitet den individuellen Prozess, rauchfrei zu werden. Sie kann persönliche Beratung zum Rauchstopp und Behandlung der Tabakabhängigkeit ergänzen, nicht ersetzen.
                </div>

                <h1>Erstellung und Finanzierung</h1>
                <div>
                    <u>Erstellung 2014:</u> Rauchfrei Telefon, Agentur co;des, Institut für Gestaltungs- und Wirkungsforschung der Technischen Universität Wien; finanziert durch den Hauptverband der österreichischen Sozialversicherungsträger.
                    <br/>
                    <u>Neuauflage 2019:</u> Rauchfrei Telefon, Firma Pixler; finanziert durch den Hauptverband der österreichischen Sozialversicherungsträger, das Bundeskanzleramt, Bundesministerin für Frauen, Familien und Jugend sowie das Rauchfrei Telefon.
                </div>

                <h1>Das Rauchfrei Telefon</h1>
                <div>
                    Das Rauchfrei Telefon ist eine Initiative der Sozialversicherungsträger, der Länder und des Bundesministeriums für Arbeit, Soziales, Gesundheit und Konsumentenschutz. Unter der kostenfreien Nummer <b>0800 810 013</b> ist von Montag bis Freitag von 10:00 bis 18:00 Uhr ein Team von Klinischen und Gesundheitspsychologinnen verfügbar, die in der Tabakentwöhnung spezialisiert sind, und rund um das Thema Rauchstopp beraten und informieren. Umfassende Informationen werden auf <b>www.rauchfrei.at</b> zur Verfügung gestellt. Auf Wunsch werden <b>Informationsmaterialien</b> kostenfrei per Post zugeschickt.
                </div>

                <h1>Datenschutz</h1>
                <div >
                    Sämtliche Informationen zum Datenschutz finden Sie unter diesem <a href="#" onclick="window.open('https://www.noegkk.at/cdscontent/?contentid=10007.793830&portal=noegkkportal&viewmode=content', '_system', 'location=yes'); return false;">Link</a>.
                </div>

                <h1>Impressum/Medieninhaber</h1>
                <div>
                    Medieninhaber: Österreichische Gesundheitskasse<br/>
                    Kremser Landstraße 3, 3100 St. Pölten<br/>
                    Für den Inhalt verantwortlich: Rauchfrei Telefon<br/>
                    E-Mail: info@rauchfrei.at<br/>
                    Homepage: <a href="#" onclick="window.open('https://www.rauchfrei.at', '_system', 'location=yes'); return false;">www.rauchfrei.at</a><br/>
                    Grafik: <a href="#" onclick="window.open('https://studio.mishugge.com', '_system', 'location=yes'); return false;">studio.mishugge.com</a><br/>
                    Programmierung: Helmut Chlebecek, <a href="#" onclick="window.open('https://www.pixler.at', '_system', 'location=yes'); return false;">www.pixler.at</a><br/>
                    <a href="#" onclick="window.open('https://www.gesundheitskasse.at/impressum', '_system', 'location=yes'); return false;">www.gesundheitskasse.at/impressum</a><br/>
                </div>
            </div>
        );
    }
}

export default (withStyles(style)(About));
