import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./AttackeStyles";
import { Grid, Paper } from "@material-ui/core";
import {connect} from "react-redux";
import globalStyle from "../../../themes/GlobalStyles";
import PrimaryButton from "../../../Components/PrimaryButton";

class Attacke extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <h1>Rauchverlangen, starker Gusto, die Gedanken drehen sich nur um das Eine?</h1>

                <div className={classes.content}>
                    <strong>Halte durch!</strong> Der Weg in die Rauchfreiheit ist für viele nicht ganz einfach. Verlangensattacken und starker Gusto kommen zu Beginn der rauchfreien Zeit häufig vor. Es kann sein, dass der Gusto so stark wird, dass du Hilfe brauchst, um rauchfrei weiter zu machen. Es kann auch zu einem Ausrutscher kommen.<br/>
                    Vielleicht musst du auch den Rauchstopp neuerlich in Angriff nehmen, wenn du wieder ein paar Tage geraucht hast. <br/><br/>
                    Such dir in diesen Fällen hier Hilfe!
                </div>

                <div className={classes.submitButton}>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{textAlign:'right'}}>
                            <PrimaryButton label={'Ausrutscher'} onClick={() => {this.props.history.push('/intern/attacke/ausrutscher')}}/>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'left'}}>
                            <PrimaryButton label={'Hilfe Rauchverlangen'} onClick={() => {this.props.history.push('/intern/attacke/hilfe')}}/>
                        </Grid>
                    </Grid>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps){
    return{
        history: ownProps.history
    }


}


export default connect(mapStateToProps, null)(withStyles({...globalStyle(null), ...style(null)})(Attacke));
