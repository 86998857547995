import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import BasicDashboardComponent from "../BasicDashboardComponent/BasicDashboardComponent";
import BadgeDashboardComponent from "../BadgeDashboardComponent/BadgeDashboardComponent";
import { Paper, Grid } from '@material-ui/core';
import DashboardSpeedDials from './DashboardSpeedDials';
import HealthinessDashboardComponent from '../HealthinessDashboardComponent/HealthinessDashboardComponent';

import not_smoked from '../../../Assets/nichtgeraucht@2x.png'
import saved from '../../../Assets/gespart@2x.png'
import reward from '../../../Assets/Belohnung@2x.png'
import calendar from '../../../Assets/rauchstoppdatum@2x.png'
import fitnessImg from '../../../Assets/Sport@2x.png'
import flash from '../../../Assets/Motivationsflash@2x.png'
import {showInformationFeatureModal} from "../../../Actions/informationFeatureActions";
import InformationFeaturesModal from "./InformationFeaturesModal";
import moment from "moment";
import {firstDaysOption} from "../../../utils/constants";
import {openTimerDialog, unsetTimer} from "../../../Actions/userActions";
import Attack from "./Attack";

import miez from '../../../Assets/miez.png';
import achievement_1 from '../../../Assets/achievements/achievement_1.png';
import achievement_2 from '../../../Assets/achievements/achievement_2.png';
import achievement_3 from '../../../Assets/achievements/achievement_3.png';
import achievement_4 from '../../../Assets/achievements/achievement_4.png';
import achievement_6 from '../../../Assets/achievements/achievement_6.png';
import achievement_7 from '../../../Assets/achievements/achievement_7.png';
import achievement_8 from '../../../Assets/achievements/achievement_8.png';
import achievement_9 from '../../../Assets/achievements/achievement_9.png';
import achievement_10 from '../../../Assets/achievements/achievement_10.png';
import achievement_11 from '../../../Assets/achievements/achievement_11.png';
import achievement_12 from '../../../Assets/achievements/achievement_12.png';
import achievement_13 from '../../../Assets/achievements/achievement_13.png';
import ImageDashboardComponent from "../ImageDashboardComponent/ImageDashboardComponent";
import TimerDashboardComponent from "../TimerDashboardComponent/TimerDashboardComponent";

class DashboardAlready extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appSettings: false,
            randomAlternative: false,
        }
    }

    componentDidMount(){
        this.calculateValues();
        this.loadAlternatives();
    }

    calculateValues = () => {
        let appSettings = this.props.appSettings;

        let stopDate = new Date(appSettings.stopDate);
        let stopDateManipulated = new Date(appSettings.stopDate);
        let noOfCigarettes = ( () => {
            if(appSettings.noOfCigarettes){
                return appSettings.noOfCigarettes;
            } else if(appSettings && appSettings.profile && appSettings.profile.noOfCigarettes){
                return appSettings.profile.noOfCigarettes;
            } else {
                return 0;
            }
        })();

        let medianPrice = (() => {
            if(appSettings.medianPrice){
                return appSettings.medianPrice;
            } else if(appSettings && appSettings.profile && appSettings.profile.medianPrice){
                return appSettings.profile.medianPrice;
            } else {
                return 0;
            }
        })();

        // Wielange hat er nicht geraucht? in Minuten

        stopDateManipulated.setHours(0);
        stopDateManipulated.setMinutes(0);
        stopDateManipulated.setSeconds(0);

        let minutesSmokeFreeOld = 0;

        if(appSettings.oldDates){
            appSettings.oldDates.forEach(x => {
                minutesSmokeFreeOld += Math.floor((new Date(x.cancelDate) - new Date(x.oldStopDate)) / (1000 * 60));
            });
        }
        let minutesSmokeFree = Math.floor((new Date() - stopDate) / (1000 * 60));
        let minutesSmokeFreeWithPause = minutesSmokeFree + minutesSmokeFreeOld;
        if(minutesSmokeFreeWithPause < 0) {
            minutesSmokeFreeWithPause = 0;
        }

        let minutesSmokeFreeManipulated = Math.floor((new Date() - stopDateManipulated) / (1000 * 60));
        let minutesSmokeFreeWithPauseManipulated = minutesSmokeFreeManipulated + minutesSmokeFreeOld;
        if(minutesSmokeFreeManipulated < 0) {
            minutesSmokeFreeManipulated = 0;
        }

        let cigarettesPerMinute = noOfCigarettes / (24 * 60);
        console.log('noOfCigarettes: ', noOfCigarettes);
        console.log('cigarettesPerMinute: ', cigarettesPerMinute);

        // in Tagen
        appSettings.dayCountSmokeFree = Math.floor(minutesSmokeFreeManipulated / (24 * 60));
        appSettings.dayCountSmokeFreeWithPause = Math.floor(minutesSmokeFreeWithPauseManipulated / (24 * 60));

        console.log('appSettings.dayCountSmokeFreeWithPause: ', appSettings.dayCountSmokeFreeWithPause)


        // Wieviel Zigaretten hat er nicht geraucht, auf die Minute genau (Schätzung)
        appSettings.cigarettesNotSmoked = Math.floor(minutesSmokeFreeWithPause * cigarettesPerMinute);

        appSettings.eurosSaved = ( appSettings.cigarettesNotSmoked * medianPrice).toFixed(2);

        if(appSettings.eurosSaved > 0){
            appSettings.eurosSaved = Math.trunc( appSettings.eurosSaved );
        }

        console.log('eurosSaved: ', appSettings.eurosSaved);

        // next reward
        let reward = null;
        let rewards = this.props.rewards;

        rewards.sort(function(a, b) {
            return a.value - b.value;
        });
        let eurosLeft = appSettings.eurosSaved;
        for (let i = 0; i < rewards.length; i++) {
            let r = rewards[i];
            console.log(r);
            eurosLeft -= r.value;
            if (eurosLeft < 0) {
                reward = r;
                break;
            }
        }

        console.log('eurosLeft: ', eurosLeft);


        if(reward === null){
            reward = false;
        }

        if (eurosLeft < 0) {
            appSettings.nextReward = reward.text;
            eurosLeft = eurosLeft * -1;
            appSettings.nextRewardPercent = Math.floor(eurosLeft / reward.value * 100);

            let euroPerMinute = cigarettesPerMinute * medianPrice;
            let timeLeftMinutesTotal = eurosLeft / euroPerMinute;
            let timespanDate = new Date(timeLeftMinutesTotal * 60 * 1000);
            appSettings.nextRewardMinutes = timespanDate.getMinutes();
            appSettings.nextRewardHours = timespanDate.getHours();

            appSettings.nextRewardDays = Math.floor(timespanDate.getTime() / 1000 / 3600 / 24);
            // führende 0
            if (appSettings.nextRewardMinutes < 10) {
                appSettings.nextRewardMinutes = "0" + appSettings.nextRewardMinutes;
            }
            if (timeLeftMinutesTotal <= 0) {
                appSettings.nextReward = null;
            }
        } else {
            appSettings.nextRewardDays = 0;
        }

        this.setState({appSettings: appSettings});
    };

    loadAlternatives = () => {

        let appSettings = this.props.appSettings;
        let randomAlternative = false;

        if(appSettings.randomAlternative && appSettings.randomAlternative.day === moment().format('DD.MM.YYYY')){
            randomAlternative = appSettings.randomAlternative;
        } else {
            let retries = 0;
            while(!(randomAlternative && randomAlternative.alternative) && retries < 50){
                retries++;
               let customNotifications = this.notificationAllCustom();
               let randomCustomFirstDay = customNotifications.length > 0 ? customNotifications[Math.floor(Math.random() * customNotifications.length)] : undefined;
               let firstDayTexts = firstDaysOption;

                randomAlternative = '';

                if (randomCustomFirstDay) {
                    randomAlternative = firstDayTexts[(randomCustomFirstDay.type == -1 ? 0 : randomCustomFirstDay.type)];
                } else {
                    randomAlternative = firstDayTexts[Math.floor(Math.random() * firstDayTexts.length)];
                }

                if(randomAlternative){
                    randomAlternative.day = moment().format('DD.MM.YYYY');
                }
            }
        }
        this.setState({randomAlternative: randomAlternative});
    };
    notificationAllCustom = (day) => {

        let customNotifications = [];
        let notifications = this.props.notifications;
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i].custom && (day == null || notifications[i].afterDays == day)) {
                notifications[i].index = i;
                customNotifications.push(notifications[i]);
            }
        }

        return customNotifications;
    }

    getAchievement = () => {
        if(this.state.appSettings.dayCountSmokeFreeWithPause >= 0 && this.state.appSettings.dayCountSmokeFreeWithPause < 3){
            return [
                " Den wichtigsten Schritt hast du gemacht!", achievement_1, "Gratulation!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 2 && this.state.appSettings.dayCountSmokeFreeWithPause < 5){
            return [
                "Die erste Etappe hast du geschafft. Bleib dran!", achievement_2, "Super!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 4 && this.state.appSettings.dayCountSmokeFreeWithPause < 7){
            return [
                "Du bist schon einige Tage rauchfrei. Mach weiter so!", achievement_3, "Juhu!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 6 && this.state.appSettings.dayCountSmokeFreeWithPause < 14){
            return [
                "Deine erste Woche ist geschafft! Weiter so!", achievement_4, "Gut gemacht!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 13 && this.state.appSettings.dayCountSmokeFreeWithPause < 21){
            return [
                "Gratulation zu 14 Tagen. Sei stolz darauf!", achievement_6, "Tolle Leistung!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 20 && this.state.appSettings.dayCountSmokeFreeWithPause < 30){
            return [
                "Die goldene Krone ist für dich! Schon 21 Tage!", achievement_7, "Trara!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 29 && this.state.appSettings.dayCountSmokeFreeWithPause < 60){
            return [
                "Nach einem Monat weißt Du wie es geht! Bleib dabei!", achievement_8, "Toll!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 59 && this.state.appSettings.dayCountSmokeFreeWithPause < 90){
            return [
                "2 Monate hast du geschafft! Der Goldpokal ist für dich!",achievement_9, "Hut ab!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 89 && this.state.appSettings.dayCountSmokeFreeWithPause < 180){
            return [
                "Nach 3 Monaten bist Du schon ein Rauchfrei-Profi!", achievement_10, "Gratulation!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 179 && this.state.appSettings.dayCountSmokeFreeWithPause < 270){
            return [
                "Ein Goldpokal zum 6-Monats-Jubiläum! Sei stolz auf dich!", achievement_11, "Applaus!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 269 && this.state.appSettings.dayCountSmokeFreeWithPause < 360){
            return [
                "9 Monate rauchfrei! Die Silberkrone gehört dir!", achievement_12, "Yeah!"
            ];
        } else if(this.state.appSettings.dayCountSmokeFreeWithPause > 359){
            return [
                "1 Jahr Rauchfrei! Gratulation!", achievement_13, "Tatatataa!"
            ];
        } else {
            return ["",""];
        }
    };

    handleClick = (url, params = false) => {
        if(!this.props.dashboardInfo){
            if(params){
                this.props.ownProps.history.push(url, params)
            } else {
                this.props.ownProps.history.push(url)
            }

        }
    };

    renderHealthinessComponent = () => {

        const {classes, dashboardInfo, dashboardInfoData, healthiness, appSettings} = this.props;
        const _healthiness = {};

        if (healthiness && healthiness.length > 0) {
            healthiness.sort(function (a, b) {
                return a.dayFrom - b.dayFrom;
            });

            let lastIndex = 0;
            for (let i = 0; i < healthiness.length; i++) {
                if (healthiness[i].dayFrom <= appSettings.dayCountSmokeFree) {
                    _healthiness.first = healthiness[i];
                    lastIndex = i;
                }

            }

            if (_healthiness.first && healthiness.length > (lastIndex + 1)) {
                _healthiness.second = healthiness[lastIndex + 1];
            } else if (!_healthiness.first) {
                if (healthiness.length > 0) {
                    _healthiness.first = healthiness[0];
                }
                if (healthiness.length > 1) {
                    _healthiness.second = healthiness[1];
                }
            } else if (_healthiness.first && healthiness.length <= (lastIndex + 1)) {
                _healthiness.first = healthiness[healthiness.length - 2];
                _healthiness.second = healthiness[healthiness.length - 1];
            }
        }

        let healthinessProgress = 0;

        if(_healthiness.second && _healthiness.first){
            const diff = _healthiness.second.dayFrom - _healthiness.first.dayFrom;
            const diffNext = appSettings.dayCountSmokeFree > _healthiness.first.dayFrom ? appSettings.dayCountSmokeFree - _healthiness.first.dayFrom : appSettings.dayCountSmokeFree;
            healthinessProgress =  Math.ceil((diffNext / diff) * 100);
            if(healthinessProgress > 100){
                healthinessProgress = 100;
            }
        }

        if(_healthiness.second || _healthiness.first) {
            return <HealthinessDashboardComponent
                name='health'
                paper={true}
                icon={<img src={_healthiness.first.svgImage} className={classes.dashboardImages}/>}
                title={_healthiness.first.title}
                description={_healthiness.first.text}
                dashboardInfo={dashboardInfo}
                dashboardInfoData={dashboardInfoData}
                showInfo={(data) => this.props.showModal({data: {...data.data}})}
                healthinessProgress={healthinessProgress}
            />
        } else {
            return <HealthinessDashboardComponent
                name='health'
                paper={true}
                title={'Im Moment sind keine Informationen zur Verbesserung deiner Körperfunktion hinterlegt.'}
                dashboardInfo={dashboardInfo}
                dashboardInfoData={dashboardInfoData}
                showInfo={(data) => this.props.showModal({data: {...data.data}})}
            />
        }
    }

    render() {
        const { classes, motivation, fact, fitness, dashboardInfo, dashboardInfoData, dashboardSettings } = this.props;

        let showingSettings = [];
        dashboardSettings.forEach(setting => {
           if(setting.visible){
               showingSettings.push(setting.key)
           }
        });

        const achievement  = this.getAchievement();

        return(
            <div className={classes.dashboardContainer}>
                <Grid container spacing={5} style={{marginTop: 30, marginBottom: 90}}>

                    <Grid item xs={12} md={4}>
                        <BasicDashboardComponent
                            name='cigarettesNotSmoked'
                            icon={<img src={not_smoked} className={classes.dashboardImages}/>}
                            title={this.state.appSettings.cigarettesNotSmoked}
                            description={'Zigaretten hast du nicht geraucht'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.days}>
                            {this.state.appSettings.dayCountSmokeFree === 0 ?
                                <span>1.</span>
                                :
                                <span>{this.state.appSettings.dayCountSmokeFree}</span>
                            }
                        </div>
                        <div style={{textAlign: 'center', fontSize: 24}}>
                            {this.state.appSettings.dayCountSmokeFree === 0 && <span> Tag rauchfrei</span>}
                            {this.state.appSettings.dayCountSmokeFree === 1 && <span> Tag bist du bereits rauchfrei</span>}
                            {this.state.appSettings.dayCountSmokeFree > 1 && <span> Tage bist du bereits rauchfrei</span>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BasicDashboardComponent
                            name='eurosSaved'
                            icon={<img src={saved} className={classes.dashboardImages}/>}
                            title={this.state.appSettings.eurosSaved + '€'}
                            description={'hast du bereits gespart'}/>
                    </Grid>

                    {showingSettings.includes('reward') &&
                        <Grid item xs={12} md={4}>
                            <BasicDashboardComponent
                                onClick={() => this.handleClick('/intern/reward', {fromDashboard: true })}
                                name='reward'
                                paper={true}
                                icon={<img src={reward} className={classes.dashboardImages}/>}
                                description={this.state.appSettings.nextReward
                                    ? (this.state.appSettings.nextRewardDays == 0 ? 'Du kannst dir bereits diese Belohnung leisten: '+ this.state.appSettings.nextReward : ('In ' + this.state.appSettings.nextRewardDays + ' Tagen kannst du dir deine Belohnung leisten: ' + this.state.appSettings.nextReward ))
                                    : 'Du hast noch keine Belohnung angelegt'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('tipp') &&
                        <Grid item xs={12} md={8}>
                            <BadgeDashboardComponent
                                status={'already'}
                                name='tipp'
                                title={'Dein Tipp für heute'}
                                description={this.state.randomAlternative.alternative}
                                bold={true}/>
                        </Grid>
                    }

                    {showingSettings.includes('reason') &&
                        <Grid xs={12} md={4} style={{padding: 20}}>
                                <ImageDashboardComponent
                                    onClick={() => this.handleClick('/intern/best_reason', {fromDashboard: true })}
                                    name='reason'
                                    image={this.state.appSettings.bestReasonImage ? this.state.appSettings.bestReasonImage : miez}
                                    description={this.state.appSettings.bestReason ? this.state.appSettings.bestReason: 'Du hast noch keinen Grund angelegt'}
                                    dashboardInfo={dashboardInfo}
                                    dashboardInfoData={dashboardInfoData}
                                    showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    <Grid item xs={12} md={4}>
                        <BasicDashboardComponent
                            onClick={() => this.handleClick('/intern/achievements', {fromDashboard: true, dayCountSmokeFree: this.state.appSettings.dayCountSmokeFree})}
                            name='achievements'
                            paper={true}
                            icon={<img src={achievement[1]} className={classes.dashboardImages}/>}
                            title={achievement[2]}
                            description={[achievement[0]]}
                            dashboardInfo={dashboardInfo}
                            dashboardInfoData={dashboardInfoData}
                            showInfo={(data)=> this.props.showModal({data:{...data.data}})}/>
                    </Grid>

                    {showingSettings.includes('firstDays') &&
                        <Grid item xs={12} md={4}>
                            <BasicDashboardComponent
                                onClick={() => this.handleClick('/intern/first_days', {fromDashboard: true })}
                                name='firstDays'
                                paper={true} icon={<img src={calendar} className={classes.dashboardImages}/>}
                                description={'Erste rauchfrei Tage'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('fakten') &&
                        <Grid item xs={12} md={4}>
                            <BadgeDashboardComponent
                                status={'already'}
                                onClick={() => this.handleClick('/intern/facts', {fromDashboard: true })}
                                name='fakten'
                                paper={true}
                                title={fact.title?fact.title:'Kein Fakt'}
                                description={fact.text?fact.text:'Momentan ist leider kein Fakt vorhanden'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('motive') &&
                        <Grid item xs={12} md={4}>
                            <BadgeDashboardComponent
                                status={'already'}
                                onClick={() => this.handleClick('/intern/motives', {fromDashboard: true })}
                                name='motive'
                                paper={true}
                                title={'Meine Motive'}
                                listItems={this.props.motive}
                                dashboardInfo={dashboardInfo}
                                description={'Zurzeit sind keine Motive angelegt'}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }

                    {showingSettings.includes('fitness') &&
                        <Grid item xs={12} md={4}>
                            <BasicDashboardComponent
                                name='fitness'
                                paper={true}
                                icon={<img src={fitnessImg} className={classes.dashboardImages} style={{height: 90}} />}
                                title={'Hol dir einen Fitness Tipp'}
                                onClick={() => this.props.showModal({
                                    data: {
                                        ...fitness,
                                        title: fitness.title,
                                        name: 'fitness'
                                    }
                                })}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}
                                history={this.props.ownProps.history}
                            />
                        </Grid>
                    }

                    {showingSettings.includes('motivation') &&
                        <Grid item xs={12} md={4}>
                            <BasicDashboardComponent
                                name='motivation'
                                paper={true}
                                icon={<img src={flash} className={classes.dashboardImages}/>}
                                title={'Hol dir einen Motivations-Flash'}
                                onClick={() => this.props.showModal({data: {...motivation, title: 'Motivationsflash'}})}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}/>
                        </Grid>
                    }
                    {showingSettings.includes('health') &&
                        <Grid item xs={12} md={8}>
                            {this.renderHealthinessComponent()}
                        </Grid>
                    }

                    {showingSettings.includes('timer') &&
                        <Grid item xs={12} md={4}>
                            <TimerDashboardComponent
                                name='timer'
                                paper={true}
                                icon={<img src={reward} className={classes.dashboardImages} style={{height: 80}}/>}
                                title={'Verlangensattacke überstehen'}
                                dashboardInfo={dashboardInfo}
                                dashboardInfoData={dashboardInfoData}
                                showInfo={(data) => this.props.showModal({data: {...data.data}})}
                                onClick={() => this.props.openTimerDialog()}
                                counterStart={this.props.countdown}
                                stopTimer={() => this.props.stopTimer()}
                            />
                        </Grid>
                    }

                </Grid>


                <DashboardSpeedDials history={this.props.ownProps.history}/>

                {this.props.modal && this.props.modalData &&
                    <InformationFeaturesModal/>
                }

                {this.props.timerDialog &&
                    <Attack/>
                }

            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        appSettings: state.user.data && state.user.data['app-settings'],
        rewards: state.user.data && state.user.data.reward ? state.user.data.reward :[] ,
        notifications: state.user.data && state.user.data.notifications ? state.user.data.notifications :[],
        motive: state.user.data && state.user.data.motive ? state.user.data.motive : [],
        dashboardSettings: state.user.data && state.user.data['dashboard-already'] ? state.user.data['dashboard-already'] : [],

        motivation: state.information.motivation,
        fitness: state.information.fitness,
        fact: state.information.fact,
        modal: state.information.showModal,
        modalData: state.information.modalData,
        dashboardInfo: state.dashboard.dashboardInfo,
        dashboardInfoData: state.dashboard.dashboardInfoData,

        timerDialog: state.user.timerDialog,
        countdown: state.user.countdown,
    }
}
const mapDispatchToProps = {
    showModal: showInformationFeatureModal,
    openTimerDialog: openTimerDialog,
    stopTimer: unsetTimer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(DashboardAlready));