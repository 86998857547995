import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        content: {
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            padding: 20,
            width: '100%',
            maxWidth: 700,
        },
        listItem: {
            textAlign: 'left'
        },
        listItemTitle: {
            fontWeight: 'bold'
        },
        listSection: {
            textAlign: 'center',
            marginBottom: 20
        }
    }

};

export default style;