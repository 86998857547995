import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";

const Healthiness = (props) => {

    const { classes } = props;
    return (
        <div className={classes.container}>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <h1>Der Rauchstopp ist die beste Form der Gesundheitsvorsorge!</h1>
                    <div className={classes.content}>
                        <p>
                            Rauchen ist ungesund, das ist allgemein bekannt. Der gesamte Organismus wird negativ beeinflusst. Wer raucht erhöht das Risiko, diverse Erkrankungen zu entwickeln.
                            Ein rauchfreier Lebensstil fördert die Gesundheit und reduziert die vermeidbaren Risiken:
                            <p>Bei bereits vorhandenen Erkrankungen hilft der Rauchstopp dabei, das Fortschreiten zu minimieren.</p>
                            <p>Bei medikamentösen Therapien verbessert der Rauchstopp die Wirkung.</p>
                            <p>Bei Operationen beugt der Rauchstopp Wundheilungsstörungen vor.</p>
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <h1>In jedem Fall bringt der Rauchstopp Lebensqualität!</h1>
                    <div className={classes.content}>
                        <p>Bereits kurze Zeit kommt es zu positiven Veränderungen im Körper: </p>
                        <ul>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 20 Minuten</strong> sinkt der Blutdruck nahezu auf einen Wert wie vor der letzten Zigarette. Die Temperatur in Händen und Füßen steigt wieder auf normale Werte.</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 8 Stunden</strong> sinkt der Kohlenmonoxid-Spiegel im Blut auf normale Werte.</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 24 Stunden</strong> verringert sich das Herzanfallrisiko.</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 2 Wochen bis 3 Monaten</strong> verbessern sich die Kreislaufsituation und die Lungenfunktion.</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 1 bis 9 Monaten</strong> gehen Hustenanfälle, Verstopfung der Nasennebenhöhlen, Müdigkeit und Kurzatmigkeit zurück. Die Flimmerhärchen der Atemwege gewinnen wieder ihre Reinigungsfähigkeit, so dass eine normale Lungenfunktion erreicht wird. Die Infektanfälligkeit wird vermindert.</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}><strong>Nach 1 Jahr</strong> sinkt das erhöhte Risiko einer koronaren Herzkrankheit auf die Hälfte des Risikos von Raucherinnen und Rauchern.</span></li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default (withStyles(style)(Healthiness));
