import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import OnBoardingDialog from "./OnBoardingDialog";
import {Button} from "@material-ui/core";
import OnBoardingWelcome from "./OnBoardingWelcome";
import {setNextStep, unsetSteps} from "../../../Actions/wizzardActions";
import OnBoardingName from "./OnBoardingName";
import OnBoardingSlider from "./OnBoardingSlider";
import OnBoardingNoOfCigarettes from "./OnBoardingNoOfCigarettes";
import OnBoardingStopDate from "./OnBoardingStopDate";
import OnBoardingCigarettesPrice from "./OnBoardingCigarettesPrice";
import OnBoardingAge from "./OnBoardingAge";
import {getFormValues} from "redux-form";
import { change } from 'redux-form';
import Login from "../DataTransfer/Login";

class OnBoardingContainer extends Component {
    constructor(props) {
        super(props);
    }

    finishOnBoarding = () => {
        let toTransfer = {...this.props.formValues};
        if(toTransfer.stopDate){
            try {
                toTransfer.stopDate = toTransfer.stopDate.toDate()
            } catch(e){}
        }

        if(this.props.formValues.priceType == true){
            toTransfer.medianPrice = toTransfer.medianPrice * 20;
        } else {
            toTransfer.medianPrice = toTransfer.medianPrice  / 20;
        }

        this.props.history.push('/get_started/login', {boardingValues: toTransfer})
        this.props.unsetSteps();
    };


    render() {
        const { stepIndex, formValues } = this.props;

        let onBoardingComponent = false;

        switch(stepIndex){
            case 0:
                onBoardingComponent =   <OnBoardingWelcome
                                            ownProps={this.props.ownProps}
                                            nextStep={()=>this.props.nextStep()}
                                            login={()=>this.props.nextStep({step: 7})}
                                        />;
                break;
            case 1:
                onBoardingComponent =   <OnBoardingName
                                            nextStep={()=>this.props.nextStep()}
                                            name={formValues&&formValues.name?formValues.name:false}
                />;
                break;
            case 2:
                onBoardingComponent =   <OnBoardingSlider
                                            nextStep={()=>this.props.nextStep()}
                                            name={formValues&&formValues.name?formValues.name:''}
                                            statusNumeric={formValues&&formValues.statusNumeric?formValues.statusNumeric:1}
                                            changeStatus={this.props.changeStatus}
                />;
                break;
            case 3:
                onBoardingComponent =   <OnBoardingNoOfCigarettes
                                            nextStep={()=>this.props.nextStep()}
                                            noOfCigarettes={(formValues&&formValues.noOfCigarettes) ? formValues.noOfCigarettes : 0}
                                            firstCigarette={(formValues&&formValues.firstCigarette) ? formValues.firstCigarette : 0}

                />;
                break;
            case 4:
                onBoardingComponent =   <OnBoardingStopDate
                                            nextStep={()=>this.props.nextStep()}
                                            stopDate={formValues&&formValues.stopDate?formValues.stopDate:null}
                                            statusNumeric={formValues.statusNumeric}
                                        />;
                break;
            case 5:
                onBoardingComponent =   <OnBoardingCigarettesPrice
                                            nextStep={()=>this.props.nextStep()}
                                            medianPrice={formValues&&formValues.medianPrice?formValues.medianPrice:5.5}
                                            priceType={formValues&&formValues.priceType}

                />;
                break;
            case 6:
                onBoardingComponent =   <OnBoardingAge
                                            age={formValues&&formValues.age?formValues.age:false}
                                            sex={formValues&&formValues.sex?formValues.sex:false}
                                            finishOnBoarding={this.finishOnBoarding}
                                        />;
                break;
            case 7:
                onBoardingComponent =   <Login history={this.props.ownProps.history}/>;
                break;
            default:
                this.props.ownProps.history.push("/");
        }

        return (
            <Fragment>
                <OnBoardingDialog component={onBoardingComponent}/>
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        stepIndex: state.wizzard.stepIndex,
        formValues: getFormValues('onBoarding-form')(state)
    }
}
const mapDispatchToProps = {
    nextStep: setNextStep,
    unsetSteps: unsetSteps,
    changeStatus: change
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(OnBoardingContainer));