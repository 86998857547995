import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        content: {
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
            width: 500,
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20
        },
    }

};

export default style;