import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import {getRisksText, getRisksTitle} from "../../../Services/risks";
import globalStyle from "../../../themes/GlobalStyles";
import {risksText} from "../../../utils/constants";

class RisksList extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { classes, risks } = this.props;

        const toShow = [];
        risks.forEach(risk => {
            let value = risk[0];
            let text = getRisksText(risksText, value);
            if(risk[1] === true && text !== null){
                toShow.push({
                    text: text,
                    title: getRisksTitle(value)
                });
            }
        });

        return (

            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Risiken</h1>
                </div>

                <div className={classes.content}>
                    {!!(toShow && toShow.length > 0) ?
                        <div className={classes.listSection}>
                            {toShow.map(item => {
                                return (
                                    <p>
                                        <div className={classes.risksTitle}>{item.title}</div>
                                        <div>{item.text}</div>
                                    </p>
                                )
                            })
                            }
                        </div> : <div>Im Moment sind keine Risiken hinterlegt</div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
       risks: state.user.data && state.user.data.risks && state.user.data.risks.risks ? Object.entries(state.user.data.risks.risks) : [],
    }
}
export default connect(mapStateToProps, null) (withStyles({...globalStyle(null), ...style(null)})(RisksList));