
/* Action types */
const PREFIX = "file/";
export const UPLOAD_FILE_REQUEST = PREFIX + "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = PREFIX + "UPLOAD_FILE_SUCCESS";

export const GET_FILE_REQUEST = PREFIX + "GET_FILE_REQUEST";
export const GET_FILE_SUCCESS = PREFIX + "GET_FILE_SUCCESS";

export const DELETE_FILE_REQUEST = PREFIX + "UPLOAD_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = PREFIX + "UPLOAD_FILE_SUCCESS";




/* initial state */
const initialState = {

    file: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,

};

/* Reducer */
const fileReducer = (state = initialState, action) => {

    switch(action.type) {
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            };
        case GET_FILE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case DELETE_FILE_REQUEST:
            return {
                ...state,
                isDeleting: true,
            };
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                isDeleting: false,
            };

        default:
            return state;
    }
};
export default fileReducer;