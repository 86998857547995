import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {unsetTimer} from "../../../Actions/userActions";
import Applaus from '../../../Assets/applaus_3.wav';

const Timer = (props) => {

    const [counter, setCounter] = React.useState(props.counterStart);

    // Third Attempts
    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    if(counter === 0){
        var _Applaus = new Audio(Applaus);
        _Applaus.play();

        props.stopTimer();
    }

    return (
        <>
            { counter > 0 ? formatDuration(counter, ["minutes", "seconds"], "s", "digital") : "--:--:--" }
        </>
    );

};

function formatDuration(value, units = ["minutes"],  unit = "s", formatType = "units") {
    if (value < 0) {
        value = value * (-1)
    }

    let duration = moment.duration(value, unit).asMilliseconds();
    duration = Math.round(duration / 1000);

    const minuteSeconds = 60;
    const secondSeconds = 1;


    let minutes = false;
    let seconds = false;


    //Get Minutes and subtract from duration
    if (units.indexOf("minutes") !== -1) {
        if (duration >= minuteSeconds) {
            minutes = parseInt(duration / minuteSeconds);
            duration = duration - (minutes * minuteSeconds);
        } else {
            minutes = 0;
        }
    }

    //Get Seconds and subtract from duration
    if (units.indexOf("seconds") !== -1) {
        if (duration >= secondSeconds) {
            seconds = parseInt(duration / secondSeconds);
        } else {
            seconds = 0;
        }
    }

    switch (formatType) {
        case "digital":
            return <span>
                {minutes !== false && <span>{minutes < 10 && "0"}{minutes}</span>}
                {seconds !== false && <span>:{seconds < 10 && "0"}{seconds}</span>}
                </span>;
            break;
        default:
            return <span>
                {minutes !== false && <span>{minutes}m </span>}
                {seconds !== false && <span>{seconds}s </span>}
                </span>;
            break;
    }
}

function mapStateToProps(state) {
    return {
        counterStart: state.user.countdown
    }
}

const mapDispatchToProps = {
    stopTimer: unsetTimer
}

export default connect(mapStateToProps, mapDispatchToProps)((Timer));