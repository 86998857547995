import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";

const Skip = (props) => {

    const { classes } = props;

    return (
        <Fragment>
            <div className={classes.content}>
                <h3>Hau ab! Verlasse für einen Moment die Situation, in der du dich gerade befindest!</h3>
                <div className={classes.listSection}>
                    <ul>
                        <li className={classes.listIcon}><span className={classes.listText}>Wechsle für einige Minuten in ein anderes Zimmer oder vor die Türe, um dir die Füße zu vertreten</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Verlass das Lokal, das Café, die Party und schnappe draußen Frischluft</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Geh aus deinem Büro und lass dir auf der Toilette etwas Wasser über die Handgelenke laufen</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Bring den Müll raus oder hol die Post oder putz dir mal die Zähne</span></li>
                        <li className={classes.listIcon}><span className={classes.listText}>Verlass den Fernseher oder den PC für kurze Zeit und lüfte in der Zwischenzeit durch</span></li>
                    </ul>
                </div>
            </div>


        </Fragment>
    );

}

export default (withStyles(style)(Skip));
