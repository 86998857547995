import React, { useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Radio, RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import { Close as CloseIcon } from '@material-ui/icons';
import {storeRequest, unsetSmokeNow} from "../../../Actions/userActions";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import moment from 'moment';
import PrimaryButton from "../../../Components/PrimaryButton";
import globalStyle from "../../../themes/GlobalStyles";

const required = (value) =>  value !== undefined ? 0 : 'Auswahl erforderlich';

const SmokeNow = (props) => {

    const [state = 1, setState] = useState();

    const submit = (values) => {

        let rauchprotokoll = props.rauchprotokoll;
        const result = {
            date: moment.utc().format(),
            type: values.type,
            importance: values.importance
        };
        rauchprotokoll.push(result);

        props.store({key: 'rauchprotokoll', values: rauchprotokoll});
        props.unsetSmokeNow();
    };

    const radioButton = ({ input,  meta: { touched, error, warning }, ...rest }) => (
        <FormControl>
            <RadioGroup {...input} {...rest} meta>
                <FormControlLabel value="zur Belohnung" control={<Radio />} label="zur Belohnung" />
                <FormControlLabel value="als Pause" control={<Radio />} label="als Pause" />
                <FormControlLabel value="weil andere rauchen" control={<Radio />} label="weil andere rauchen" />
                <FormControlLabel value="zur Entspannung" control={<Radio />} label="zur Entspannung" />
                <FormControlLabel value="aus Langeweile" control={<Radio />} label="aus Langeweile" />
                <FormControlLabel value="aus Ärger" control={<Radio />} label="aus Ärger" />
                <FormControlLabel value="aus Rauchverlangen" control={<Radio />} label="aus Rauchverlangen" />
                <FormControlLabel value="statt Essen" control={<Radio />} label="statt Essen" />
                <FormControlLabel value="Sonstiges" control={<Radio />} label="Sonstiges" />
            </RadioGroup>
            <small>
                {touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
            </small>
        </FormControl>
    );

    const handleImportance = (value) => {
        props.changeImportance('smoke-now-form', 'importance', value)
        setState(value)
    }

    return (
        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={props.smokeNow}>
            <DialogTitle>
                <div className={props.classes.smokeDialogTitle}>
                    <h1>Ich werde jetzt rauchen...</h1>
                </div>
                <div className={props.classes.closeContainer}>
                    <IconButton onClick={() => props.unsetSmokeNow()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.smokeContent}>
                        <div className={props.classes.smokeListItem}>
                           <Field
                               name='type'
                               component={radioButton}
                               validate={required}
                           />
                        </div>


                        <div className={props.classes.smokeDialogTitle}>
                            <span>Diese Zigarette ist mir...</span>
                        </div>
                        <div className={props.classes.btnGroup}>

                            <Button
                                className={props.classes.btnSmoke}
                                variant={state !== 1 ? "outlined" : "contained"}
                                color="primary"
                                onClick={()=> handleImportance(1)}
                            >
                                1
                            </Button>
                            <Button
                                className={props.classes.btnSmoke}
                                variant={state !== 2 ? "outlined" : "contained"}
                                color="primary"
                                onClick={()=> handleImportance(2)}
                            >
                                2
                            </Button>
                            <Button
                                className={props.classes.btnSmoke}
                                variant={state !== 3 ? "outlined" : "contained"}
                                color="primary"
                                onClick={()=> handleImportance(3)}

                            >
                                3
                            </Button>
                            <Button
                                className={props.classes.btnSmoke}
                                variant={state !== 4 ? "outlined" : "contained"}
                                color="primary"
                                onClick={()=> handleImportance(4)}
                            >
                                4
                            </Button>
                            <Button
                                className={props.classes.btnSmoke}
                                variant={state !== 5 ? "outlined" : "contained"}
                                color="primary"
                                onClick={()=> handleImportance(5)}
                            >
                                5
                            </Button>
                        </div>
                        <div>
                            <span style={{float: 'left'}}>unwichtig</span>
                            <span style={{float: 'right'}}>wichtig</span>
                        </div>

                        <Field
                            name={'importance'}
                            component={TextFieldGroup}
                            style={{display: 'none'}}
                        />

                        <div style={{textAlign: 'center', marginTop:50, marginBottom: 20}}>
                            <PrimaryButton
                                label={'Hinzufügen'}
                                onClick={props.handleSubmit(submit)}
                            />
                        </div>
                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        smokeNow: state.user.smokeNow,
        rauchprotokoll: state.user.data && state.user.data.rauchprotokoll ? state.user.data.rauchprotokoll : [],
    }
}

const mapDispatchToProps = {
    unsetSmokeNow: unsetSmokeNow,
    store: storeRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'smoke-now-form',
        enableReinitialize: false,
    }),
    withStyles({...globalStyle(null), ...style(null)})
)(SmokeNow);