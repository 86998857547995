import {SET_NEXT_STEP, SET_PREVIOUS_STEP, UNSET_STEPS} from "../Reducers/wizzardReducer";

export const setNextStep = (values) => ({
    type: SET_NEXT_STEP,
    ...values
});

export const setPreviousStep = () => ({
    type: SET_PREVIOUS_STEP
});

export const unsetSteps = () => ({
    type: UNSET_STEPS
});