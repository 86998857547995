import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./WizzardStyles";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import {setNextStep, setPreviousStep, unsetSteps} from "../../../Actions/wizzardActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";


class WizzardStep extends Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount(){
        this.props.unsetSteps();
    }

    onHandleClose = () => {
        this.props.ownProps.history.push("/intern/dashboard");
    }

    render() {
        const { classes, image, title, text, stepIndex } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <h1>Erklärung zu Rauchfrei</h1>
                </div>
                <div className={classes.content}>
                        <img src={image}  className={classes.stepImage}/>
                        <p className={classes.stepTitle}>{title}</p>
                        <span className={classes.stepText}>{text}</span>
                </div>
                <div className={classes.bottom}>

                    <div className={classes.dotContainer}>
                        <span className={classes.dot + ' ' + (stepIndex == 0 && classes.dotFilled)}></span>
                        <span className={classes.dot + ' ' + (stepIndex == 1 && classes.dotFilled)}></span>
                        <span className={classes.dot + ' ' + (stepIndex == 2 && classes.dotFilled)}></span>
                        <span className={classes.dot + ' ' + (stepIndex == 3 && classes.dotFilled)}></span>
                    </div>

                    <PrimaryButton onClick={()=> this.props.nextStep()} label={'Weiter'}/>
                    {stepIndex > 0 && <div style={{marginTop: 10}}>
                        <SimpleButton onClick={() => this.props.previousStep()} label={'Zurück'} />
                    </div>
                    }
                    <div style={{marginTop: 5}}>
                        <SimpleButton onClick={this.onHandleClose} label={'Überspringen'} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        stepIndex: state.wizzard.stepIndex
    }
}
const mapDispatchToProps = {
    nextStep: setNextStep,
    previousStep: setPreviousStep,
    unsetSteps: unsetSteps
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(withStyles(style)(WizzardStep));