import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        container: {
            textAlign: 'center',
        },
        content: {
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 15,
            paddingLeft: 20,
            textAlign: 'left',
            maxWidth: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20
        },
        link: {
            fontSize: 20,
            cursor: 'pointer',
            color: '#67bad3',
        },
        linkIcon: {
            color: '#F2B63D',
            marginRight: 10
        },
        listSection: {
            textAlign: 'left'
        },
        listIcon: {
            color: '#F2B63D',
        },
        listText: {
            color: '#000'
        },
        healthiness: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: 20,
            width: '80%',
        },
        tobacco: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: 20,
            width: '100%',
        },
        faq: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: 20,
            width: '100%',
        },
        risksTitle: {
            fontWeight: 'bold'
        },

    }

};

export default style;