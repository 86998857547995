import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from '../Reducers/flashMessageReducer';

export function addFlashMessage({message, style}) {
    return {
        type: ADD_FLASH_MESSAGE,
        message,
        style
    }
}

export function deleteFlashMessage(id) {
    return {
        type: DELETE_FLASH_MESSAGE,
        id
    }
}