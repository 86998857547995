import React, { Component, Fragment } from 'react';

import { Button } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        background: '#ffd606',
        borderRadius: 30,
        border: 0,
        color: '#000',
        height: 50,
        minWidth: 200,
        padding: '0 60px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
        '&:hover': {
            cursor: 'pointer',
            background: '#ffd606',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        },
        fontFamily: 'Fedra',
        fontWeight: 'bold'
    }
}));


const PrimaryButton = ({label, onClick, style, disabled}) => {

    const classes = useStyles();

    return (
        <Button className={classes.container} style={style} variant={'contained'} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

export default PrimaryButton;