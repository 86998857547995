import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import {Field} from "redux-form";
import DatePickerGroup from "../../../Components/DatePickerGroup";
import moment from 'moment'
import PrimaryButton from "../../../Components/PrimaryButton";
const OnBoardingStopDate = ({classes, nextStep, stopDate,statusNumeric}) => {

    if(statusNumeric !== 5) {
        nextStep();
    }

    return (
        <div className={classes.container}>
            <h1 className={classes.dialogTitle}>Rauchstopp</h1>
            <div className={classes.content}>
                <div className={classes.inputContainer}>
                    <div className={classes.inputLabel}><p>Ich rauche nicht mehr seit</p>
                        <Field
                            name='stopDate'
                            component={DatePickerGroup}
                            fullWidth
                            maxDate={moment()}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    disabled={!(stopDate && moment(stopDate).isSameOrBefore())}
                    onClick={nextStep}
                    label={'Weiter'}
                />
            </div>

        </div>
    )
};

export default withStyles(style)(OnBoardingStopDate);
