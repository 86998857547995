import { takeLatest, all, takeEvery, debounce } from 'redux-saga/effects';
import api from "../Services/api";

import {HIDE_INFORMATION_FEATURE_MODAL, LOAD_INFORMATION_FEATURE_REQUEST} from "../Reducers/informationFeatureReducer";
import {loadInformationFeatureRequest} from "../Sagas/informationFeatureSagas";
import {LOAD_DASHBOARD_INFO_REQUEST} from "../Reducers/dashboardReducer";
import {loadDashboardInfoRequest} from "./dashboardSagas";
import {
    CREATE_ACCOUNT_REQUEST, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, LOGIN_REQUEST, LOGOUT_REQUEST,
    SET_PASSWORD_REQUEST
} from "../Reducers/authReducer";
import {createAccount, forgotPassword, login, logout, setPassword} from "./authSagas";
import {setResetPassword} from "../Actions/authActions";
import {
    LOAD_USER_REQUEST, LOAD_USER_SUCCESS, STORE_PROFILE_REQUEST, STORE_PROFILE_SUCCESS, STORE_REQUEST,
    STORE_SUCCESS
} from "../Reducers/userReducer";
import {loadUser, reloadActiveDay, store, storeProfile} from "./userSagas";
import {GET_FILE_REQUEST, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS} from "../Reducers/fileReducer";
import {getFileRequest, uploadFile} from "./fileSagas";



export default function* root() {
    yield all([

        /* Dashboard*/
        takeLatest(LOAD_INFORMATION_FEATURE_REQUEST, loadInformationFeatureRequest, api),
        takeLatest(LOAD_DASHBOARD_INFO_REQUEST, loadDashboardInfoRequest, api),

        /* get new Informations for Dashboard */
        takeLatest(HIDE_INFORMATION_FEATURE_MODAL, loadInformationFeatureRequest, api),

        /* login */
        takeLatest(LOGIN_REQUEST, login, api),

        /* create User */
        takeLatest(CREATE_ACCOUNT_REQUEST, createAccount, api),

        /* set User password */
        takeLatest(SET_PASSWORD_REQUEST, setPassword, api),

        /* forgot password */
        takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword, api),

        /* logout */
        takeLatest(LOGOUT_REQUEST, logout),

        /* store function */
        takeLatest(STORE_REQUEST, store, api),

        /* store profile function */
        takeLatest(STORE_PROFILE_REQUEST, storeProfile, api),

        /* load user */
        takeLatest(LOAD_USER_REQUEST, loadUser, api),

        /* upload File */
        takeLatest(UPLOAD_FILE_REQUEST, uploadFile, api),

        /*reloads*/
        takeLatest(
            [
                STORE_PROFILE_SUCCESS,
                STORE_SUCCESS,
                UPLOAD_FILE_SUCCESS
            ],
            loadUser, api, {loadFromApi: true}),

        takeLatest(LOAD_USER_SUCCESS, reloadActiveDay),

    ]);
}