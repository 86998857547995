import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./ImageDashboardComponentStyles";
import HelpIcon from '@material-ui/icons/Help';

const ImageDashboardComponent = ({ classes, name, paper = false, description, image, onClick, dashboardInfo, dashboardInfoData, showInfo}) => {

    let tileStyle = {};
    let ifFitness = {};
    let fitnessTitle = {};

    tileStyle = {
        cursor: 'pointer',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }

    let modalData = {};
    if(dashboardInfoData && dashboardInfoData.length){
        modalData = dashboardInfoData.find(item => item.key === name);
    }

    return (
        <div className={classes.dashboardTiles} style={tileStyle} onClick={onClick}>

            {dashboardInfo &&
                <div className={classes.infoOverlay} onClick={()=> showInfo({data: {...modalData}})}>
                    <HelpIcon className={classes.infoIcon}/>
                </div>
            }

            <div className={classes.container} style={ifFitness} >
                <div className={classes.description}>
                    {description}
                </div>
            </div>
        </div>

    );

}

export default (withStyles(style)(ImageDashboardComponent));