import React, { useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./SupportStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import {connect} from "react-redux";
import { Close as CloseIcon } from '@material-ui/icons';
import {storeRequest, unsetActiveSupport, unsetAddDialog} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";

const required = (value) => value ? 0 : 'Auswahl erforderlich';

const SupportDialog = (props) => {
    useEffect(() => {
        return () => {
            props.unsetActiveSupport();
        }
    }, []);

    const getSupport = (element) => element.id === props.activeSupport.id;

    const submit = (values) => {
        const support = props.supports;

        if(props.editable){
            const index = support.findIndex(getSupport);

            support[index] = {
                id: support[index].id,
                text: values.text,
                number: values.number
            };
        } else {
            support.push(values)
        }

        props.store({key: 'support', values: support});
        props.unsetAddDialog();
    };

    const deleteSupport = () => {
        const newSupport = [];

        props.supports.forEach(support =>{
            if(support.id !== props.activeSupport.id){
                newSupport.push(support)
            }
        });
        props.store({key: 'support', values: newSupport});
        props.unsetAddDialog();
    };

    return (
        <Dialog fullWidth maxWidth={"md"} classes={{ paper: props.classes.dialogPaper }} open={true}>
            <DialogTitle>
                <div className={props.classes.dialogTitle}>
                    <span>Unterstützung {props.editable?'bearbeiten':'hinzufügen'}</span>
                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => props.unsetAddDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.dialogContent}>
                        <Field
                            name={'text'}
                            component={TextFieldGroup}
                            fullWidth
                            placeholder={'Bitte eingeben ...'}
                            validate={required}
                        />

                        <div style={{height:50}}></div>

                        <Field
                            name={'number'}
                            type={'tel'}
                            component={TextFieldGroup}
                            fullWidth
                            placeholder={'0664/123456'}
                            validate={required}
                        />

                        <div style={{marginTop: 30, marginBottom: 10, textAlign: 'center'}}>
                            <PrimaryButton
                                onClick={props.handleSubmit(submit)}
                                label={'Speichern'}
                            />

                            {props.editable &&
                                <div style={{marginTop: 10, textAlign: 'center'}}>
                                    <SimpleButton
                                        onClick={deleteSupport}
                                        label={'Löschen'}
                                    />
                                </div>
                            }
                        </div>


                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return{
        supports: state.user.data && state.user.data.support ? state.user.data.support : [],
        editable: state.user.editable,
        activeSupport: state.user.activeSupport,
        initialValues: {
            text: state.user.activeSupport&&state.user.activeSupport.text?state.user.activeSupport.text:'',
            number: state.user.activeSupport&&state.user.activeSupport.number?state.user.activeSupport.number:'',
        }
    }
}

const mapDispatchToProps = {
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
    unsetActiveSupport: unsetActiveSupport
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'support-form',
        enableReinitialize: true,
    }),
    withStyles(style)
)(SupportDialog);