
/* Action types */
const PREFIX = "wizzard/";

export const SET_NEXT_STEP = PREFIX + "SET_NEXT_STEP";
export const SET_PREVIOUS_STEP = PREFIX + "SET_PREVIOUS_STEP";
export const UNSET_STEPS = PREFIX + "UNSET_STEPS";



/* initial state */
const initialState = {
    stepIndex: 0,
};

/* Reducer */
const wizzardReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_NEXT_STEP:
            let step = state.stepIndex + 1;
            if(action.step !== undefined){
                step = action.step;
            }

            return {
                ...state,
                stepIndex: step
            };
        case SET_PREVIOUS_STEP:

            let prevStep = state.stepIndex - 1;
            if(state.stepIndex == 7){ //login
                prevStep = 0;
            }
            return {
                ...state,
                stepIndex: prevStep
            };
        case UNSET_STEPS:
            return {
                ...state,
                stepIndex: 0
            };
        default:
            return state;
    }
};
export default wizzardReducer;