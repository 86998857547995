import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import { Grid, Paper } from '@material-ui/core';
import {getDayCount} from "./DashboardYes";




class Statistics extends Component {
    constructor(props) {
        super(props);
    }

    countTypes = (item) => {
        let count = 0;
        for(let i = 0; i < this.props.rauchprotokoll.length; ++i){
            if(this.props.rauchprotokoll[i].type === item.type)
                count++;
        }
        return count;
    };


    renderListItems = (items) =>{
        return items.map((item, index) => {return this.renderItem(item, index+1)});
    };

    renderItem = (item, index) =>{
        return (
            <p><span style={{fontWeight: 'bold', fontSize: 18}}> {index}</span> <span className={this.props.classes.listText}>{item.type} <span style={{fontWeight: 'bold', fontSize: 18}}>{item.count} mal</span></span></p>
        )
    };

    importance = () => {
        let rauchprotokoll = this.props.rauchprotokoll;
        let importantCount = [];
        let importantMatch = [];
        let important = undefined;
        for (let i = 0; i < rauchprotokoll.length; i++) {
            important = rauchprotokoll[i].importance;
            if (importantMatch.indexOf(important) == -1) {
                importantMatch.push(important);
                importantCount[importantMatch.indexOf(important)] = 1;
            } else {
                importantCount[importantMatch.indexOf(important)]++;
            }
        }
        let objReturn = [];
        for (let i = 0; i < importantCount.length; i++) {
            objReturn.push({
                important: importantMatch[i],
                count: importantCount[i],
            });
        }
        objReturn.sort(function(a, b) {
            return b.count - a.count;
        });
        return objReturn;
    };



    render() {
        const { classes } = this.props;


        const types = [];
        const resultTypes = [];

        this.props.rauchprotokoll.forEach(protokoll => {
            if(!types.includes(protokoll.type)){
                types.push(protokoll.type)
                resultTypes.push({type: protokoll.type, count: this.countTypes(protokoll)})
            }
        });

        const importance = this.importance();
        let importanceTotal = 0;
        for (let i = 0; i < importance.length; i++) {
            importanceTotal += importance[i].count;
        }

        return (

            <Fragment>
                <div className={classes.title}>
                    <h1>Statistik</h1>
                </div>

                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p style={{float: 'left', fontWeight: 'bold', fontSize: 20}}>Tag</p>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <p style={{float: 'right', fontWeight: 'bold', fontSize: 20}}>Zigaretten</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <span style={{float: 'right', fontWeight: 'bold', fontSize: 18, paddingRight: 20}}>{getDayCount(this.props.rauchprotokoll).length}</span>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <span><hr style={{height: 3, background: 'black', border: 'none'}}/></span>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <span style={{float: 'left', fontWeight: 'bold', fontSize: 18, paddingLeft: 20}}>{this.props.rauchprotokoll.length}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <h2>Ich habe geraucht weil: </h2>
                            {this.renderListItems(resultTypes)}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <h2>Wichtigkeit</h2>
                            <div>
                                {
                                    importance.map(imp => {
                                        return(
                                            <p><strong>{Math.round(imp.count / importanceTotal * 100)} %</strong> der gerauchten Zigarretten waren <strong>{imp.important}</strong></p>
                                        )
                                    })
                                }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
       rauchprotokoll: state.user.data && state.user.data.rauchprotokoll? state.user.data.rauchprotokoll: [],
    }
}
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(Statistics));