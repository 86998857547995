import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./WizzardStyles";
import WizzardStep from "./WizzardStep";



import wizzard_0 from '../../../Assets/wizard_0.png';
import wizzard_1 from '../../../Assets/wizard_1.png';
import wizzard_2 from '../../../Assets/wizard_2.png';
import wizzard_3 from '../../../Assets/wizard_3.png';

class WizzardContainer extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { stepIndex } = this.props;

        let image = false;
        let title = false;
        let text = false;

        switch(stepIndex){
            case 0:
                image = wizzard_0;
                title = 'Burger Menü';
                text = 'Im Burgermenü oben links  kannst du dein Profil anlegen, deine Eingaben für die einzelnen Module machen und Fakten und Push Notifications/Benachrichtigungen nachlesen.'
                break;
            case 1:
                image = wizzard_1;
                title = 'Dashboard Info';
                text = 'Hier findest du Informationen zu allen Modulen, die du auf dein Dashboard gelegt hast.';
                break;
            case 2:
                image = wizzard_2;
                title = 'Dashboardcustomizer';
                text = 'Oben rechts in der Toolbar kannst du anklicken, welche Module du am Dashboard haben möchtest.'
                break;
            case 3:
                image = wizzard_3;
                title = 'Push Notifications/Benachrichtigungen';
                text = 'Es gibt ein Pool aus motivierenden Benachrichtigungen. Je mehr Eingaben du machst, desto individueller werden die Nachrichten für dich ausgewählt. Wenn du keine Nachrichten bekomen möchtest, kannst du sie bei "Einstellungen" ausschalten.'
                break;
            default:
                this.props.ownProps.history.push("/intern/dashboard");
        }

        return (
            <Fragment>
                {image && title && text  &&
                    <WizzardStep image={image} title={title} text={text} step={stepIndex}/>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        stepIndex: state.wizzard.stepIndex,
    }
}
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(WizzardContainer));