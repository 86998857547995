import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import globalStyle from "../../../themes/GlobalStyles";


class FitnessList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, fitnessList } = this.props;

        return (

            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Fitness Tipps</h1>
                </div>

                <div className={classes.content}>
                    <span>Diese Fitness Tipps hast du als nützlich markiert.</span>

                    <div style={{height: 20}}></div>

                    <div className={classes.daysContent}>
                        {fitnessList.map(tipp => {
                            return(
                                <p>
                                    <div className={classes.fitnessTitle}>{tipp.title}</div>
                                    <div className={classes.fitnessText}>{tipp.text}</div>
                                </p>
                            )})
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
       fitnessList: state.user.data && state.user.data.fitnessWhiteList ? state.user.data.fitnessWhiteList : [],
    }
}
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles({...globalStyle(null), ...style(null)})(FitnessList));