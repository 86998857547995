import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { Button, MenuItem } from "@material-ui/core";
import {Field} from "redux-form";
import SelectGroup from "../../../Components/SelectGroup";
import PrimaryButton from "../../../Components/PrimaryButton";
import {cigarettes, firstCigarette as firstCigarettes} from "../../../utils/constants";
import TextFieldGroup from "../../../Components/TextFieldGroup";

const OnBoardingNoOfCigarettes = ({classes, nextStep, noOfCigarettes, firstCigarette }) => {

    const renderOptions = (options) => {
        return options.map( option => renderOption(option))
    };

    const renderOption = (option) => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(!!((noOfCigarettes && firstCigarette && noOfCigarettes >= 0))){ //validate
                nextStep();
            }
        }
    };

    return (
        <div className={classes.container}>
            <h1 className={classes.dialogTitle}>Anzahl</h1>
            <div className={classes.content}>
                <div className={classes.inputContainer}>
                    <div className={classes.inputLabel} style={{marginTop: 0, marginBottom: 8}}>Ich rauche ... Zigaretten pro Tag
                        {/*<Field
                            name='noOfCigarettes'
                            component={SelectGroup}
                            fullWidth
                        >
                            {renderOptions(cigarettes)}
                        </Field>*/}
                        <Field
                            label={'Anzahl'}
                            name='noOfCigarettes'
                            type={'number'}
                            component={TextFieldGroup}
                            validate={value => (value >= 0) ? 0 : 'Auswahl erforderlich'}
                            containerStyle={{textAlign:'center', maxWidth: 200}}
                            inputStyle={{textAlign:'center'}}
                            style={{alignItems: 'center', width: 200}}
                            onKeyDown={_handleKeyDown}
                        />
                    </div>
                    <div className={classes.inputLabel}>Ich rauche die erste Zigarette
                        <Field
                            name='firstCigarette'
                            component={SelectGroup}
                            fullWidth
                        >
                            {renderOptions(firstCigarettes)}
                        </Field>
                    </div>
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    disabled={!(firstCigarette && noOfCigarettes >= 0)}
                    onClick={nextStep}
                    label={'Weiter'}
                />
            </div>
        </div>
    )
};

export default withStyles(style)(OnBoardingNoOfCigarettes);
