import {getParsedEntry, saveParsedEntry} from "../utils/helper";

export const dashboardViewAlready = [
    {
        settingsTitle: '',
        key: 'cigarettesNotSmoked',
        icon: 'cigarettes-smoked-icon',
        text: null,
        description: 'Zigaretten hast du nicht geraucht',
        href: '',
    },
    {
        settingsTitle: '',
        key: 'eurosSaved',
        icon: 'money-saved-icon',
        text: null,
        description: 'hast du bereits gespart',
        href: '',
    },
    {
        settingsTitle: 'Mein bester Grund',
        key: 'reason',
        icon: '',
        text: '',
        description: '',
        href: 'app.best-reason',
        hrefReturnTo: {returnto: 'homescreen-already'},
    },
    {
        settingsTitle: 'Meine Belohnung',
        key: 'reward',
        icon: 'reward-icon',
        text: '',
        description: '',
        href: 'app.reward',
        hrefReturnTo: {returnto: 'homescreen-already'},
    },
    {
        settingsTitle: 'Dein Tipp für Heute',
        key: 'tipp',
        icon: '',
        text: 'Dein Tipp für heute',
        description: '',
        href: ''
    },
    {
        settingsTitle: '',
        key: 'achievements',
        icon: '',
        text: null,
        description: '',
        href: 'achievements'
    },
    {
        settingsTitle: 'Erste rauchfreie Tage',
        key: 'firstDays',
        icon: 'calendar-yellow-icon',
        text: '',
        description: 'Erste rauchfreie Tage',
        href: 'app.firstdays',
    },
    {
        settingsTitle: 'Faktencheck',
        key: 'fakten',
        icon: '',
        text: '',
        description: '',
        href: 'faktencheck'
    },
    {
        settingsTitle: 'Meine Motive',
        key: 'motive',
        icon: '',
        text: 'Meine Motive:',
        description: '',
        href: 'app.motive',
        hrefReturnTo: {returnto: 'homescreen-already'},
    },
    {
        settingsTitle: 'Fitness',
        key: 'fitness',
        icon: 'hantel-icon',
        text: '',
        description: 'Hol dir einen Fitness Tipp',
        href: ''
    },
    {
        settingsTitle: 'Motivationsflash',
        key: 'motivation',
        icon: 'flash-icon',
        text: '',
        description: 'Hol dir einen Motivationsflash',
        href: ''
    },
    {
        settingsTitle: 'Verbesserung der Körperfunktion',
        key: 'health',
        icon: '',
        text: 'Körperfunktion',
        description: 'Hier steht mal was ganz interessantes',
        href: 'healthiness'
    },
    {
        settingsTitle: 'Verlangensattacke',
        key: 'timer',
        icon: 'confetti-icon',
        text: 'Verlangensattacke überstehen',
        description: '',
        href: ''
    }
];

export const dashboardViewMaybe = [
    {
        settingsTitle: '',
        key: 'smokeNow',
        icon: 'smoke-now-icon',
        text: 'Ich werde jetzt rauchen',
        description: null,
        href: '',
    },
    {
        settingsTitle: '',
        key: 'statistic',
        icon: 'present-icon',
        text: 'Auswertung:',
        description: '',
        href: 'statistik',
    },
    {
        settingsTitle: '',
        key: 'checklist',
        icon: 'checklist-icon',
        text: null,
        description: 'der Checkliste habe ich erledigt',
        href: 'app.checklist',
    },
    {
        settingsTitle: '',
        key: 'stopDate',
        icon: 'calendar-icon-1',
        text: '',
        description: '',
        href: '',
    },
    {
        settingsTitle: 'Vorbereitungsfortschritt',
        key: 'firstDaysProgress',
        icon: 'calendar-yellow-icon',
        text: null,
        description: '',
        href: '',
    },
    {
        settingsTitle: 'Erste rauchfreie Tage',
        key: 'firstDays',
        icon: 'calendar-icon-1',
        text: '',
        description: 'Erste rauchfreie Tage',
        href: 'app.firstdays',
    },
    {
        settingsTitle: 'Motivwaage',
        key: 'motivwaage',
        icon: 'calendar-icon',
        text: 'Deine Motive:',
        description: '',
        href: 'app.balance',
    },
    {
        settingsTitle: 'Meine Risiken',
        key: 'risiken',
        icon: '',
        text: 'Meine Risiken:',
        description: '',
        href: 'app.risks',
    },
    {
        settingsTitle: 'Stolpersteine',
        key: 'stolpersteine',
        icon: '',
        text: 'Meine Stolpersteine:',
        description: '',
        href: 'app.hurdles-list',
    },
    {
        settingsTitle: 'Meine Motive',
        key: 'motive',
        icon: '',
        text: 'Meine Motive:',
        description: '',
        href: 'app.motive',
        hrefReturnTo: {returnto: 'homescreen-already'},
    }
];

export const getDashboardAlready = () => {
    const dashboard = getParsedEntry('dashboard-already');

    let defaultVisibility = [
        {
            key: 'cigarettesNotSmoked',
            visible: true
        },
        {
            key: 'eurosSaved',
            visible: true
        },
        {
            key: 'reason',
            visible: false
        },
        {
            key: 'reward',
            visible: false
        },
        {
            key: 'tipp',
            visible: false
        },
        {
            key: 'achievements',
            visible: true
        },
        {
            key: 'firstDays',
            visible: false
        },
        {
            key: 'motive',
            visible: false
        },
        {
            key: 'motivation',
            visible: false
        },
        {
            key: 'fakten',
            visible: false
        },
        {
            key: 'fitness',
            visible: false
        },
        {
            key: 'health',
            visible: false
        },
        {
            key: 'timer',
            visible: false
        }
    ];

    if(dashboard && dashboard.length > 0){
        defaultVisibility = dashboard;
    }

    let dashboardView = [
        ...dashboardViewAlready
    ];

    dashboardView.forEach(widget => {
        defaultVisibility.forEach(visibility => {
            if(widget.key === visibility.key){
                widget.visible = visibility.visible;
            }
        })
    });
    saveParsedEntry('dashboard-already', dashboardView);

    return dashboardView;
};

export const getDashboardYes = () => {
    const dashboard = getParsedEntry('dashboard-yes');

    let defaultVisibility = [
        {
            key: 'smokeNow',
            visible: true
        },
        {
            key: 'statistic',
            visible: true
        },
        {
            key: 'checklist',
            visible: true
        },
        {
            key: 'stopDate',
            visible: true
        },
        {
            key: 'firstDaysProgress',
            visible: false
        },
        {
            key: 'firstDays',
            visible: false
        },
        {
            key: 'motivwaage',
            visible: false
        },
        {
            key: 'risiken',
            visible: false
        },
        {
            key: 'stolpersteine',
            visible: false
        },
        {
            key: 'motive',
            visible: false
        },

    ];

    if(dashboard && dashboard.length > 0){
        defaultVisibility = dashboard;
    }

    let dashboardView = [
        ...dashboardViewMaybe
    ];

    dashboardView.forEach(widget => {
        defaultVisibility.forEach(visibility => {
            if(widget.key === visibility.key){
                widget.visible = visibility.visible;
            }
        })
    });

    saveParsedEntry('dashboard-yes', dashboardView);

    return dashboardView;
};