import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { Field, reduxForm, getFormValues } from 'redux-form';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./SettingsStyles";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import { MenuItem, Grid, Button } from "@material-ui/core";
import SwitchGroup from "../../../Components/SwitchGroup";
import {storeRequest} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";
import SimpleButton from "../../../Components/SimpleButton";


class Settings extends Component {
    constructor(props) {
        super(props);
    }

    required = value => value ? 0 : 'Auswahl erforderlich';

    submit = (values) => {
        this.props.store({key:'app-settings', values:values});
    };

    resetCounter = () => {
        this.props.store({key:'app-settings', values: {stopDate:'', oldDates: []}})
    };

    resetEverything = () => {
        localStorage.clear();
        window.location.reload();
    };

    submitAndBack = (values) => {
        this.submit(values);
        this.props.history.push('/intern/dashboard');
    };

    render() {
        const { classes, handleSubmit  } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Einstellungen</h1>
                </div>

                <div className={classes.content}>
                    <form>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Field
                                    label={'Name'}
                                    name='name'
                                    component={TextFieldGroup}
                                    fullWidth
                                    validate={[this.required]}
                                />
                            </Grid>
                            {/*<Grid item xs={7}>
                                <p>Push Notifikation</p>
                            </Grid>
                            <Grid item xs={5}>
                                <Field
                                    name='disableNotifications'
                                    component={SwitchGroup}
                                    fullWidth
                                    label={'ja'}
                                />
                            </Grid>*/}

                        </Grid>
                    </form>
                </div>
                <div className={classes.submitButton}>
                    <PrimaryButton
                        label={'Speichern'}
                        onClick={handleSubmit(this.submit)}
                    />
                    <br/>
                    <PrimaryButton
                        style={{marginTop: 10, marginBottom: 20}}
                        label={'Speichern und zurück'}
                        onClick={handleSubmit(this.submitAndBack)}
                    />
                    <div style={{marginTop: 10}}>
                        <SimpleButton
                            label={'Zähler zurücksetzen'}
                            onClick={()=>this.resetCounter()}
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <SimpleButton
                            label={'Alles zurücksetzen'}
                            onClick={()=>this.resetEverything()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const appSettings = state.user && state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings']: false;

    return{
        initialValues: {
            name: appSettings && appSettings.name ? appSettings.name : '',
            disableNotifications: appSettings && appSettings.disableNotifications ? appSettings.disableNotifications : false,
        }
    }
}
const mapDispatchToProps = {
    store: storeRequest,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'settings-form',
        enableReinitialize: true
    }),
    withStyles(style)
)(Settings);
