import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import {risksText} from '../../../utils/constants';
import globalStyle from "../../../themes/GlobalStyles";

class RisksList extends Component {
    constructor(props) {
        super(props);
    }

    getHurdlesText = (hurdleType) => {
        if (!risksText || risksText.length === 0) {
            return null;
        }
        for (let i = 0; i < risksText.length; i++) {
            if (risksText[i].type === hurdleType) {
                return risksText[i].text;
            }
        }
        return null;
    };

    getRisksTitle = (riskType) => {
        switch(riskType){
            case 'like': return 'Mir schmeckt´s'; break;
            case 'stress': return 'Hilft mir beim Stress'; break;
            case 'used': return 'Rauchen ist meine Gewohnheit'; break;
            case 'noreason': return 'Ich hab keinen Grund aufzuhören'; break;
            case 'nowant': return 'Ich will nicht aufhören'; break;
            case 'nobreak': return 'Ohne Zigarette keine Pause'; break;
            case 'nogainweight': return 'Ich will nicht zunehmen'; break;
            case 'smokeflat': return 'Viele in meiner Umgebung rauchen'; break;
            case 'helps': return 'Rauchen hilft mir einfach manchmal'; break;
            case 'noother': return 'Ich gönn mir sonst nichts'; break;
            case 'fear': return 'Ich hab Angst vor den Entzugssymptomen'; break;
            case 'nosucceed': return 'Ich denk, das schaff ich sowieso nicht'; break;
            default: return 'Unbekannt';
        }
    };

        render() {
        const { classes, hurdles } = this.props;

        const risksText = [];

            hurdles.forEach(hurdle => {
            let value = hurdle[0];
            if(hurdle[1] === true && this.getHurdlesText(value) !== null && this.getRisksTitle(value !== null)){
                risksText.push(
                    {
                        text: this.getHurdlesText(value),
                        title: this.getRisksTitle(value)

                    }
                )
            }
        });

        return (

            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Stolpersteine</h1>
                </div>

                <div className={classes.content}>

                    {!!(risksText && risksText.length > 0) ?
                        <div className={classes.listSection}>
                            {risksText.map(item => {
                                return (
                                    <p>
                                        <div className={classes.risksTitle}>{item.title}</div>
                                        <div>{item.text}</div>
                                    </p>
                                )
                            })
                            }
                        </div> : <div>Im Moment sind keine Stolpersteine hinterlegt</div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
       hurdles: state.user.data && state.user.data.risks && state.user.data.risks.hurdles ? Object.entries(state.user.data.risks.hurdles) : [],
    }
}
export default connect(mapStateToProps, null) (withStyles({...globalStyle(null), ...style(null)})(RisksList));