import React from "react";

const style = theme => {
    return {
        container: {
            width: '100%',
            padding: 10,
            height: '100%',
            minHeight: 200
        },
        icon: {
            width: '100%',
            textAlign: 'center'
        },
        title: {
            fontSize: 24,
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
        },
        description: {
            width: '100%',
            height: '50%',
            textAlign: 'center',
        },

        dashboardTiles: {
            position: 'relative',
            height: '100%',
            cursor: 'pointer'
        },
        infoOverlay: {
            position: 'absolute',
            backgroundColor: '#000',
            opacity: 0.8,
            width: '100%',
            height: '100%',
            borderRadius: 8,
            display: 'flex',
            zIndex: 5,
            alignItems: 'center'
        },
        infoIcon: {
            color: '#fff',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 50,
        },
        fitnessFooter: {
            backgroundColor: '#67bad3',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            zIndex: 1,
        },
        fitnessFooterText: {
            width: '100%',
            color: '#fff',
            textAlign: 'center',
            display: 'block',
            lineHeight: '40px',

        }
    }
};

export default style;