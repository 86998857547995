import React from "react";

const style = theme => {
    return {
        dialog: {
            paddingTop: 40,
            width: '100%',
            textAlign: 'center'
        },
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        content: {
            marginTop: 10,
            marginBottom: 30,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
            maxHeight: 450,
        },
        welcomeTitle: {
            textAlign: 'center',
            marginTop: 20
        },
        dialogTitle: {
            textAlign: 'center',
            marginTop: 20
        },
        dialogText: {
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 20
        },
        inputLabel: {
            fontWeightBold: 'bold',
            textAlign: 'center',
            marginTop: 30
        },
        errorMessage: {
            color: '#d23c3c',
            marginTop: 50,
            textAlign: 'center'
        },
        inputContainer: {
            padding: 20,
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        buttonContainer: {
            textAlign: 'center',
            marginTop: 30
        }
    }
};

export default style;