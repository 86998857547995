import React from "react";



const style = theme => {
    return {
        dashboardImages: {
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 20,
            display: 'block',
            height: 120
        },
        modalTitle: {
            textAlign: 'center',
        },
        modalText: {
        },
        shareSection: {
            textAlign: 'center',
            fontSize: 40
        },
        days: {
            fontSize: 150,
            fontWeight: 'light',
            textAlign: 'center',
            lineHeight: 1
        },
        fitnessTitle: {
            fontWeight: 'bold'
        },
        fitnessText: {

        },
        title: {
            textAlign: 'center'
        },
        statisticsCard: {
            textAlign: 'left'
        },
        paper: {
            padding: 30,
            height: '100%'
        },
        listIcon: {
            color: '#F2B63D',
        },
        listText: {
            color: '#000'
        },
        smokeListItem: {
            fontSize: 18,
            fontWeight: 'bold'
        },
        smokeContent: {
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            width: '50%'
        },
        smokeDialogTitle: {
            textAlign: 'center',
        },
        btnGroup: {
            display: 'flex',
            flexDirection: 'row'
        },
        btnSmoke: {
            flex: 1,
            margin: 20
        },
        achievements: {
            textAlign: 'center',
        },
        achievementsGrid: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
        },
        settingsSection: {
            textAlign: 'center',
            marginBottom: 20,
            padding: 20,
        },
        settingsItem: {
            fontWeight: 'bold',
            textAlign: 'left'
        },
        settingsTitle: {
            textAlign: 'center',
        },
        dashboardContainer: {
        },
        componentContainer: {
            marginTop: 20,
        },
        speedDialText: {
            color: '#FFF',
            fontWeight: 'bold',
            marginBottom: 20,
            cursor: 'pointer'
        },
    }

};

export default style;