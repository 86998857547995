import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import {connect} from "react-redux";

const Addiction = (props) => {

    const { classes } = props;

    let fagerstrom = 0;


    if(props.noOfCigarettes && props.firstCigarette){

        switch (props.firstCigarette) {
            case 5:
                fagerstrom += 3;
                break;
            case 30:
                fagerstrom += 2;
                break;
            case 60:
                fagerstrom += 1;
                break;
        }

        if (props.noOfCigarettes > 30) {
            fagerstrom += 3;
        } else if (props.noOfCigarettes > 20) {
            fagerstrom += 2;
        } else if (props.noOfCigarettes > 10) {
            fagerstrom += 1;
        }

    }

    return (
        <div className={classes.container}>
            <h1>Abhängigkeit</h1>
            <div className={classes.content}>
                    <div className={classes.listSection}>

                        Mit dem Heaviness of Smoking Index (kurz HSI) kann die körperliche Abhängigkeit vom Nikotin eingeschätzt werden. Deine Angaben zur täglich gerauchten Zigarettenmenge und zum Zeitpunkt der ersten Zigarette des Tages geben einen Hinweis darauf, wie stark deine körperliche Abhängigkeit ist.


                        {fagerstrom <= 4 ?
                            <p>
                                Aufgrund deiner persönlichen Angaben zeigt sich, dass du nicht stark nikotinabhängig bist
                                und
                                vorwiegend aufgrund von Gewohnheiten und in bestimmten Situationen rauchst. Plane konkrete
                                Alternativen statt des Rauchens. Nutze die Funktion „Erste rauchfreien Tage“, um zu deinen
                                gewohnten Rauchzeiten konkrete Tipps zu erhalten.
                            </p>
                            :
                            <p>
                                Aufgrund deiner persönlichen Angaben zeigt sich, dass dein Körper stark nikotinabhängig ist.
                                Beachte die körperliche Abhängigkeit beim Vorbereiten deiner Rauchfreiheit. Informiere dich
                                in der Apotheke oder in der ärztlichen Praxis über Nikotinersatzprodukte. Diese können in
                                den ersten rauchfreien Tagen und Wochen eine zusätzliche Unterstützung sein.
                            </p>
                        }


                    </div>
                </div>
            </div>

    );
}

function mapStateToProps(state) {
    return{
        noOfCigarettes: state.user && state.user.data && state.user.data.profile && state.user.data.profile.noOfCigarettes ? state.user.data.profile.noOfCigarettes : false,
        firstCigarette: state.user && state.user.data && state.user.data.profile && state.user.data.profile.firstCigarette ? state.user.data.profile.firstCigarette : false,
    }
}

export default connect(mapStateToProps, null) (withStyles(style)(Addiction));
