
/* Action types */
const PREFIX = "informationFeature/";

export const LOAD_INFORMATION_FEATURE_REQUEST = PREFIX + "LOAD_INFORMATION_FEATURE_REQUEST";
export const LOAD_INFORMATION_FEATURE_SUCCESS = PREFIX + "LOAD_INFORMATION_FEATURE_SUCCESS";
export const LOAD_INFORMATION_FEATURE_ERROR = PREFIX + "LOAD_INFORMATION_FEATURE_ERROR";

export const SHOW_INFORMATION_FEATURE_MODAL = PREFIX + "SHOW_INFORMATION_FEATURE_MODAL";
export const HIDE_INFORMATION_FEATURE_MODAL = PREFIX + "HIDE_INFORMATION_FEATURE_MODAL";



/* initial state */
const initialState = {
    healthiness: [],
    motivation: {},
    fact: {},
    fitness: {},
    isFetching: false,
    error: false,
    

    showModal: false,
    modalData: {}
};

/* Reducer */
const informationFeatureReducer = (state = initialState, action) => {

    switch(action.type) {
        case LOAD_INFORMATION_FEATURE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case LOAD_INFORMATION_FEATURE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                healthiness: action.healthiness,
                motivation: action.motivation,
                fact: action.fact,
                fitness: action.fitness
            };
        case LOAD_INFORMATION_FEATURE_ERROR:
            return {
                ...state,
                error: true
            };
        case SHOW_INFORMATION_FEATURE_MODAL:
            return {
                ...state,
                showModal: true,
                modalData: action.data
            };
        case HIDE_INFORMATION_FEATURE_MODAL:
            return {
                ...state,
                showModal: false,
                modalData: {}
            };
        default:
            return state;
    }
};
export default informationFeatureReducer;