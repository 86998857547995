import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";

const Pregnant = (props) => {

    const { classes } = props;
    return (
            <div className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={6} md={6}>
                        <h1>Viele Veränderungen</h1>
                        <div className={classes.content}>
                            <div>
                                <p>Die Schwangerschaft und die Stillzeit sind Zeiten, in denen sich so viel verändert, wie selten im Leben. Diese Zeit rauchfrei zu leben ist nicht nur für die Eltern, sondern gerade für das Kind besonders wichtig.</p>
                                <p>Der Rauchstopp ermöglicht eine optimale Versorgung des Kindes. Durch die Rauchfreiheit wird die Plazenta besser durchblutet und kann das Ungeborene ausreichend mit Sauerstoff und Nährstoffen versorgen. So kann sich das Kind bestmöglich entwickeln.</p>
                                <p>Durch den veränderten Stoffwechsel in der Schwangerschaft wird das Rauchverlangen in den ersten Tagen oft stärker empfunden. Gute Unterstützung und professionelle Beratung sind hilfreich, um dem Verlangen Stand zu halten.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <h1>Am besten Rauchfrei</h1>
                        <div className={classes.content}>
                            <div>
                                <p>Ein Rauchstopp bringt Vorteile für Mutter, Vater und Kind:</p>
                                <ul>
                                    <li className={classes.listIcon}><span className={classes.listText}>Das Kind hat eine größere Chance, gesund und normalgewichtig auf die Welt zu kommen</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>Das Kind wird vor mehreren tausend giftigen, teilweise krebserregenden Substanzen geschützt</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>Das Risiko einer Fehl- oder Frühgeburt sowie eines plötzlichen Kindstod verringert sich</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>Das Kind neigt in weiterer Folge weniger zu Atemwegserkrankungen, Koliken und Allergien, was auch für die Eltern eine Erleichterung bedeutet</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>Geld für das Baby und die Eltern kann gespart werden</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>Auch die Eltern leben gesünder und fühlen sich wohler</span></li>
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <h1>Stillzeit</h1>
                        <div className={classes.content}>
                            <div>
                                <p>Über die Muttermilch werden die Schadstoffe der Zigarette an das Baby weitergegeben. Mit der Rauchfreiheit wird verhindert, dass das Kind den Suchtstoff Nikotin sowie Schadstoffe über die Muttermilch, Haut und Kleidung aufnimmt.</p>
                                Wenn noch geraucht wird, dann:
                                <ul>
                                    <li className={classes.listIcon}><span className={classes.listText}>nicht in der Gegenwart des Kindes rauchen</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>nicht in Innenräumen oder im Auto rauchen</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>die Zigarettenmenge stark reduzieren</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>vor dem Stillen lange Rauchpausen einlegen</span></li>
                                    <li className={classes.listIcon}><span className={classes.listText}>nach dem Rauchen Hände waschen</span></li>
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
    );
}

export default (withStyles(style)(Pregnant));
