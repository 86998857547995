import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";


import close from '../../../Assets/v2/btn_close_big.png';
import help from '../../../Assets/hilfe@2x.png'
import mobil from '../../../Assets/rauchfreitelefon-anrufen@2x.png'
import tips from '../../../Assets/attacke@2x.png'
import again from '../../../Assets/ausrutscher@2x.png'
import calendar from '../../../Assets/neuenrauchstoppplanen@2x.png'
import genug from '../../../Assets/v3/genug-schatten@2x.png'

import {setStatusAlready, storeRequest} from "../../../Actions/userActions";
import {useMediaQuery} from "react-responsive";

class DashboardSpeedDials extends Component {
    constructor(props) {
        super(props);

        this.state = {overlay: false, isDesktopOrLaptop: !!(window.innerWidth > 900)};
    }

    handleStop = () => {
        let profile = this.props.profile;
        let appSettings = this.props.appSettings;

        profile.status = 'maybe';
        appSettings.status = 'maybe';
        profile.statusNumeric = 4;
        appSettings.statusNumeric = 4;

        if(!appSettings.oldDates){
            appSettings.oldDates = [];
        }

        appSettings.oldDates.push(
            {
                oldStopDate: appSettings.stopDate,
                cancelDate: new Date()
            }
        );
        appSettings.stopDate = undefined;
        appSettings['profile'] = profile;

        this.props.store({key: 'app-settings', values: appSettings});
        this.props.store({key: 'profile', values: profile});

    };

    showOverlay = () => {
        const { overlay } = this.state;
        this.setState({overlay: !overlay});
    };

    goto = (page) => {
        this.props.history.push('/intern/'+page);
    };



    render() {
        const { classes } = this.props;



        return (
            <Fragment>
                {this.props.profile.status === 'already' ?
                    <div style={{position: 'fixed', bottom: 20, left: 0, width: '100%', textAlign: 'center', zIndex: 100}}>
                        <img style={{cursor: 'pointer', width: '110px', height: '110px'}} src={ this.state.overlay ? close : help} onClick={() => this.showOverlay()}/>
                    </div>
                    :
                    <div style={{position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'center'}}>
                        <img style={{cursor: 'pointer', width: '110px', height: '110px'}} src={genug} onClick={() => this.props.setStatus()}/>
                    </div>
                }

                { this.state.overlay && [
                    <div style={{zIndex: 99, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', backdropFilter: 'blur(5px)'}}>

                    </div>,
                    <div style={{zIndex: 101, position: 'fixed', bottom: 120, left: 0, width: '100%', textAlign: 'center'}}>
                        <div style={{width: 900, marginLeft: 'auto', marginRight: 'auto'}}>

                            <a href="tel:0800810013" style={{textDecoration: 'none', marginBottom: this.state.isDesktopOrLaptop ? 40 : 0}}>
                                <img style={{cursor: 'pointer', width: '90px', height: '90px'}} src={mobil}/>
                                <div className={classes.speedDialText}>Rauchfrei Telefon anrufen</div>
                            </a>

                            <div style={{flex: 1, flexDirection: 'row', display: this.state.isDesktopOrLaptop ? 'flex' : 'block', marginBottom: this.state.isDesktopOrLaptop ? 40 : 0, marginTop: this.state.isDesktopOrLaptop ? 80 : 0}}>
                                <div onClick={() => this.goto('attacke')} style={{flex: 1}}>
                                    <img style={{cursor: 'pointer', width: '90px', height: '90px'}} src={tips}/>
                                    <div className={classes.speedDialText}>Tipps bei Rauchverlangen</div>
                                </div>
                                <div onClick={() => this.goto('attacke/ausrutscher')} style={{flex: 1}}>
                                    <img style={{cursor: 'pointer', width: '90px', height: '90px'}} src={again}/>
                                    <div className={classes.speedDialText}>Wieder geraucht</div>
                                </div>
                                <div onClick={this.handleStop} style={{flex: 1}}>
                                    <img style={{cursor: 'pointer', width: '90px', height: '90px'}} src={calendar}/>
                                    <div className={classes.speedDialText}>Neuen Rauchstopp planen</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ]
                }


            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        profile: state.user.data && state.user.data.profile ? state.user.data.profile : {},
        appSettings: state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings'] : {},
    }
}
const mapDispatchToProps = {
    store: storeRequest,
    setStatus: setStatusAlready
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(DashboardSpeedDials));