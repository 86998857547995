import React from 'react';
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

const CheckboxGroup = ({ input, label, checked, value, classes, onChange, disabled, required, defaultChecked,  meta: { touched, error, warning } }) => {

    return (
        <>
        <FormControlLabel
            control={<Checkbox
                {...input}
                value={value}
                checked={input.value ? true : false}
                required={required}
                classes={classes}
                onChange={input.onChange}
                disabled={disabled}
                defaultChecked={defaultChecked}

            />}
            label={<Typography style={{fontFamily: 'Fedra'}}>{label}</Typography>}
        />
        <small>
            {touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
        </small>
        </>



    )
}
export default (CheckboxGroup);