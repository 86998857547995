import {
    ADD_CONTRA,
    ADD_PRO, CLOSE_TIMER_DIALOG,
    LOAD_USER_FAILED, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, OPEN_TIMER_DIALOG, RESET_REDUX, SET_ACTIVE_DAY,
    SET_ACTIVE_NOTIFICATION,
    SET_ACTIVE_REWARD,
    SET_ACTIVE_SUPPORT,
    SET_ADD_DIALOG, SET_SMOKE_NOW, SET_STATUS_ALREADY, SET_TIMER,
    STORE_FAILED, STORE_PROFILE_FAILED, STORE_PROFILE_REQUEST, STORE_PROFILE_SUCCESS,
    STORE_REQUEST,
    STORE_SUCCESS, UNSET_ACTIVE_DAY, UNSET_ACTIVE_NOTIFICATION, UNSET_ACTIVE_REWARD, UNSET_ACTIVE_SUPPORT,
    UNSET_ADD_DIALOG, UNSET_SMOKE_NOW, UNSET_STATUS_ALREADY, UNSET_TIMER
} from "../Reducers/userReducer";

export const resetRedux = () => ({
    type: RESET_REDUX
});

export const storeProfileRequest = (data) => ({
    type: STORE_PROFILE_REQUEST,
    ...data
});

export const storeProfileSuccess = () => ({
    type: STORE_PROFILE_SUCCESS

});
export const storeProfileFailed = () => ({
    type: STORE_PROFILE_FAILED,
});

export const storeRequest = (data) => ({
    type: STORE_REQUEST,
    ...data
});

export const storeSuccess = () => ({
    type: STORE_SUCCESS
});
export const storeFailed = () => ({
    type: STORE_FAILED,
});

export const loadUserRequest = (values) => ({
    type: LOAD_USER_REQUEST,
    ...values
});

export const loadUserSuccess = (data) => ({
    type: LOAD_USER_SUCCESS,
    ...data
});

export const loadUserFailed = () => ({
    type: LOAD_USER_FAILED,
});

export const setAddDialog = () => ({
    type: SET_ADD_DIALOG,
});

export const unsetAddDialog = () => ({
    type: UNSET_ADD_DIALOG,
});

export const setActiveReward = (reward) => ({
    type: SET_ACTIVE_REWARD,
    ...reward
});

export const unsetActiveReward = () => ({
    type: UNSET_ACTIVE_REWARD,
});

export const setActiveSupport = (support) => ({
    type: SET_ACTIVE_SUPPORT,
    ...support
});

export const unsetActiveSupport = () => ({
    type: UNSET_ACTIVE_SUPPORT,
});

export const addPro = () => ({
    type: ADD_PRO
});

export const addContra = () => ({
    type: ADD_CONTRA,
});

export const setActiveDay = (day) => ({
    type: SET_ACTIVE_DAY,
    ...day
});

export const unsetActiveDay = () => ({
    type: UNSET_ACTIVE_DAY,
});

export const setActiveNotification = (notification) => ({
    type: SET_ACTIVE_NOTIFICATION,
    ...notification
});

export const unsetActiveNotification = () => ({
    type: UNSET_ACTIVE_NOTIFICATION,
});

export const setSmokeNow = () => ({
    type: SET_SMOKE_NOW,
});

export const unsetSmokeNow = () => ({
    type: UNSET_SMOKE_NOW,
});

export const openTimerDialog = () => ({
    type: OPEN_TIMER_DIALOG,
});

export const closeTimerDialog = () => ({
    type: CLOSE_TIMER_DIALOG,
});

export const setTimer = (seconds) => ({
    type: SET_TIMER,
    ...seconds
});

export const unsetTimer = () => ({
    type: UNSET_TIMER,
});

export const setStatusAlready = () => ({
    type: SET_STATUS_ALREADY,
});

export const unsetStatusAlready = () => ({
    type: UNSET_STATUS_ALREADY,
});

