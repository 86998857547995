import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./SupportStyles";
import { Grid, Button, IconButton} from "@material-ui/core";
import {setActiveSupport, setAddDialog} from "../../../Actions/userActions";
import SupportDialog from "./SupportDialog";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PrimaryButton from "../../../Components/PrimaryButton";

class Support extends Component {
    constructor(props) {
        super(props);
    }

    handleEdit = (support) => {
        this.props.setActiveSupport({support: support});
        this.props.setAddDialog();
    };

    render() {
        const { classes, dialog, supports } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Unterstützung</h1>
                </div>

                <div className={classes.content}>
                    Trage Telefonnummern von Menschen ein, die du im Notfall anrufen kannst.

                    {supports &&
                        <div style={{marginTop: 30}}>
                            {supports.map(support => {
                                return(
                                    <div>
                                        <p className={classes.listItem}>
                                            <span>{support.text}</span>
                                            <span style={{marginLeft: 20}}>{support.number}</span>

                                            <IconButton style={{padding: 0, color:'#67bad3'}} onClick={()=>this.handleEdit(support)}>
                                                <ArrowForwardIosIcon/>
                                            </IconButton>
                                        </p>
                                        <hr/>
                                    </div>
                                )})
                            }
                        </div>
                    }
                    <div className={classes.addButton}>
                        <PrimaryButton
                            onClick={()=>this.props.setAddDialog()}
                            label={'Unterstützung hinzufügen'}
                        />
                    </div>
                </div>


                {dialog && <SupportDialog />}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        dialog: state.user.addDialog,
        supports: state.user.data && state.user.data.support ? state.user.data.support:[]
    }
}
const mapDispatchToProps = {
    setAddDialog: setAddDialog,
    setActiveSupport: setActiveSupport
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Support));
