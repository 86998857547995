import React from "react";

const globalStyle = theme => {
    return {
        container: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 10,
        },
        content: {
            marginTop: 30,
            marginBottom: 20,
            backgroundColor: '#FFF',
            borderRadius: 15,
            padding: 30,
            textAlign: 'center',
            width: '100%',
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 30
        },
        closeContainer: {
            position: 'absolute',
            right: 10,
            top: 10
        }
    }

};

export default globalStyle;