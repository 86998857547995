import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";

const Tobacco = (props) => {

    const { classes } = props;
    return (
        <div className={classes.container}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <h1>Chemie - Cocktail</h1>
                    <div className={classes.content}>
                        <div>
                            <p><strong>Tabakrauch</strong> enthält <strong>4.800 chemische Substanzen</strong>, die miteinander in Wechselwirkung stehen und sich so oft noch verstärken. Viele der Inhaltsstoffe sind giftig, ca. 90 davon sind krebserregend.</p>

                            <p>Einen unbedenklichen Grenzwert für den Konsum von Zigaretten gibt es deshalb nicht.<br/>Schon 1-4 Zigaretten täglich erhöhen das Risiko für zahlreiche Erkrankungen, wie Herz-Kreislauferkrankungen, Lungenerkrankungen etc.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h1>Inhaltstoffe</h1>
                    <div className={classes.content}>

                        <p>Im Tabakrauch finden sich u.a. Kohlenmonoxid (Auspuffgase), Ammoniak (Reinigungsmittel), Blei und Cadmium (Batterien), freie Radikale etc.<br/><br/>
                            Viele Inhaltsstoffe der Zigarette werden extra beigemischt</p>
                        <ul>
                            <li className={classes.listIcon}><span className={classes.listText}>Menthol erleichtert das Rauchen,</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Ammoniak beschleunigt die Aufnahme von Nikotin,</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Vanille, Lakritze und Honig überdecken den schlechten Tabakgeschmack und</span></li>
                            <li className={classes.listIcon}><span className={classes.listText}>Zucker verdoppelt das Suchtpotential, man wird schneller abhängig.</span></li>

                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h1>Nikotin macht süchtig</h1>
                    <div className={classes.content}>

                        <p><strong>Nikotin</strong> ist jener Inhaltstoff der Zigarette, der süchtig macht. Beim Inhalieren gelangt das Nikotin innerhalb von 7 Sekunden ins Gehirn. Dort werden Botenstoffe ausgeschüttet, die das Belohnungszentrum aktivieren und starke positiv empfundene Effekte auslösen. Deshalb willst oder musst du bald wieder zur nächsten Zigarette greifen.</p>
                        <p>Häufig werden durchschnittlich zwischen 15 und 25 Zigaretten täglich geraucht, um den Nikotinspiegel über den Tag gut aufrecht zu erhalten. Oft ist die erste Zigarette des Tages sehr wichtig, da der Nikotinspiegel im Blut über Nacht im Schlaf abfällt.</p>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default (withStyles(style)(Tobacco));
