import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        content: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: 20,
            width: '100%'
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 20
        },
    }

};

export default style;