import React, { Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FirstDaysStyles";
import { MenuItem, Grid, Button, IconButton } from "@material-ui/core";
import NotificationsDialog from "./NotificationsDialog";
import {setActiveNotification, setAddDialog} from "../../../Actions/userActions";
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import PrimaryButton from "../../../Components/PrimaryButton";

const FirstDayDetail = (props) => {


    console.log(props._activeDay)
    const activeDay = props._activeDay?props._activeDay:props.location.state.activeDay;

    const setNotification = (notification) => {
        props.setActiveNotification({notification: notification});
        props.setAddDialog();
    };
    return (
        <div className={props.classes.container}>
            <div className={props.classes.title}>
                <h1>Tag {activeDay.day + 1}</h1>
            </div>

            <div className={props.classes.content}>
                <span>Wähle jene Situationen und Uhrzeiten aus, in denen du besonders darauf achten musst, gute Alternativen zur Zigarette bereit zu haben. Zu diesen Zeiten erhältst Du dann eine passende Push Notification.</span>

                <div className={props.classes.listSection}>
                    {activeDay.notifications.map(notification => {
                        return(
                            <div onClick={()=>setNotification(notification)}>
                                <p className={props.classes.listItem}>
                                    {notification.label}

                                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right', zIndex: 50}}>
                                        <ArrowForwardIosIcon/>
                                    </IconButton>
                                </p>
                                <hr/>
                            </div>
                        )})
                    }
                </div>

                <div className={props.classes.addButton}>
                    <PrimaryButton
                        label={'Ereignis hinzufügen'}
                        onClick={()=>props.setAddDialog()}
                    />
                </div>
            </div>

             {props.dialog && <NotificationsDialog activeDay={activeDay}/>}

        </div>
    );

}

function mapStateToProps(state) {
    return{
        dialog: state.user.addDialog,
        _activeDay: state.user.activeDay&&state.user.activeDay.notifications&&state.user.activeDay.notifications.length?state.user.activeDay:false
    }
}
const mapDispatchToProps = {
    setAddDialog: setAddDialog,
    setActiveNotification: setActiveNotification
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FirstDayDetail));
