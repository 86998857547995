import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./MotivStyles";
import { MenuItem, Grid, Button, IconButton } from "@material-ui/core";
import { setAddDialog, storeRequest, unsetAddDialog } from "../../../Actions/userActions";
import MotivDialog from "./MotivDialog";
import DeleteIcon from '@material-ui/icons/Delete';
import PrimaryButton from "../../../Components/PrimaryButton";

class Motiv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }
    }

    deleteMotive = (value) => {
        const newMotives = [];

        this.props.motives.forEach(motive =>{
            if(motive !== value){
               newMotives.push(motive)
            }
        })
        this.props.store({key: 'motive', values: newMotives});
    };


    render() {
        const { classes, dialog, motives } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Motive</h1>
                </div>

                <div className={classes.content}>
                    <span>Gute Motive helfen dir, langfristig rauchfrei zu bleiben.</span>

                    {motives &&
                        <div style={{marginTop: 30}}>
                                {motives.map(motive => {
                                    return(
                                        <div onMouseEnter={()=>this.setState({active: motive})} onMouseLeave={()=>this.setState({active: false})}>
                                            <p className={classes.listItem}>
                                                {motive.text}

                                                {motive===this.state.active &&
                                                    <IconButton style={{padding: 0, color:'red', float: 'right', zIndex: 50, marginTop: 0}} onClick={() => this.deleteMotive(motive)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                }
                                            </p>

                                            <hr/>
                                        </div>
                                    )})
                                }
                            </div>
                        }
                    <div className={classes.addButton}>
                        <PrimaryButton
                            onClick={()=>this.props.setAddDialog()}
                            label={'Motiv hinzufügen'}/>
                    </div>
                </div>




                {dialog && <MotivDialog/>}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        dialog: state.user.addDialog,
        motives: state.user.data && state.user.data.motive ? state.user.data.motive:[],
    }
}
const mapDispatchToProps = {
    setAddDialog: setAddDialog,
    unsetAddDialog: unsetAddDialog,
    store: storeRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Motiv));
