import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FirstDaysStyles";
import {Button, IconButton, Badge} from "@material-ui/core";
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import {setActiveDay} from "../../../Actions/userActions";

class FirstDaysContainer extends Component {
    constructor(props) {
        super(props);
    }

    setDay = (day) => {
        this.props.setActiveDay({day: day});
        this.props.ownProps.history.push('/intern/first_days/detail', {activeDay: day});

    };

    getNotifications = (byDay) => {
        const result = this.props.notifications.filter(notification => notification.afterDays===byDay);
        return result;
    };

    render() {
        const { classes } = this.props;

        const firstDays = [
            {
                day: 0,
                notifications: this.getNotifications(0)
            },
            {
                day: 1,
                notifications: this.getNotifications(1)
            },
            {
                day: 2,
                notifications: this.getNotifications(2)
            },
            {
                day: 3,
                notifications: this.getNotifications(3)
            },
            {
                day: 4,
                notifications: this.getNotifications(4)
            },
            {
                day: 5,
                notifications: this.getNotifications(5)
            },
            {
                day: 6,
                notifications: this.getNotifications(6)
            }
        ];


        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Erste rauchfreie Tage</h1>
                </div>

                <div className={classes.content}>
                    <span>Plane die ersten Tage deiner rauchfreien Zeit besonders gut! Welche Situationen könnten für dich anspruchsvoll sein? Spezielle Push Notifications können Dir in diesen Situationen weiterhelfen!</span>

                    <div className={classes.daysContent}>
                        {firstDays.map(day => {
                            return(
                                <div onClick={()=>this.setDay(day)}>
                                    <p className={classes.listItem}>
                                        <span> Tag {day.day + 1}</span>
                                        <span className={classes.badge}><Badge badgeContent={day.notifications&&day.notifications.length?day.notifications.length:'0'} color="primary"/></span>

                                        <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}}>
                                            <ArrowForwardIosIcon/>
                                        </IconButton>
                                    </p>
                                    <hr/>
                                </div>
                            )})
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return{
        ownProps: ownProps,
        notifications: state.user.data && state.user.data.notifications ? state.user.data.notifications : []
    }
}
const mapDispatchToProps = {
    setActiveDay: setActiveDay,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(style)(FirstDaysContainer));