import React from 'react';
import {
    Group as GroupIcon,
    Settings as SettingsIcon,
    Person as PersonIcon,
    Star as StarIcon,
    EmojiEmotions as EmojiIcon,
    Notifications as NotificationIcon,
    PlaylistAddCheck as PlaylistAddCheckIcon,
    Warning as WarningIcon,
    DateRange as DateRangeIcon,
    SwapHoriz as SwapHorizIcon,
    Help as HelpIcon,
    ThumbUp as ThumbUpIcon,
    PersonAdd as PersonAddIcon,
    MonetizationOn as MonetizationOnIcon,
    CastForEducation as CastForEducationIcon,
    School as SchoolIcon,
    AttachFile as AttachFileIcon,
    ExitToApp as ExitToAppIcon,
    ViewAgenda as ViewAgendaIcon

} from '@material-ui/icons';


export default {
    items: [
        {
            name: 'Mein Profil',
            url: '/intern/profil',
            icon: <PersonIcon/>,
            type: 'always',
        },
        {
            name: 'Dashboard',
            url: '/intern/dashboard',
            icon: <StarIcon/>,
            type: 'always'
        },
        {
            name: 'Meine Belohnung',
            url: '/intern/reward',
            icon: <MonetizationOnIcon/>,
            type: 'already'
        },
        {
            name: 'Mein bester Grund',
            url: '/intern/best_reason',
            icon: <ThumbUpIcon/>,
            type: 'already'
        },
        {
            name: 'Meine Motive',
            url: '/intern/motives',
            icon: <EmojiIcon/>,
            type: 'always'
        },
        {
            name: 'Meine Unterstützung',
            url: '/intern/support',
            icon: <PersonAddIcon/>,
            type: 'already'
        },
        /*{
            name: 'Alle Benachrichtigungen',
            url: '/intern/notifications',
            icon: <NotificationIcon/>,
            type: 'always'
        },*/
        {
            name: 'Checkliste',
            url: '/intern/checklist',
            icon: <PlaylistAddCheckIcon/>,
            type: 'maybe'
        },
        {
            name: 'Motivwaage',
            url: '/intern/scale',
            icon: <ViewAgendaIcon/>,
            type: 'maybe'
        },
        {
            name: 'Meine Risiken',
            url: '/intern/risk',
            icon: <WarningIcon/>,
            type: 'maybe'
        },
        {
            name: 'Stolpersteine',
            url: '/intern/hurdles',
            icon: <GroupIcon/>,
            type: 'maybe'
        },
        {
            name: 'Erste rauchfreie Tage',
            url: '/intern/first_days',
            icon: <DateRangeIcon/>,
            type: 'always'
        },
        {
            name: 'Einführung',
            url: '/intern/wizzard',
            icon: <CastForEducationIcon/>,
            type: 'always'
        },
        {
            name: 'Fakten',
            url: '/intern/facts',
            icon: <SchoolIcon/>,
            type: 'always'
        },
        {
            name: 'Tipps',
            url: '/intern/tips',
            icon: <AttachFileIcon/>,
            type: 'always'
        },
        /*{
            name: 'Daten übertragen',
            url: '/intern/transfer',
            icon: <SwapHorizIcon/>,
            type: 'always',
            key: 'transfer'
        },*/
        {
            name: 'Einstellungen',
            url: '/intern/settings',
            icon: <SettingsIcon/>,
            type: 'always'
        },
        {
            name: 'Über die App',
            url: '/intern/about',
            icon: <HelpIcon/>,
            type: 'always'
        },

        {
            divider: true
        },
        {
            name: 'Logout',
            url: '/intern/transfer',
            icon: <ExitToAppIcon/>,
            type: 'always',
            key: 'logout'
        },
        {
            name: 'Attacke',
            url: '/intern/attacke',
            visible: false
        }
    ]
};
