import React, { Component, Fragment } from 'react';

import { Button } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        color: '#1ca4f4',
        fontFamily: 'Fedra',
        fontWeight: 'bold'
    }
}));


const SimpleButton = ({label, onClick}) => {

    const classes = useStyles();

    return (
        <Button className={classes.container} onClick={onClick}>
            {label}
        </Button>
    )
}

export default SimpleButton;