import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    GET_FILE_REQUEST,
    GET_FILE_SUCCESS,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
} from "../Reducers/fileReducer";

export const uploadFileRequest = (file) =>({
    type: UPLOAD_FILE_REQUEST,
    ...file
});

export const uploadFileSuccess = () => ({
    type: UPLOAD_FILE_SUCCESS,
});

export const getFileRequest = () =>({
    type: GET_FILE_REQUEST,
});

export const getFileSuccess = () => ({
    type: GET_FILE_SUCCESS,
});

export const deleteFileRequest = (id) =>({
    type: DELETE_FILE_REQUEST,
    id
});

export const deleteFileSuccess = () => ({
    type: DELETE_FILE_SUCCESS,
});


