import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./AttackeStyles";
import { Grid, Paper } from "@material-ui/core";
import {connect} from "react-redux";
import globalStyle from "../../../themes/GlobalStyles";

class HilfeRauchverlangen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <h1>Hilfe Rauchverlangen</h1>

                <div className={classes.content}>
                    <p>Verlangensattacken, Gedanken, rund ums Rauchen und starker Gusto sind normal! Denk daran, wie lange dein Körper das Nikotin gewohnt war. Gib ihm  Zeit, sich auf die Rauchfreiheit umzustellen! Diese Attacken sind unangenehm, aber sie werden vergehen! Bleib standhaft!</p>
                    <ul class="list">
                        <li>Denk an deine Motive, warum du rauchfrei sein willst </li>
                        <li>Verwende bei Bedarf Nikotinersatzprodukte aus der Apotheke, die helfen dem Körper beim Entzug</li>
                        <li>Mach kleine Schritte: Bleib heute rauchfrei!</li>
                        <li>Plane deine Belohnung und male sie dir in den schönsten Farben aus</li>
                        <li>Nutz die 3 A - Technik: Abwarten, Ablenken und/oder Abhauen</li>
                    </ul>
                </div>


            </div>
        );
    }
}

function mapStateToProps(state, ownProps){
    return{
        history: ownProps.history
    }


}


export default connect(mapStateToProps, null)(withStyles({...globalStyle(null), ...style(null)})(HilfeRauchverlangen));
