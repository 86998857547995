import React from "react";

const style = theme => {
    return {
        title: {
            textAlign: 'center'
        },
        dialogTitle: {
            fontSize: 36,
            textAlign: 'center',
        },
        addButton: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            marginTop: 50,
        },
        dialogContent: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
        },
        submitButton: {
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10
        },
        listItem: {
            fontWeight: 'bold'
        }
    }

};

export default style;