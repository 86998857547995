import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import {setNextStep, setPreviousStep, unsetSteps} from "../../../Actions/wizzardActions";
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import {hideInformationFeatureModal} from "../../../Actions/informationFeatureActions";
import {storeRequest} from "../../../Actions/userActions";
import PrimaryButton from "../../../Components/PrimaryButton";


class InformationFeaturesModal extends Component {
    constructor(props) {
        super(props);

    }

    whiteList = (entry) => {

        let whiteList = this.props.fitnessWhiteList;

        let found = false;
        whiteList.forEach(listEntry => {
            if(listEntry._id === entry._id){
                found = true;
            }
        });
        if(!found){
            whiteList.push(entry);
        }

        this.props.store({key: 'fitnessWhiteList', values: whiteList});
        this.props.hideModal();
    }

    blackList = (entry) => {

        let blackList = this.props.fitnessBlackList;

        let found = false;
        blackList.forEach(listEntry => {
            if(listEntry._id === entry._id){
                found = true;
            }
        });
        if(!found){
            blackList.push(entry);
        }

        this.props.store({key: 'fitnessBlackList', values: blackList});
        this.props.hideModal();
    };

    deleteWhiteList = (index) => {
        let whiteList = this.props.fitnessWhiteList;
        if(index < whiteList.length) {
            whiteList.splice(index, 1);
            this.props.store({key: 'fitnessWhiteList', values: whiteList});
        } else {
            console.error('motive index greater than length');
        }
    };


    render() {
        const { classes, modal, modalData } = this.props;
        return (
            <Dialog maxWidth={'md'} open={modal}>
                <DialogTitle >
                    <div className={classes.modalTitle}>{modalData.title}</div>
                </DialogTitle>
                <DialogContent>
                    <span className={classes.modalText}>{modalData.text?modalData.text:'Keine Information vorhanden'}</span>
                </DialogContent>
                <DialogActions>
                    <div style={{flexDirection: 'column', flex: 1, textAlign: 'center'}}>

                        {modalData.name === 'fitness' ?
                            <div style={{marginBottom: 10}}>
                                <PrimaryButton label={'Nützlich für mich'} onClick={() => this.whiteList(modalData)} />
                                <PrimaryButton label={'Nicht nützlich'} onClick={() => this.blackList(modalData)} style={{marginLeft: 20}}/>
                            </div>
                            :
                            <div style={{flex: 1, marginBottom: 10}}>
                                <PrimaryButton label={'OK'} onClick={() => this.props.hideModal()} />
                            </div>
                        }
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return{
        modal: state.information.showModal,
        modalData: state.information.modalData,
        fitnessBlackList: state.user.data && state.user.data.fitnessBlackList ? state.user.data.fitnessBlackList : [],
        fitnessWhiteList: state.user.data && state.user.data.fitnessWhiteList ? state.user.data.fitnessWhiteList : [],
    }
}
const mapDispatchToProps = {
    hideModal: hideInformationFeatureModal,
    store: storeRequest
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(withStyles(style)(InformationFeaturesModal));