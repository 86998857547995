import {put, call, select} from "@redux-saga/core/effects";
import {addFlashMessage} from "../Actions/flashMessageActions";
import {
    loadUserFailed, loadUserSuccess, setActiveDay, storeFailed, storeProfileFailed, storeProfileSuccess, storeRequest,
    storeSuccess
} from "../Actions/userActions";
import {getDashboardAlready, getDashboardYes} from "../Services/dashboard";

export function* storeProfile (api, data) {

    const profile = data.values;
    const appSettings = JSON.parse(localStorage.getItem('app-settings'));

    appSettings['profile'] = profile;

    if(appSettings){
        for (let [key, value] of Object.entries(profile)) {
            if(appSettings && appSettings[key] !== undefined){
                appSettings[key] = value;
            }
        }
    }

    const result1 = yield call(api.store, 'profile', profile);
    const result2 = yield call(api.store, 'app-settings', appSettings);

    localStorage.setItem('profile', JSON.stringify(profile));
    localStorage.setItem('app-settings', JSON.stringify(appSettings));

    if(result1 && result1.data && result1.data.success){
        yield put(storeProfileSuccess());
        yield put(addFlashMessage({message: 'Ihre Angaben wurden erfolgreich gespeichert', style:'success'}));
    } else{
        yield put(storeProfileFailed())
        yield put(addFlashMessage({message: 'Fehler beim Speichern Ihrer Angaben', style:'error'}))
    }
}

export function* store (api, data) {
    const result = yield call(api.store, data.key, data.values);
    localStorage.setItem(data.key, JSON.stringify(data.values));

    if(result && result.data && result.data.success){
        yield put(storeSuccess());
        yield put(addFlashMessage({message: 'Ihre Angaben wurden erfolgreich gespeichert', style:'success'}));
    } else{
        yield put(storeFailed())
        yield put(addFlashMessage({message: 'Fehler beim Speichern Ihrer Angaben', style:'error'}))
    }
}

export function* loadUser (api, loadFromApi = false) {
    let result = {};

    if(loadFromApi){
        result = yield call(api.loadUser);
        if(result && result.data){
            const entries = Object.entries(result.data);
            entries.forEach(item => {
                localStorage.setItem(item[0], JSON.stringify(item[1]));
            });
        }
    } else {
        result.data = allStorage();
    }

    if(result && result.data){

        if(result.data.support && result.data.support.length){
            result.data.support = createIds(result.data.support);
        }

        if(result.data.reward && result.data.reward.length){
            result.data.reward = createIds(result.data.reward);
        }

        if(result.data.notifications && result.data.notifications.length){
            result.data.notifications = createIds(result.data.notifications);
        }

        if(!result.data['dashboard-already'] || result.data['dashboard-already'].length == 0){
            result.data['dashboard-already'] = getDashboardAlready();
        }

        if(!result.data['dashboard-yes'] || result.data['dashboard-yes'].length == 0){
            result.data['dashboard-yes'] = getDashboardYes();
        }


        yield put(loadUserSuccess({data: result.data}));
    } else{
        yield put(loadUserFailed())
    }
}

export function* reloadActiveDay () {
    try {
        const user = state => state.user;
        const {data, activeDay} = yield select(user);

        if(data.notifications){
            let newActiveDay = {};
            newActiveDay.day = activeDay.day;
            newActiveDay.notifications = data.notifications.filter(notification => notification.afterDays === activeDay.day);

            yield put(setActiveDay({day:newActiveDay}))
        }

    } catch (e) {
        console.log(e)
    }
}

function createIds(dataArray){
    dataArray.forEach((item, i) => {
        item.id = i + 1;
    });

    return dataArray
}

function allStorage() {

    let archive = {},
    keys = Object.keys(localStorage),
    i = 0, key;

    for (; key = keys[i]; i++) {
        archive[key] = localStorage.getItem(key);
        try {
            archive[key] = JSON.parse(archive[key])
        } catch(e){ }
    }

    return archive;
}









