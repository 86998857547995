import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            light: '#63ccff',
            main: '#67bad3',
            dark: '#006db3',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FFDF80',
            main: '#F2B63D',
            dark: '#DBA13B',
            contrastText: '#fff',
        }
    },
    shape: {
        borderRadius: 8,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: "Fedra, SansSerif",
                    fontSize: 16
                },
                h1: {
                    fontSize: 36,
                    fontWeight: 'normal'
                },
                input: {
                    fontFamily: "Fedra, SansSerif",
                }
            },
        },
        MuiDrawer: {
            paper: {
                minWidth: 256,
            },
            paperAnchorDockedLeft: {
                borderRight: 'none',
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: 'rgba(255,255,255,0.1)',
                marginLeft: 15,
                marginRight: 15,
                marginTop: 5,
                marginBottom: 5
            },
        },
        MuiListItem: {
            selected: {
                backgroundColor: 'rgba(255,255,255,0.15)'
            }
        }
    },
});

export default theme;