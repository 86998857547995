import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import {Field} from "redux-form";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import PrimaryButton from "../../../Components/PrimaryButton";



const OnBoardingName = ({classes, nextStep, name}) => {

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(!!((name && name.length > 2))){ //validate
                nextStep();
            }
        }
    };

    return (
        <div className={classes.container}>
            <h1 className={classes.welcomeTitle}>Dein Name</h1>
            <div className={classes.content}>
                <div className={classes.dialogTitle}>Um dich persönlich ansprechen zu können, gib bitte deinen Namen ein!</div>
                <div className={classes.inputContainer}>
                    <Field
                        label={'Wie heißt du?'}
                        name='name'
                        component={TextFieldGroup}
                        validate={value => value ? 0 : 'Auswahl erforderlich'}
                        containerStyle={{textAlign:'center', maxWidth: 200}}
                        inputStyle={{textAlign:'center'}}
                        style={{alignItems: 'center', width: 200}}
                        onKeyDown={_handleKeyDown}
                    />
                </div>
            </div>


            <div className={classes.buttonContainer}>
                <PrimaryButton
                    disabled={!!(!(name && name.length > 2))}
                    onClick={nextStep}
                    label={'Weiter'}
                />
            </div>
        </div>
    )
}

export default withStyles(style)(OnBoardingName);
