import React from "react";
import palette from "../../../themes/defaultTheme"


const style = theme => {
    return {
        dashboardTilesBadges: {
            height: '100%',
            position: 'relative',
            cursor: 'pointer',
            minHeight: 200
        },
        container: {
            width: '100%',
            paddingTop: 20,
        },
        badge: {
            textAlign: 'left',
            backgroundColor: '#67bad3',
            padding: 8,
            paddingLeft: 12,
            paddingRight: 20,
            fontWeight: 'bold'
        },
        title: {
            width: '100%',
            height: '20%',
            textAlign: 'center',
            fontWeight: 'bold',
        },
        description: {
            width: '100%',
            height: '100%',
            textAlign: 'left',
            padding: 15,
        },
        descriptionBold: {
            width: '100%',
            height: '100%',
            textAlign: 'left',
            fontSize: 24,
            fontWeight: 'bold',
            padding: 15,
        },
        listContainer: {
            width: '100%',
            height: '100%',
            textAlign: 'left',
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 5,
        },
        listIcon: {
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            borderRadius: '50%',
            fontSize: '20px',
            color: '#000',
            textAlign: 'center',
            backgroundColor: '#67bad3',
            float: 'left'
        },
        listItem: {
            width: '80%',
        },
        infoOverlay: {
            position: 'absolute',
            backgroundColor: '#000',
            opacity: 0.8,
            width: '100%',
            height: '100%',
            borderRadius: 8,
            display: 'flex',
            zIndex: 5,
            alignItems: 'center'
        },
        infoIcon: {
            color: '#fff',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 50,
        },
    }

};

export default style;