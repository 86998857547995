import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {compose} from "redux";
import {Divider, ListSubheader, withStyles} from "@material-ui/core";
import nav from './_nav'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import style from "../AppContainerStyles"

class DrawerItems extends Component {
    activeRoute(routeName, props) {
        return props.location.pathname.indexOf(routeName) > -1;
    }

    getStatus = () => {
        const { statusNumeric } = this.props;
        if(statusNumeric && statusNumeric == 5){
            return 'already';
        }
        return 'maybe';
    }

    render() {
        const {classes, status} = this.props;
        const activeRoute = this.activeRoute;
        // nav list divider
        const divider = (divider, key) => (
            <Divider/>
        );

        // nav list title
        const title = (title, key) => (
            <ListSubheader component="div">
                {title}
            </ListSubheader>
        );

        //default navitem
        const navItem = (item, key) => (
            <ListItem button component={Link} to={item.url} selected={activeRoute(item.url, this.props)}>
                <ListItemIcon className={classes.navIcon}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name}/>
            </ListItem>
        );

        // nav link
        const navLink = (item, idx) => {
            if (item.type === this.getStatus() || item.type === 'always') {
                return (
                    item.title ? title(item, idx) :
                        item.divider ? divider(item, idx) :
                            item.children ? navItem(item, idx)
                                : navItem(item, idx)
                );
            } else {
                return null;
            }
        };

        // nav list
        const navList = (items) => {
            let result = [];
            items.forEach((item, index) => {
                if(item.visible === false){
                    return;
                }

                result.push(navLink(item, index));
            });
            return result;
        };

        return (
            <List className={classes.list} style={{marginTop: 40}}>
                {navList(nav.items)}
            </List>
        )
    }
}

function mapStateToProps(state) {
    const appSettings = state.user.data && state.user.data['app-settings'] ? state.user.data['app-settings'] : {};
    return {
        statusNumeric:appSettings ? appSettings.statusNumeric : 1
    };
}

DrawerItems.contextTypes = {
    router: PropTypes.object.isRequired
}

export default compose(
    withStyles(style),
    connect(mapStateToProps)
)(withRouter(DrawerItems));