export const cigarettes = [
    {value: 0, label: 'bitte auswählen'},
    {value: 1, label: '1 Zigarette am Tag'},
    {value: 2, label: '2 Zigaretten am Tag'},
    {value: 3, label: '3 Zigaretten am Tag'},
    {value: 4, label: '4 Zigaretten am Tag'},
    {value: 5, label: '5 Zigaretten am Tag'},
    {value: 6, label: '6 Zigaretten am Tag'},
    {value: 7, label: '7 Zigaretten am Tag'},
    {value: 8, label: '8 Zigaretten am Tag'},
    {value: 9, label: '9 Zigaretten am Tag'},
    {value: 10, label: '10 Zigaretten am Tag'},
    {value: 11, label: '11 Zigaretten am Tag'},
    {value: 12, label: '12 Zigaretten am Tag'},
    {value: 13, label: '13 Zigaretten am Tag'},
    {value: 14, label: '14 Zigaretten am Tag'},
    {value: 15, label: '15 Zigaretten am Tag'},
    {value: 16, label: '16 Zigaretten am Tag'},
    {value: 17, label: '17 Zigaretten am Tag'},
    {value: 18, label: '18 Zigaretten am Tag'},
    {value: 19, label: '19 Zigaretten am Tag'},
    {value: 20, label: '20 Zigaretten am Tag'},
    {value: 21, label: '21 Zigaretten am Tag'},
    {value: 22, label: '22 Zigaretten am Tag'},
    {value: 23, label: '23 Zigaretten am Tag'},
    {value: 24, label: '24 Zigaretten am Tag'},
    {value: 25, label: '25 Zigaretten am Tag'},
    {value: 26, label: '26 Zigaretten am Tag'},
    {value: 27, label: '27 Zigaretten am Tag'},
    {value: 28, label: '28 Zigaretten am Tag'},
    {value: 29, label: '29 Zigaretten am Tag'},
    {value: 30, label: '30 Zigaretten am Tag'},
    {value: 31, label: '31 Zigaretten am Tag'},
    {value: 32, label: '32 Zigaretten am Tag'},
    {value: 33, label: '33 Zigaretten am Tag'},
    {value: 34, label: '34 Zigaretten am Tag'},
    {value: 35, label: '35 Zigaretten am Tag'},
    {value: 36, label: '36 Zigaretten am Tag'},
    {value: 37, label: '37 Zigaretten am Tag'},
    {value: 38, label: '38 Zigaretten am Tag'},
    {value: 39, label: '39 Zigaretten am Tag'},
    {value: 40, label: '40 Zigaretten am Tag'},
    {value: 41, label: '41 Zigaretten am Tag'},
    {value: 42, label: '42 Zigaretten am Tag'},
    {value: 43, label: '43 Zigaretten am Tag'},
    {value: 44, label: '44 Zigaretten am Tag'},
    {value: 45, label: '45 Zigaretten am Tag'},
    {value: 46, label: '46 Zigaretten am Tag'},
    {value: 47, label: '47 Zigaretten am Tag'},
    {value: 48, label: '48 Zigaretten am Tag'},
    {value: 49, label: '49 Zigaretten am Tag'},
    {value: 50, label: '50 Zigaretten am Tag'},
    {value: 51, label: 'mehr als 50 Zigaretten am Tag'},
];
export const firstCigarette = [
    {value: 0, label: 'bitte auswählen'},
    {value:5, label:'5 min nach dem Aufwachen'},
    {value:30, label:'6-30 min nach dem Aufwachen'},
    {value:60, label:'31-60 min nach dem Aufwachen'},
    {value:61, label:'über eine Stunde nach dem Aufwachen'}
];
export const sex = [
    {value:0, label: 'bitte auswählen'},
    {value:'male', label:'Männlich'},
    {value:'female', label:'Weiblich'}
];
export const age = [
    {value: 0, label: 'bitte auswählen'},
    {value:15, label:'Jünger als 16 Jahre'},
    {value:16, label:'16 - 18 Jahre'},
    {value:18, label:'18 - 20 Jahre'},
    {value:20, label:'20 - 30 Jahre'},
    {value:30, label:'30 - 40 Jahre'},
    {value:40, label:'40 - 50 Jahre'},
    {value:50, label:'50 - 60 Jahre'},
    {value:60, label:'älter als 60 Jahre'}
];
export const bestReasonType = [
    {value: '', label:'bitte auswählen'},
    {value: 'Gesundheit', label: 'Gesundheit'},
    {value: 'Wohlbefinden und Aussehen', label: 'Wohlbefinden und Aussehen'},
    {value: 'Geld', label: 'Geld'},
    {value: 'Suchtfreiheit', label: 'Suchtfreiheit'},
    {value: 'Familie und Freunde', label: 'Familie und Freunde'},
    {value: 'Kinderwunschn und Schwangerschaft', label: 'Kinderwunschn und Schwangerschaft'},
    {value: 'Ziele und Werte', label: 'Ziele und Werte'},
    {value: 'Anderer', label: 'Anderer'},
];
export const motive = [
    {value: 0, label:'bitte auswählen'},
    {value: 'Ich will etwas für meine Gesundheit tun', label:'Ich will etwas für meine Gesundheit tun'},
    {value: 'Ich will meinen Körper vor Schadstoffen schützen', label:'Ich will meinen Körper vor Schadstoffen schützen'},
    {value: 'Ich will Geld sparen', label:'Ich will Geld sparen'},
    {value: 'Ich will frei von der Sucht sein', label:'Ich will frei von der Sucht sein'},
    {value: 'Ich will gut riechen', label:'Ich will gut riechen'},
    {value: 'Ich möchte mich fitter und wohler fühlen', label:'Ich möchte mich fitter und wohler fühlen'},
    {value: 'Ich will schwanger werden', label:'Ich will schwanger werden'},
    {value: 'Ich bin schwanger und will das Beste für mein Baby', label:'Ich bin schwanger und will das Beste für mein Baby'},
    {value: 'Ich stille und will das Beste für mein Baby', label:'Ich stille und will das Beste für mein Baby'},
    {value: 'Ich will für meine Familie rauchfrei sein', label:'Ich will für meine Familie rauchfrei sein'},
    {value: 'Ich will für meine Freunde rauchfrei sein', label:'Ich will für meine Freunde rauchfrei sein'},
    {value: 'Rauchen passt nicht mehr zu mir und meinem Leben', label:'Rauchen passt nicht mehr zu mir und meinem Leben'},
    {value: 'Ich will niemandem durch meinen Rauch schaden', label:'Ich will niemandem durch meinen Rauch schaden'},
    {value: 'Ich will ein gutes Vorbild sein', label:'Ich will ein gutes Vorbild sein'},
];
export const contra = [
    {value: ' ', label:'Bitte auswählen'},
    {value: 'Entspannung', label:'Entspannung'},
    {value: 'Gewohnheit', label:'Gewohnheit'},
    {value: 'Gesellschaft', label:'Gesellschaft'},
    {value: 'Alkohol und Rauchen', label:'Alkohol und Rauchen'},
    {value: 'Es schmeckt mir', label:'Es schmeckt mir'},
    {value: 'Ich bin abhängig', label:'Ich bin abhängig'},
    {value: 'Will mich nicht einschränken', label:'Will mich nicht einschränken'},
    {value: 'Angst vor dem Entzug', label:'Angst vor dem Entzug'},
    {value: 'Rauchen gehört zu mir', label:'Rauchen gehört zu mir'},
    {value: 'Ich will nicht zunehmen', label: 'Ich will nicht zunehmen'},
];
export const pro = [
    {value: ' ', label: 'Bitte auswählen'},
    {value: 'Gesundheit', label: 'Gesundheit'},
    {value: 'Wohlbefinden', label: 'Wohlbefinden'},
    {value: 'Fitness', label: 'Fitness'},
    {value: 'Geruch', label: 'Geruch'},
    {value: 'Geld', label: 'Geld'},
    {value: 'Vorbildwirkung', label: 'Vorbildwirkung'},
    {value: 'Gesundes Aussehen', label: 'Gesundes Aussehen'},
    {value: 'Suchtfreiheit', label: 'Suchtfreiheit'},
    {value: 'Schwangerschaft', label: 'Schwangerschaft'},
    {value: 'Schutz vor Passivrauch', label: 'Schutz vor Passivrauch'},
];
export const risks = [
    {value: 'asthma', label: 'Asthma bronchiale'},
    {value: 'copd', label: 'COPD '},
    {value: 'diabetes', label: 'Diabetes'},
    {value: 'pille', label: 'Einnahme Pille'},
    {value: 'surgery', label: 'Geplante Operation'},
    {value: 'heartdesease', label: 'Herz-Kreislauferkrankung'},
    {value: 'breathless', label: 'Husten und Atemnot'},
    {value: 'psycho', label: 'Psychische Erkrankung'},
    {value: 'tumor', label: 'Tumorerkrankung'},
    {value: 'bloodpressure', label: 'Bluthochdruck'},
    {value: 'furtile', label: 'Fruchtbarkeit'},
    {value: 'teeth', label: 'Mund und Zähne'},
    {value: 'other', label: 'Sonstige Erkrankungen'}
];
export const hurdles = [
    {value: 'like', label: 'Mir schmeckt´s'},
    {value: 'stress', label: 'Hilft mir beim Stress'},
    {value: 'used', label: 'Rauchen ist meine Gewohnheit'},
    {value: 'noreason', label: 'Ich hab keinen Grund aufzuhören'},
    {value: 'nowant', label: 'Ich will nicht aufhören'},
    {value: 'nobreak', label: 'Ohne Zigarette keine Pause'},
    {value: 'nogainweight', label: 'Ich will nicht zunehmen'},
    {value: 'smokeflat', label: 'Viele in meiner Umgebung rauchen'},
    {value: 'helps', label: 'Rauchen hilft mir einfach manchmal'},
    {value: 'noother', label: 'Ich gönn mir sonst nichts'},
    {value: 'fear', label: 'Ich hab Angst vor den Entzugssymptomen'},
    {value: 'nosucceed', label: 'Ich denk, das schaff ich sowieso nicht'}
];
export const checklist = [
    {
        text: "Datum für den Rauchstopptag festgelegt",
        progress: 4
    },
    {
        text: "Zigaretten, Zünder und Aschenbecher entsorgt",
        progress: 4
    },
    {
        text: "Programm für den Rauchstopptag überlegt",
        progress: 2
    },
    {
        text: "Motive für die Rauchfreiheit aufgeschrieben",
        progress: 2
    },
    {
        text: "Meinen persönlichen Motivationssatz überlegt",
        progress: 2
    },
    {
        text: "Meine drei Alternativen zum Rauchen überlegt",
        progress: 2
    },
    {
        text: "Meine Stolpersteine herausgefunden",
        progress: 2
    },
    {
        text: "Meine Umgebung ist rauchfrei",
        progress: 2
    },
    {
        text: "Bei starker körperlicher Abhängigkeit - Nikotinprodukte besorgt",
        progress: 2
    },
    {
        text: "Alternativen für Typische Rauchrituale überlegt",
        progress: 2
    },
    {
        text: "Umfeld informiert",
        progress: 1
    },
    {
        text: "Kleidung und Textilien, die nach Rauch riechen, sind gereinigt",
        progress: 1
    },
    {
        text: "Belohnung überlegt",
        progress: 1
    },
    {
        text: "Gesunde Ernährung bedacht",
        progress: 1
    },
    {
        text: "Bewegung, die mir Spaß macht für den Alltag geplant",
        progress: 1
    },
    {
        text: "Mundhygiene Termin ausgemacht",
        progress: 1
    },
    {
        text: "Wohnung ausgemalt",
        progress: 1
    }
];
export const firstDaysOption = [
    {
        number: -1,
        text: "bitte auswählen",
    },{
        number: 0,
        text: "Erste in der Früh",
        alternative: "Gestalte in den ersten Tagen dein Morgenritual anders als bisher.",
        notification: "Starte mit Frischluft in den Tag!"
    }, {
        number: 1,
        text: "Kaffee und Rauchen",
        alternative: "Trink in der ersten Zeit besser Tee oder trink deinen Kaffee anders als gewohnt - Einen Espresso statt der Melange, den Kaffe an einem anderen Ort trinken... jede Änderung hilft",
        notification: "Kaffee und … ein Schluck Wasser dazu!"
    }, {
        number: 2,
        text: "Verdauungszigarette",
        alternative: "Bring deine Verdauung mit viel Flüssigkeit und Dörrzwetschken in Schwung",
        notification: "Die Verdauung funktioniert besser wenn du zusätzlich zwei Gläser Wasser trinkst!   "
    }, {
        number: 3,
        text: "Rauchen bei der Arbeit",
        alternative: "Halte dich zu Beginn an deine nichtrauchenden Kolleginnen und Kollegen – Wie gestalten sie ihre Pausen? Was machen sie mit ihren Händen beim Plaudern?",
        notification: "Rauchfrei arbeitet es sich leichter."
    }, {
        number: 4,
        text: "Pausenzigarette",
        alternative: "Mach rauchfrei Pause, atme tief durch und trink Wasser oder iß ein Stück Obst und - Sei stolz auf dich!",
        notification: "Mach eine gesunde Pause!"
    }, {
        number: 5,
        text: "Rauchen nach dem Mittagessen",
        alternative: "Steh gleich nach dem Essen auf oder geh ein paar Schritte",
        notification: "Ein paar Züge Frischluft nach dem Essen!"
    }, {
        number: 6,
        text: "Rauchen gegen Stress",
        alternative: "Stress wird durch Rauchen nur kurzfristig besser! Langfristig hilft Nichtrauchen gegen Stress. Atme gut durch und mach einen Schritt nach dem anderen.",
        notification: "Zigaretten machen mehr Stress als sie lindern – bald wirst du den Unterschied merken!"
    }, {
        number: 7,
        text: "Rauchen aus Langeweile",
        alternative: "Eine Zeitschrift oder ein Buch lesen, ein Spiel am Handy, einen Spaziergang, Sport, Malen, Stricken oder was auch immer … was machst du gerne? Lass (alte) Hobbies wieder aufleben und nütze die gewonnene Zeit!",
        notification: "Beobachte Nichtraucher/innen, was sie bei Langeweile tun."
    }, {
        number: 8,
        text: "Rauchen aus Einsamkeit",
        alternative: "Einsamkeit ist oft schwer zu ertragen! Denk an deine Gründe warum du rauchfrei sein möchtest! Ruf an, wenn du magst -  0800 810 013",
        notification: "Ruf doch wen an oder schreib jemandem! "
    }, {
        number: 9,
        text: "Rauchen zur Verbesserung der Stimmung",
        alternative: "Die Stimmung kann durch Sport, ein Gespräch oder eine Aktivität, die Spaß macht verbessert werden! Probier aus, was bei dir funktioniert.",
        notification: "Rauchfrei wird’s dir besser gehen!"
    }, {
        number: 10,
        text: "Rauchen für bessere Konzentration",
        alternative: "Kurze Pause, Frischluft, ein Glas Wasser, Übungen für den Kopf oder den Körper – es gibt unzählige sinnvolle Möglichkeiten, deine Konzentration zu steigern. Finde was Passendes!",
        notification: "Rauchfrei bist du besser konzentriert!"
    }, {
        number: 11,
        text: "Rauchen davor oder danach",
        alternative: "Vielleicht findest du etwas anderes, um den Tag oder bestimmte Tätigkeiten zu strukturieren, planen oder abzuschließen. Listen machen, Tee trinken, eine Pause machen, ...",
        notification: "Finde deinen eigenen Rhythmus!"
    }, {
        number: 12,
        text: "Rauchen am Weg oder beim Warten",
        alternative: "Bei Weg- und Wartezeiten helfen Kaugummis oder zuckerfreie Zuckerl, die alten Rituale zu ändern und etwas für den Mund zu haben",
        notification: "Lenk dich ab beim Warten und am Weg - z.B. mit einem Kaugummi."
    }, {
        number: 13,
        text: "Rauchen im Auto",
        alternative: "Halte beim Autofahren Lutschbonbons oder Kaugummis griffbereit",
        notification: "Bleib rauchfrei im Auto!"
    }, {
        number: 14,
        text: "Gemeinsames Rauchen (PartnerIn, FreundIn)",
        alternative: "Die Umstellung von gemeinsamen Ritualen dauert ein bisschen. Überleg, ob ihr eure gemeinsamen Aktivitäten etwas verändert oder euch an anderen Orten zusammensetzt... Findet gemeinsame rauchfreie Situationen oder Orte!",
        notification: "Du bist auch rauchfrei eine gute Gesellschaft!"
    }, {
        number: 15,
        text: "Rauchen beim Telefonieren, Fernsehen oder am PC",
        alternative: "Achte darauf, dass du während verschiedener Tätigkeiten deine Finger oder deine Lippen beschäftigst....mit Kritzeln, einem Stressball oder einem Zahnstocher zwischen den Zähnen ",
        notification: "Konzentrier dich auf das was du machst, das geht rauchfrei besser!"
    }, {
        number: 16,
        text: "Feierabendritual",
        alternative: "Finde ein Feierabendritual, das dir gut tut. Probier verschiedenes aus!",
        notification: "Finde ein neues Abendritual!"
    }, {
        number: 17,
        text: "Rauchen statt Essen",
        alternative: "Greif z.B. zu kalorienarmen Gemüsesticks oder trink ein Glas Wasser.",
        notification: "Trink erst mal ein Glas Wasser!"
    }, {
        number: 18,
        text: "Genusszigarette",
        alternative: "Genieße mit allen Sinnen! Du wirst staunen, was du riechen, schmecken, fühlen, hören und sehen lernst.",
        notification: "Genieß etwas Gesundes!"
    }, {
        number: 19,
        text: "Rauchen in schwierigen Situationen",
        alternative: "Leicht ist das Leben leider oft nicht. Rauchen macht es aber auch nicht besser! Denk daran, warum du rauchfrei sein möchtest!",
        notification: "Rauchen löst keine Probleme!"
    }, {
        number: 20,
        text: "Belohnungszigarette",
        alternative: "Zur Belohnung gibt es Verschiedenes! Was würde dich denn freuen? Wenn du das Geld durchs Nichtrauchen gespart hast, dann gönn dir doch diese Belohnung!",
        notification: "Belohn dich gesund!"
    }, {
        number: 21,
        text: "Rauchen beim Ausgehen",
        alternative: "Geh in der ersten Zeit in Nichtraucherlokale und vermeide, Alkohol zu trinken, bis du dich sicherer fühlst.",
        notification: "Geh aus - ohne Reue am nächsten Tag!"
    }, {
        number: 22,
        text: "Alkohol und Rauchen",
        alternative: "Am besten hilft es in der ersten Zeit, keinen Alkohol zu trinken - bis du ein bisschen sicherer rauchfrei bist",
        notification: "Trink vorerst alkoholfrei!"
    }
];
export const risksText = [
    {
        "type": "asthma",
        "text": "Wenn du Asthma hast, ist dein Körper rauchfrei leistungsfähiger. Das Atmen fällt leichter, vielleicht brauchst du auch weniger Medikamente."
    }, {
        "type": "copd",
        "text": "Der Rauchstopp ist bei COPD die Therapie der ersten Wahl. Nichts hilft mehr, den Krankheitsverlauf zu verbessern!"
    }, {
        "type": "diabetes",
        "text": "Die Therapie von Diabetes gestaltet sich schwierig, wenn die Insulinempfindlichkeit durchs Rauchen herabgesetzt ist. Rauchfreiheit hilft, deinen Diabetes gut in den Griff zu bekommen."
    }, {
        "type": "pille",
        "text": "Durch die Kombination von Rauchen und Pille steigt dein Krankheitsrisiko für Herzinfarkt, Lungenembolie und Schlaganfall an. Rauchfrei verhütest du gesünder!"
    }, {
        "type": "surgery",
        "text": "Operationen verlaufen bei Nichtraucherinnen und Rauchern mit weniger Komplikationen. Auch die Wundheilung ist besser."
    }, {
        "type": "heartdesease",
        "text": "Rauchen ist der wichtigste vermeidbare Risikofaktor für Herz-Kreislauferkrankungen. Achte auf dein Herz und erspare ihm die Zigaretten!"
    }, {
        "type": "breathless",
        "text": "Husten und Atemnot sind oft erste Anzeichen von Lungenerkrankungen und werden durch den Rauchstopp verringert oder können sogar ganz verschwinden. Lass die Beschwerden jedoch ärztlich abklären! Es gibt keinen „normalen Raucherhusten“."
    }, {
        "type": "psycho",
        "text": "Rauchen ist für viele eine Krankheitsbewältigung bei psychischen Beschwerden. Es schadet langfristig aber nicht nur deinem Körper, sondern auch deiner Psyche. Ein Rauchstopp oder eine Verringerung der Zigarettenmenge helfen oft, dass es auch der Psyche besser geht."
    }, {
        "type": "tumor",
        "text": "Rauchen ist für jede Art von Tumorerkrankung ein hoher Risikofaktor. Die Rauchfreiheit reduziert das Risiko, an Krebs zu erkranken erheblich. Durch den Rauchstopp werden Krankheitsverlauf und Wirksamkeit der Therapien verbessert."
    }, {
        "type": "bloodpressure",
        "text": "Schon eine Zigarette erhöht den Blutdruck, weil das Herz schneller schlägt und der Widerstand in den Gefäßen steigt. Aber auch der Rauchstopp wirkt schnell: bereits nach einer Woche sinkt der Blutdruck und die Herzfrequenz nimmt ab."
    }, {
        "type": "furtile",
        "text": "Rauchen verringert die männliche und weibliche Fruchtbarkeit. Eine Schwangerschaft tritt bei Nichtraucherinnen schneller ein."
    }, {
        "type": "teeth",
        "text": "Rauchen führt zu Veränderungen in der Mundhöhle und zu gehäuftem Zahnverlust. Der Rauchstopp ist eine tolle Zahnhygiene."
    }, {
        "type": "other",
        "text": "Es gibt kein Organ, das nicht vom Rauchstopp profitiert. Die bessere Durchblutung und die gestoppte Giftstoffzufuhr lässt alle Bereiche des Körpers aufatmen."
    }, {
        "type": "like",
        "text": "Schmeckt dir wirklich jede Zigarette? Oder gibt es manchmal auch die eine oder andere, die nicht so gut ist oder die du gleich wieder ausdämpfst? Finde heraus, was dir schmeckt, dir aber nicht schadet!"
    }, {
        "type": "stress",
        "text": "Rauchen entspannt nur kurzfristig und verursacht dir und deinem Körper viel Stress. Es gibt viele gesunde Strategien zur Stressbewältigung! Langfristige Entspannung erlebst du rauchfrei. "
    }, {
        "type": "used",
        "text": "Wahrscheinlich hast du in deinem Leben schon tausendfach zur Zigarette gegriffen, dadurch ist es eine Gewohnheit geworden. Diese lässt sich aber auch wieder „verlernen“ und/oder durch andere Gewohnheiten ersetzen. "
    }, {
        "type": "noreason",
        "text": "Vielleicht gibt es auf den ersten Blick keinen ausschlaggebenden Grund für dich. Irgendeinen Vorteil hätte die Rauchfreiheit aber wahrscheinlich doch auch für dich. Überlege, was das sein könnte? Das Geld, deine Unabhängigkeit, deine Familie,...?"
    }, {
        "type": "nowant",
        "text": "Willst du nicht aufhören, oder kannst du nicht? In beiden Fällen – ruf uns an, wenn du drüber sprechen willst – 0800 810 013! Wir freuen uns auf deinen Anruf."
    }, {
        "type": "nobreak",
        "text": "Deine bisherigen Rauchpausen kannst du vielleicht in „Frischluftpausen“ mit neuen Pausenritualen ändern. Die rauchfreie Pause ist erholsamer für deinen Kopf und deinen Körper."
    }, {
        "type": "nogainweight",
        "text": "Durch den Rauchstopp kommt es zu einer Normalisierung deines Stoffwechsels. Dein Körper verbraucht weniger Kalorien, weil er nicht mehr so gestresst ist. Bewegung und bewusste Ernährung helfen, das Gewicht zu halten."
    }, {
        "type": "smokeflat",
        "text": "Es fällt vielen schwer, rauchfrei zu werden, wenn Partner/Partnerin, Familie oder Arbeitskollegen und Kolleginnen rauchen. Achte einmal darauf, in welchen Situationen du auch rauchfrei mitreden kannst."
    }, {
        "type": "helps",
        "text": "Rauchen hilft – wirklich? Oder lenkt Rauchen vielleicht ab, gibt dir etwas zu tun, gibt dir das Gefühl, du machst etwas… Überlege doch, wodurch du dich besser fühlen könntest, aber dir damit nicht schadest."
    }, {
        "type": "noother",
        "text": "Gönn dir etwas Gutes! Etwas das dir gut tut und dich freut. Mach dich auf die Suche, was das sein könnte."
    }, {
        "type": "fear",
        "text": "Entzugssymptome können, müssen aber nicht auftreten. Meistens dauern sie nur einige Tage. Manche vergleichen sie mit einer Grippe, bei der man sich nicht so gut fühlt, sich schonen soll und grantig ist. Aber nach einigen Tagen vergehen die Symptome wieder."
    }, {
        "type": "nosucceed",
        "text": "Schon einmal den Rauchstopp versucht und nicht geschafft? Einige Anläufe gehören für die meisten dazu. Es gibt viele Strategien und Tipps, die hilfreich sind. Nimm Unterstützung in Anspruch, damit gelingt der Rauchstopp leichter!"
    }, {
        "type": "other2",
        "text": "Es gibt einige Situationen, die die Rauchfreiheit nicht ganz so leicht machen. Ruf uns an -  0800 810 013! Schauen wir, ob wir gemeinsam eine Lösung finden!"
    }];

