import findIndex from 'lodash/findIndex';


/* Action types */
const PREFIX = "flashMessage/";
export const ADD_FLASH_MESSAGE = PREFIX + "ADD_FLASH_MESSAGE";
export const DELETE_FLASH_MESSAGE = PREFIX + "DELETE_FLASH_MESSAGE";

const initialState = [];
const uuidv4 = require('uuid/v4');

const flashMessageReducer = (state = initialState, action) => {

    switch(action.type) {
        case ADD_FLASH_MESSAGE:
            return [
                {
                    id: uuidv4(),
                    text: action.message,
                    style: action.style,
                    timeout: 'fast'
                },
                ...state
        ];
        case DELETE_FLASH_MESSAGE:
            const index = findIndex(state, { id: action.id });
            if (index >= 0) {
                return [
                    ...state.slice(0, index),
                    ...state.slice(index + 1)
                ];
            }
            return {
                state,
            };

        default:
            return state;
    }
};
export default flashMessageReducer;