import axios from "../axios";

async function loadInformationFeatures(params) {
    const healthiness = await axios.get('/healthiness', {params: params});
    const motivation = await axios.get('/motivationflash/random', {params: params});
    const fact = await axios.get('/facts/next', {params: params});
    const fitness = await axios.get('/fitness/random', {params: params});

    return {
        healthiness: healthiness,
        motivation: motivation,
        fact: fact,
        fitness: fitness
    };
}

async function loadDashboardInfo(params) {
    const response =  await axios.get('/dashboardinfos', {params: params});
    return response;
}

async function login(email, password) {
    try {
        const response =  await axios.post('/auth/login', {email: email, password: password});
        return response;
    } catch(e) {
        return e.response;
    }
}

async function createAccount() {
    try {
        return await axios.post('/auth/create_account', {params: {}});
    } catch(e){
        return e.response;
    }
}

async function setPassword(email, password) {
   try {
       const response =  await axios.post('/auth/set_password', {email: email, password: password});
       return response;
   } catch(e) {
       return e.response;
   }
}

async function forgotPassword(email) {
    try {
        const response =  await axios.post('/auth/forgot_password', {email: email});
        return response;
    } catch(e) {
        return e.response;
    }
}

async function store(key, data) {
    try {
        const response =  await axios.post('/store', {key: key, data: data});
        return response;
    } catch(e) {
        return e.response;
    }
}

async function loadUser() {
    try {
        return await axios.get('/store/restore');
    } catch(e) {
        return e.response;
    }
}


export default {
    loadInformationFeatures,
    loadDashboardInfo,
    login,
    createAccount,
    setPassword,
    forgotPassword,
    store,
    loadUser,
}