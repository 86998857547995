import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";
import { Grid, Paper } from "@material-ui/core";

const Sisha = (props) => {

    const { classes } = props;
    return (
        <Fragment>
            <div className={classes.container}>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <h1>E-Zigaretten sind auch nicht gesund</h1>
                        <div className={classes.content}>
                            <div>
                                Elektrische Rauchprodukte (E-Zigarette, E-Shisha) simulieren das Rauchen mit technischen Mitteln. Dabei wird eine Flüssigkeit (Liquid), die zu ca 90% aus Propylenglycol besteht, über ein batteriebetriebenes Heizmodul verdampft, der Dampf wird dann inhaliert. Das Liquid gibt es in verschiedenen Geschmacksrichtungen, in manchen Fällen ist es nikotinfrei, in vielen Fällen nikotinhältig, die Mengenangaben sind meist ungenau.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <h1>Momentan keine Empfehlung möglich</h1>
                        <div className={classes.content}>
                            <div>
                                Die wissenschaftliche Datenlage über die gesundheitlichen Auswirkungen bei wiederholtem, langfristigem Gebrauch ist zurzeit nicht ausreichend. E-Zigaretten können momentan weder als eine „gesunde Alternative“ zur herkömmlichen Zigarette, noch als eine Methode zur Reduktion oder Entwöhnung empfohlen werden. Durch das Beibehalten des Rauchrituals und die Beimengung von Nikotin kann das Suchtverhalten gestärkt werden.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <h1>Wasserpfeife schadet auch</h1>
                        <div className={classes.content}>
                            <div>
                                Die Wasserpfeife (oder auch Shisha, Boory, Narghile, Arghile, Hookha, Goza, Hubble-Bubble) ist aufgrund der unterschiedlichen Geschmacksrichtungen und des gemeinsamen Rauchrituals vor allem bei Jugendlichen sehr beliebt. Der Gebrauch der Wasserpfeife ist genauso schädlich wie das Zigarettenrauchen. Die oftmals angenommene Filterwirkung durch das Wasser konnte nicht bestätigt werden.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <h1>Unterschätztes Risiko</h1>
                        <div className={classes.content}>
                            <div>
                                So werden über den Rauch von Wasserpfeifen teilweise sogar größere Mengen an Schadstoffen aufgenommen als über filterlose Zigaretten. Das gilt insbesondere für Teer und Kohlenmonoxid, sowie für die Giftstoffe Arsen, Chrom und Nickel. Außerdem wird wesentlich mehr Nikotin aufgenommen. Da die Wasserpfeife meistens in Gemeinschaft mit anderen geraucht wird, besteht zusätzlich ein erhöhtes Risiko für die Übertragung von Infektionskrankheiten.
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
}

export default (withStyles(style)(Sisha));
