export const defaultAppSettings = {
    name: undefined,
    status: 'yes',
    stopDate: '',
    firstTime: true,
    introViewed: false,
    bestReasonTyp: null,
    bestReasonImage: null,
    bestReason: '',
    fbAppId: '1234567890',
    notificationDate: null,
    disableNotifications: false,
    anonymous: true,
};

export const defaultProfile = {
    noOfCigarettes: '',
    startDate: null,
    startSmoke: '',
    firstCigarette: '',
    medianPrice: 5.5,
    age: undefined,
    sex: '',
    state: '',
    pregnant: false,
    status: 'yes',
};