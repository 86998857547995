import React from 'react';
import {Provider} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";

import Drawer from "./Containers/app/Drawer/Drawer";
import "./App.css";
import rootReducer from "./rootReducer";
import rootSagas from "./Sagas/rootSagas";

// THEME
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import defaultTheme from './themes/defaultTheme';
import setAuthorizationToken from "./utils/setAuthorizationToken";

const sagaMiddleware = createSagaMiddleware();


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware)
    )
);
sagaMiddleware.run(rootSagas);

if (localStorage.rfJwtToken) {
    setAuthorizationToken(localStorage.rfJwtToken);
}

function App() {

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={defaultTheme}>
                <Router>
                    <Route path="/" component={Drawer}/>
                </Router>
            </MuiThemeProvider>
        </Provider>
    );
}
export default App;
