export const getParsedEntry = (key) => {
    let value = localStorage.getItem(key);
    if(value){
        try {
            value = JSON.parse(value);
        } catch(e){

        }
    }
    return value;
};

export const saveParsedEntry = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch(e){
        console.error(e);
    }
};