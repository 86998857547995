import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./OnBoardingStyles";
import { Button, Slider, createMuiTheme } from "@material-ui/core";
import { Field} from 'redux-form';
import TextFieldGroup from "../../../Components/TextFieldGroup";
import PrimaryButton from "../../../Components/PrimaryButton";
import {makeStyles, ThemeProvider} from "@material-ui/styles";

const OnBoardingSlider = ({classes, nextStep, name, statusNumeric, changeStatus}) => {
    let label = '';
    switch(statusNumeric){
        case 1:
            label = 'Ich rauche gern';
            break;

        case 2:
            label = 'Ich rauche aber bin unzufrieden';
            break;

        case 3:
            label = 'Ich will in den nächsten 6 Monaten aufhören';
            break;

        case 4:
            label =  'Ich will bald aufhören';
            break;

        case 5:
            label = 'Ich bin schon rauchfrei';
            break;
    }

    const [value, setValue] = React.useState(statusNumeric);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        changeStatus('onBoarding-form', 'statusNumeric', newValue);
    };

    const PrettoSlider = createMuiTheme({
        overrides: {
            MuiSlider: {
                root: {
                    color: '#ffd606',
                    height: 8,
                },
                thumb: {
                    height: 24,
                    width: 24,
                    backgroundColor: '#fff',
                    border: '2px solid currentColor',
                    marginTop: -8,
                    marginLeft: -12,
                },
                track: {
                    height: 8,
                    borderRadius: 4,
                },
                rail: {
                    height: 8,
                    borderRadius: 4,
                },
                mark: {
                    backgroundColor: '#ffd606',
                    height: 12,
                    width: 3,
                    marginTop: -2
                },
            }
        }
    });

    const useStyles = makeStyles((theme) => ({
        root: {
            width: 300,
        },
        margin: {
            height: theme.spacing(3),
        },
    }));

    return (
        <div className={classes.container}>
            <h1 className={classes.dialogTitle}> Hallo {name} </h1>

            <div className={classes.content}>
                <div className={classes.dialogText}>Deine eigene Entscheidung für den Rauchstopp ist der erste Schritt in Richtung Rauchfreiheit. Rauchfrei werden?</div>

                <div className={classes.inputLabel}>{label}</div>

                <div className={classes.inputContainer}>
                    <ThemeProvider theme={PrettoSlider}>
                        <Slider
                            value={value}
                            min={1}
                            step={1}
                            max={5}
                            onChange={handleChange}
                            marks={true}
                        />
                    </ThemeProvider>
                </div>

                <div>Durch bewegen des Sliders kannst du wählen in welcher Situation du dich gerade befindest.</div>

                <div style={{display: 'none'}}>
                    <Field
                        name='statusNumeric'
                        component={TextFieldGroup}
                    />
                </div>

            </div>


            <div className={classes.buttonContainer}>
                <PrimaryButton
                    onClick={nextStep}
                    label={'Weiter'}
                />
            </div>
        </div>
    )
};

export default withStyles(style)(OnBoardingSlider);
