import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardStyles";
import {compose } from "redux";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Grid } from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import { Close as CloseIcon } from '@material-ui/icons';
import {closeTimerDialog, setTimer} from "../../../Actions/userActions";
import TextFieldGroup from "../../../Components/TextFieldGroup";
import PrimaryButton from "../../../Components/PrimaryButton";

const Attack = (props) => {

    const calculateTimer = (values) => {

        const minInSec = parseInt(values.minutes?values.minutes:'0') * 60;
        const sec = minInSec + parseInt(values.seconds?values.seconds:'0');

        props.setTimer({seconds: sec});
        props.closeTimerDialog();
    };


    return (
        <Dialog fullWidth maxWidth={"sm"} classes={{ paper: props.classes.dialogPaper }} open={props.timerDialog}>
            <DialogTitle>
                <div className={props.classes.smokeDialogTitle}>
                    <span>Wie lange dauert deine Verlangensattacke</span>
                    <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => props.closeTimerDialog()}>
                        <CloseIcon style={{fontSize: 32}}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                    <form className={props.classes.smokeContent}>

                        <Grid container spacing={10}>
                            <Grid item xs={6}>
                                <Field
                                    name='minutes'
                                    type={'number'}
                                    component={TextFieldGroup}
                                    label={'min'}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    name='seconds'
                                    type={'number'}
                                    component={TextFieldGroup}
                                    label={'sec'}
                                />
                            </Grid>
                        </Grid>

                        <div style={{textAlign: 'center', marginTop: 20, marginBottom: 20}}>
                            <PrimaryButton
                                label={'Starten'}
                                onClick={props.handleSubmit(calculateTimer)}
                            />
                        </div>
                    </form>
            </DialogContent>
        </Dialog>

    )
};

function mapStateToProps(state) {
    return {
        timerDialog: state.user.timerDialog,
        initialValues: {
            minutes: 10,
            seconds: 0
        }
    }
}

const mapDispatchToProps = {
    closeTimerDialog: closeTimerDialog,
    setTimer: setTimer
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'attack-form',
        enableReinitialize: false,
    }),
    withStyles(style)
)(Attack);