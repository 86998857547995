import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FactsStyles";

const MedicalSupport = (props) => {

    const { classes } = props;
    return (
        <div className={classes.container}>
            <h1>Medikamentöse Unterstützung</h1>
            <div className={classes.content}>
                <div className={classes.listSection}>
                    Bei starker körperlicher Abhängigkeit kann die Unterstützung durch Nikotinersatzprodukte wie Nikotinpflaster oder Nikotinkaugummi oder durch die verschreibungspflichtigen Wirkstoffe Bupropion oder Varenicline eine Hilfe sein. Wichtig dabei ist die richtige Anwendung der Produkte, die ausreichend lange Anwendungsdauer und die ausreichend hohe Dosierung. Lass dich ärztlich beraten oder informiere dich in der Apotheke, welches Produkt für dich passt. Die medikamentöse Unterstützung soll in jedem Fall mit Beratung zur Verhaltensänderung kombiniert werden, um die Wirksamkeit der Produkte zu erhöhen.
                </div>
            </div>
        </div>
    );
}

export default (withStyles(style)(MedicalSupport));
