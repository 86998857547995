import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./ScaleStyles";
import { Grid, Button, IconButton } from "@material-ui/core";
import {
    addContra, addPro, setAddDialog, storeRequest,
} from "../../../Actions/userActions";
import ScaleDialog from "./ScaleDialog";
import DeleteIcon from '@material-ui/icons/Delete';
import {contra, pro} from "../../../utils/constants";
import PrimaryButton from "../../../Components/PrimaryButton";
import globalStyle from "../../../themes/GlobalStyles";

class Scale extends Component {
    constructor(props) {
        super(props);

        this.state={
            active: false
        }
    }

    addPro = () => {
        this.props.addPro();
        this.props.setAddDialog();
    };

    addContra = () => {
        this.props.addContra();
        this.props.setAddDialog();
    };

    deletePro = (value) => {
        const newPros = [];
        this.props.pros.forEach(pro =>{
            if(pro !== value){
                newPros.push(pro)
            }
        })
        this.props.store({key: 'balancePros', values: newPros});
    };

    deleteContra = (value) => {
        const newContras = [];
        this.props.contras.forEach(contra =>{
            if(contra !== value){
                newContras.push(contra)
            }
        })
        this.props.store({key: 'balanceContras', values: newContras});
    };


    render() {
        const { classes, dialog, pros, contras } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>Meine Motivwaage</h1>
                </div>

                <div className={classes.content}>
                    <span>Es gibt einiges, das für den Rauchstopp spricht, aber auch etwas, das dich immer noch an der Zigarette hält? So geht es vielen. Stelle gegenüber was für und was gegen deine Rauchfreiheit spricht.</span>

                    <div className={classes.addButton}>
                        <PrimaryButton
                            label={' Pro hinzufügen'}
                            onClick={()=>this.addPro()}
                        />
                    </div>

                    {pros &&
                        <div>
                            {pros.map(pro => {
                                return(
                                    <div onMouseEnter={()=>this.setState({active: pro})} onMouseLeave={()=>this.setState({active: false})}>
                                        <p className={classes.listItem}>
                                            {pro.text}

                                            {pro===this.state.active &&
                                                <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => this.deletePro(pro)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            }
                                        </p>

                                        <hr/>
                                    </div>
                                )})
                            }
                        </div>
                    }

                    <div className={classes.addButton}>
                        <PrimaryButton
                            label={'Contra hinzufügen'}
                            onClick={()=>this.addContra()}
                        />
                    </div>

                    {contras &&
                    <div>
                        {contras.map(contra => {
                            return(
                                <div onMouseEnter={()=>this.setState({active: contra})} onMouseLeave={()=>this.setState({active: false})}>
                                    <p className={classes.listItem}>
                                        {contra.text}

                                        {contra===this.state.active &&
                                        <IconButton style={{padding: 0, color:'#67bad3', float: 'right'}} onClick={() => this.deleteContra(contra)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                        }
                                    </p>

                                    <hr/>
                                </div>
                            )})
                        }
                    </div>
                    }
                </div>

                {dialog && <ScaleDialog pro={pro} contra={contra}/>}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        dialog: state.user.addDialog,
        pros: state.user.data && state.user.data.balancePros ? state.user.data.balancePros:[],
        contras: state.user.data && state.user.data.balanceContras ? state.user.data.balanceContras:[],
    }
}
const mapDispatchToProps = {
    setAddDialog: setAddDialog,
    store: storeRequest,
    addPro: addPro,
    addContra: addContra
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles({...globalStyle(null), ...style(null)})(Scale));
