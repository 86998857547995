import React, { Component, Fragment } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./TipsStyles";

const Trap = (props) => {

    const { classes } = props;

    return (
        <div className={classes.container}>
            <h2>Achtung Falle!</h2>
            <div className={classes.content}>
                <div className={classes.listSection}>
                    <p>
                        Manchmal funktioniert es, weniger zu rauchen. In vielen Fällen ist es ein guter erster Schritt zur Verbesserung der Gesundheit und zur Veränderung des Rauchverhaltens. Manchmal ist dieser Weg sogar der Start in die Rauchfreiheit.
                        Viele machen aber die Erfahrung, dass es langfristig leichter ist, ganz rauchfrei zu sein.
                    </p>
                    <p>
                        Probier aus, was für dich gut passt. Wenn du gemeinsam eine passende Strategie besprechen willst, ruf uns an – 0800 810 013! Wir freuen uns auf deinen Anruf.
                    </p>
                </div>
            </div>


        </div>
    );

}

export default (withStyles(style)(Trap));
