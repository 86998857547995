import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";


const TextFieldGroup = ({ input, onKeyDown, field, value, label, margin, classes, type, onChange, containerStyle, disabled, onBlur, onFocus, fullWidth, placeholder, required, autoComplete, multiline, autoFocus, maxLength, size, inputStyle, inline, labelWidth, className, style, min, max, steps, unit, rows, help, inputProps, variant, id, disable,  meta: { touched, error, warning } }) => {

    return (
		<>
		<TextField
            {...input}
			label={label}
			margin={margin}
			required={required}
			onBlur={onBlur}
			onFocus={onFocus}
			type={type}
			disabled={disabled}
			placeholder={placeholder}
			autoFocus={autoFocus}
			maxLength={maxLength}
			size={size}
			id={id}
			error={touched && error}
			fullWidth={fullWidth}
			rows={rows}
			multiline={multiline}
			style={style}
			variant = {variant}
			autoComplete={{autoComplete}}
            inputProps={{style: inputStyle}}
            InputProps={{style: containerStyle}}
            onKeyDown={onKeyDown}
		/>
		<small>
            {touched && ((error && <span style={{color: '#f44336'}}>{error}</span>) || (warning && <span>{warning}</span>))}
		</small>
		</>
    )
}

TextFieldGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    autoComplete: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
}

TextFieldGroup.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
    autoFocus: false,
    maxLength: 255,
    size: "",
    inline: false,
    labelWidth: 3,
    min: false,
    max: false,
    steps: false,
    className: "",
    onBlur: null,
    onFocus: null,
    rows: 4,
    help: false,
    autoComplete: false,
    required: false,
    fullWidth: true,
    margin: "dense",
}

export default (TextFieldGroup);